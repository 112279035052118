import React, { Component } from 'react'
import { Button, Dialog, Typography } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css'


class ResetSuccess extends Component {

  state = {
    newPhoneNumber: '',
    newName: '',
    newPassword: '',
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  render() {
    const { open, handleClose } = this.props

    return (
      <Dialog open={open} onClose={handleClose} PaperProps={{style:{width: '400px', padding:'16px'}}}>
          <Typography style={{fontWeight: 'bold', marginBottom: 8}} padding="20px 20px 0 20px">
              SUA SENHA FOI RESETADA
            </Typography>
          <Typography variant='caption'>Já pode fazer login com sua nova senha para acessar o sistema </Typography>
          <Button 
            variant="contained"
            fullWidth
            color="primary"
            type="button"
            margin="normal"
            style={{ marginTop: 16 }}
            onClick={handleClose}
          >
            <b>OK</b>
          </Button>
        </Dialog>
    )
  }
}

export default ResetSuccess
