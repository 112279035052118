import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import UserBar from '../auth/UserBar'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Card, Typography, CardContent } from '@material-ui/core';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import PlatformCreate from './PlatformCreate';
import PlatformEdit from './PlatformEdit';
import PlatformList from './PlatformList';
import { getPlatformsFetchData } from './actions'
import Pagination from '@material-ui/lab/Pagination';


class Platforms extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  state = {
    anchorEl: null,
    platform: null
  }

  componentDidMount() {
    this.props.getPlatforms({ all: true })
  }

  toggleCreate = (createType) => {
    this.setState((state) => {
      if(state.openCreate) {
        this.props.getPlatforms({ all: true })
      }
      return ({ openCreate: !state.openCreate })
    })
    if(['PLATFORM'].includes(createType)) {
      this.setState((state) => ({ createType }))
    }
  }

  toggleUpdate = (platform) => {
    this.props.getPlatforms({ all: true })
    this.setState((state) => {
      return ({ openUpdate: !state.openUpdate, platform })
    })
    
  }

  handleCreate = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { auth, platforms } = this.props
    const { openCreate, openUpdate, createType, platform } = this.state
    const { docs = [], totalPages = 1 } = platforms;

    if(Object.keys(auth).length === 0){
      this.props.history.push('/login')
    }

    const SideCreate = ({ open, close }) => {
      const form = {
        'PLATFORM': { title: 'Criar Plataforma', component: <PlatformCreate platforms={platforms} closeCreate={close} /> }
      }

      if (!createType) return null

      return (
        <Drawer open={open} onClose={close} anchor='top' >
          <Container style={{ padding: "16px 0"}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">{form[createType]['title']}</Typography>
              </Grid>
              <Grid item xs={12}>
                {
                  form[createType]['component']
                }
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      )
    }

    const SideUpdate = ({ open, close }) => {
      return (
        <Drawer open={open} onClose={close} anchor='top' >
          <Container style={{ padding: "16px 0"}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">Editar Plataforma</Typography>
              </Grid>
              <Grid item xs={12}>
                <PlatformEdit platforms={platforms} platform={platform || null} closeUpdate={close} />
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      )
    }

    return (
      <div>
        <UserBar auth={auth} />
        <SideCreate open={openCreate} close={this.toggleCreate}/>
        <SideUpdate open={openUpdate} close={this.toggleUpdate}/>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h5' style={{ marginTop: 8 }}>Plataformas</Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                <ListItem button onClick={() => this.toggleCreate('PLATFORM')}>
                  <ListItemIcon>
                    <StoreMallDirectoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Criar plataforma" />
                </ListItem>
                <Divider />
              </List>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <PlatformList docs={docs} toggleShow={this.toggleUpdate} />
                    </Grid>
                    <Grid item style={{ textAlign: 'center' }} xs={12}>
                      <div>
                        <Pagination style={{ margin: '16px auto', display: 'inline-block' }} count={totalPages} color="primary" onChange={this.handlePaginate} />
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, platforms }) => {
  return {
    auth,
    platforms
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPlatforms: (query) => dispatch(getPlatformsFetchData(query)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Platforms)