import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Grid, InputAdornment } from '@material-ui/core'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import CircularProgress from '@material-ui/core/CircularProgress'
import NumberFormat from 'react-number-format';
import translations from '../../translations/translations';



function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale="true"
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

class TransferForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired
  }

  state = {
    value: '',
    destination_user: '',
    comment: ''
  }

  reset() {
    this.setState((state) => ({
      value: '',
      destination_user: '',
      comment: ''
    }))
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  handleSubmit = () => {
    this.props.handleSubmit(this.state, () => this.reset())
  }

  render() {
    const { destination_user, value, comment } = this.state
    const { isFetching, lang } = this.props

    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              label="Email do Destino"
              variant="filled"
              onChange={this.handleChange}
              name="destination_user"
              value={destination_user}
              validators={['required']}
              errorMessages={['Campo obrigatório']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              label={translations[lang].value}
              variant="filled"
              onChange={this.handleChange}
              name="value"
              value={value}
              validators={['required']}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputComponent: NumberFormatCustom,
              }}
              errorMessages={['Campo obrigatório']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              label={translations[lang].comment}
              variant="filled"
              onChange={this.handleChange}
              name="comment"
              value={comment}
            />
          </Grid>
          <Grid item xs={6}>
          <Button 
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              disabled={isFetching}
              style={{ height: 55 }}
            >
              {isFetching && <CircularProgress size={20} />} {translations[lang].transfer_action}
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    )
  }
}

export default TransferForm;