
import { Typography } from '@material-ui/core'
import React from 'react'

const TimeCountWidget = ({hours}) => {

  
  const colors = {
    'grey': '#808080',
    'orange': '#e2891b',
    'red': '#e40d0d',
  }

  let hourColor = ''

  if(hours <= 2) {
    hourColor = colors.grey
  } else if(hours <= 8) {
    hourColor = colors.orange
  } else {
    hourColor = colors.red
  }

  return(
    <Typography variant="body1" style={{ fontSize: 12, background:hourColor, color:'white', fontWeight:'bold', borderRadius:5, padding:3, width:'fit-content', marginTop:4 }}>{hours}h</Typography>
  )
}

export default TimeCountWidget