export const sale = (state = {}, action) => {
  switch (action.type) {
    case 'GET_SALE':
      return action.sale
    default:
        return state;
  }
}

export const sales = (state = [], action) => {
  switch (action.type) {
    case 'GET_SALES':
      return action.sales
    default:
        return state;
  }
}

export const recent_sales = (state = [], action) => {
  switch (action.type) {
    case 'GET_RECENT_SALES':
      return action.recent_sales
    default:
        return state;
  }
}