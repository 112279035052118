import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import UserBar from '../auth/UserBar'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import { Card, Typography, CardContent, Drawer } from '@material-ui/core';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { getPlatformTransactionsFetchData, getSafeBalanceFetchData, getPlatformsResumeFetchData, getUsersBalanceFetchData } from './actions';
import SafeFilters from './SafeFilters';
import Pagination from '@material-ui/lab/Pagination';
import { getFiltersFetchData, getUsersFetchData } from '../users/actions';
import { getPlatformsFetchData } from '../platforms/actions';
import PlatformTransactionList from './PlatformTransactionList';
import _ from 'lodash'
import moment from 'moment'
import SideView from '../common/SideView';
import NumberFormat from 'react-number-format'
import SafeTransactionCreate from './SafeTransactionCreate';
import ResumeWidgets from '../common/ResumeWidgets'


class Safe extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  state = {
    anchorEl: null,
    openCreate: false,
    createType: null
  }

  parseQueryParams() {
    return _.chain(this.props.location.search)
    .replace('?', '') // a=b454&c=dhjjh&f=g6hksdfjlksd
    .split('&') // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
    .map(_.partial(_.split, _, '=', 2)) // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
    .fromPairs() // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
    .value()
  }

  setFilters(filters) {
    const { startDate, endDate } = filters;
    if(startDate) {
      filters.startDate = moment(startDate, 'DD/MM/YYYY').utc().toDate()
    }
    if(endDate) {
      filters.endDate = moment(endDate, 'DD/MM/YYYY').utc().toDate()
    }

    this.props.getFilters(({
      ...this.props.filters,
      ...filters
    }))
    
  }

  handlePaginate = (event, value) => {
    const { platform_transactions } = this.props
    const { page } = platform_transactions
    this.props.getPlatformTransactions({ page: value || page, query: this.props.filters })
  }

  toggleShow = (document, user, platform) => {

    if(user) {
      document.user =  user
    }

    if(platform) {
      document.platform = platform
    }
    this.setState((state) => ({ openShow: !state.openShow, document }))
  }

  componentDidMount() {
    this.props.getPlatforms({ all: true })
    this.props.getPlatformsResume()
    this.props.getUsers({})
    this.props.getSafeBalance()
    this.props.getUsersBalance()
    const search = this.parseQueryParams()
    const filters = {
      startDate: moment().subtract(7, 'days').toDate(),
      endDate: moment().toDate(),
      platform: 'all',
      user: 'all',
    }

    if(search) {
      this.setFilters({
        ...filters,
        ...search
      })
    }
    this.props.getPlatformTransactions({ page: 1, query: {
      ...filters,
      ...search
    } })
  }

  toggleCreate = (createType) => {
    this.setState((state) => {
      if(state.openCreate) {
        this.props.getSafeBalance()
        this.props.getPlatformsResume()
        this.handlePaginate()
      }
      return ({ openCreate: !state.openCreate })
    })
    if(['DEPOSIT', 'WITHDRAW'].includes(createType)) {
      this.setState((state) => ({ createType }))
    }
  }

  render() {
    const { auth, platforms, filters, platform_transactions, safe_balance, resume, users_balance, users } = this.props
    const { openCreate, createType, openShow, document } = this.state
    const { docs = [], totalPages = 1, page = 1 } = platform_transactions;


    if(Object.keys(auth).length === 0){
      this.props.history.push('/login')
    }

    const SideCreate = ({ open, close }) => {
      const form = {
        'DEPOSIT': { title: 'Criar depósito', component: <SafeTransactionCreate closeCreate={close} platforms={platforms.docs} type="DEPOSIT"/> },
        'WITHDRAW': { title: 'Criar saque', component: <SafeTransactionCreate closeCreate={close} platforms={platforms.docs} type="WITHDRAW"/> }
      }
      if (!createType) return null

      return (
        <Drawer open={open} onClose={close} anchor='top' >
          <Container style={{ padding: "16px 0"}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">{form[createType]['title']}</Typography>
              </Grid>
              <Grid item xs={12}>
                {
                  form[createType]['component']
                }
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      )
    }

    return (
      <div>
        <UserBar auth={auth} />
        <SideCreate open={openCreate} close={this.toggleCreate}/>
        <SideView open={openShow} close={this.toggleShow} document={document}/>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h5' style={{ marginTop: 8 }}>Cofre</Typography>
            </Grid>
            <Grid item xs={12}>
                <ResumeWidgets resume={resume}/>
            </Grid>
            <Grid item xs={6}>
              <div style={{ background: '#eee', borderRadius: 5, padding: 8, fontSize: 18, textAlign: 'center' }}>
                <Typography variant="caption">Saldo <b>Plataformas</b></Typography>
                <Typography><b>
                  <NumberFormat 
                    value={safe_balance/100} 
                    displayType={'text'} 
                    thousandSeparator="."
                    decimalSeparator=","
                    fixedDecimalScale="true"
                    decimalScale={2}
                    />  
                </b></Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ background: '#eee', borderRadius: 5, padding: 8, fontSize: 18, textAlign: 'center' }}>
                <Typography variant="caption">Saldo <b>Usuários</b></Typography>
                <Typography><b>
                  <NumberFormat 
                    value={users_balance/100} 
                    displayType={'text'} 
                    thousandSeparator="."
                    decimalSeparator=","
                    fixedDecimalScale="true"
                    decimalScale={2}
                    />  
                </b></Typography>
              </div>
            </Grid>
            {/* <Grid item xs={12}>
              <List>
                <ListItem button onClick={() => this.toggleCreate('DEPOSIT')}>
                  <ListItemIcon>
                    <AddCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Criar depósito" />
                </ListItem>
                <ListItem button onClick={() => this.toggleCreate('WITHDRAW')}>
                  <ListItemIcon>
                    <RemoveCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Criar saque" />
                </ListItem>
                <Divider />
              </List>
            </Grid> */}
            <Grid item xs={12}>
              <SafeFilters platforms={platforms.docs || platforms} users={users.docs || users} filters={filters} setFilters={(query) => { this.props.getPlatformTransactions({ page: 1, query }) }}/>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <PlatformTransactionList docs={docs} toggleShow={this.toggleShow}/>
                    </Grid>
                    <Grid item style={{ textAlign: 'center' }} xs={12}>
                      <div>
                        <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={page} count={totalPages} color="primary" onChange={this.handlePaginate} />
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, platform_transactions, safe_balance, platforms, resume, users_balance, users, filters }) => {
  return {
    auth,
    platform_transactions,
    safe_balance,
    platforms,
    resume,
    users_balance,
    users,
    filters
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPlatformTransactions: (query) => dispatch(getPlatformTransactionsFetchData(query)),
    getFilters: (filters) => dispatch(getFiltersFetchData(filters)),
    getPlatforms: (query) => dispatch(getPlatformsFetchData(query)),
    getSafeBalance: () => dispatch(getSafeBalanceFetchData()),
    getPlatformsResume: () => dispatch(getPlatformsResumeFetchData()),
    getUsersBalance: () => dispatch(getUsersBalanceFetchData()),
    getUsers: (query) => dispatch(getUsersFetchData(query)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Safe)
