        
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, TableContainer, TableBody, TableCell, TableRow, TableHead, Table, Typography } from '@material-ui/core'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import translations from '../../translations/translations'

class TransactionList extends Component {
  
  render() {
    const { docs = [], lang } = this.props;
    const colors = {
      'C': 'green',
      'D': 'red'
    }
    const operation = {
      'C': '+',
      'D': '-'
    }
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{translations[lang].date}</TableCell>
              <TableCell>{translations[lang].origin}</TableCell>
              <TableCell align="right">{translations[lang].value}</TableCell>
              <TableCell align="right">{translations[lang].balance}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { docs.length ? docs.map(transaction => (
                  <TableRow key={transaction._id}>
                      <TableCell>
                        <Typography variant="body1" style={{ fontSize: 12 }}><b>{moment(transaction.created_at).format('DD/MM/YY')}</b></Typography>
                        <Typography variant="body1" style={{ fontSize: 12 }}>{moment(transaction.created_at).format('HH:mm')}</Typography>
                      </TableCell>
                      <TableCell>
                        <OriginLink transaction={transaction} toggleShow={this.props.toggleShow} lang={lang} />
                      </TableCell>
                      <TableCell align="right">
                        <NumberFormat 
                          style={{ color: colors[transaction.type] }}
                          value={ transaction.value/100} 
                          displayType={'text'} 
                          prefix={`${operation[transaction.type]}`} 
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale="true"
                        />
                      </TableCell>
                      <TableCell align="right">
                        <NumberFormat 
                          style={{ color: colors[transaction.type] }}
                          value={transaction.new_balance ? transaction.new_balance/100 : '-'} 
                          displayType={'text'} 
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale="true"
                        />
                      </TableCell>
                    </TableRow>
            )) : <TableRow>
              <TableCell>Não há registros.</TableCell>
            </TableRow> }
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

const OriginLink = ({ transaction, toggleShow, lang }) => {
  const { transfer, sale, purchase } = transaction;
  let text = ''
  let document = {}
  if(transfer) {
    document = transfer;
    text = translations[lang].transfer
  }
  if(sale) {
    document = sale;
    text = translations[lang].sale
  }
  if(purchase) {
    document = purchase;
    text = translations[lang].purchase
  }
  return (<Button size="small" color="primary" variant="contained" onClick={() => { toggleShow(document) }}>{text}</Button>)
}

const mapStateToProps = ({ auth, lang }) => {
  return {
    auth,
    lang
  }
}


export default withRouter(connect(mapStateToProps)(TransactionList))

      