import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Grid, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Checkbox, InputAdornment } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import CircularProgress from '@material-ui/core/CircularProgress'
import NumberFormat from 'react-number-format';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale="true"
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

class PlatformForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired
  }

  componentDidMount() {
    const { platform } = this.props;
    this.setState({
      ...platform,
      sale_limit: platform ? platform.sale_limit / 100 : 0,
      origin: platform && platform.origin ? platform.origin : "none"
    })
  }

  state = {
    name: '',
    origin: 'none',
    identifer: '',
    purchase_identifier: '',
    purchase: false,
    sale: false,
    sale_limit: '',
    fee: ''
  }

  reset() {
    this.setState((state) => ({
      name: '',
      origin: 'none',
      identifer: '',
      purchase: false,
      sale: false,
      sale_limit: '',
      fee: ''
    }))
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }


  handleChangeCheckbox = (event) => {
    const value = event.target.checked
    const field = event.target.name
    this.setState({ [field]: value })
  }


  handleSubmit = () => {
    console.log({ state: this.state, platform: this.props.platform })
    const platform = this.props.platform
    this.props.handleSubmit({ ...this.state, _id: platform ? platform._id : null })
  }

  render() {
    const { name, origin, identifier, purchase_identifier, purchase, sale, sale_limit, fee } = this.state
    const { isFetching, platforms } = this.props


    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextValidator
              fullWidth
              label="Nome"
              variant="filled"
              onChange={this.handleChange}
              name="name"
              value={name}
            />
          </Grid>

          <Grid item xs={4}>
            <TextValidator
              fullWidth
              label="Identificador"
              variant="filled"
              onChange={this.handleChange}
              name="identifier"
              value={identifier}
            />
          </Grid>
          <Grid item xs={4}>
            <TextValidator
              fullWidth
              label="Identificador do Sensei"
              variant="filled"
              onChange={this.handleChange}
              name="purchase_identifier"
              value={purchase_identifier}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="origin-label">Platforma Origem</InputLabel>
              <Select
                labelId="origin-label"
                id="origin"
                name="origin"
                variant="filled"
                value={origin}
                onChange={this.handleChange}
              >
                <MenuItem value="none">Nenhuma</MenuItem>
                {
                  platforms && platforms.docs.length ? platforms.docs.map(platform => <MenuItem key={platform._id} value={platform._id}>{platform.name}</MenuItem>) : null
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={sale|| false} onChange={this.handleChangeCheckbox} name={`sale`} />}
                label="Envio"
              />
              <FormControlLabel
                control={<Checkbox checked={purchase || false} onChange={this.handleChangeCheckbox} name={`purchase`} />}
                label="Recebimento"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <TextValidator
                fullWidth
                label="Limite sem taxa"
                variant="filled"
                onChange={this.handleChange}
                name="sale_limit"
                value={sale_limit}
                validators={['required']}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
                errorMessages={['Campo obrigatório']}
              />
          </Grid>
          <Grid item xs={4}>
            <TextValidator
                fullWidth
                label="Taxa"
                variant="filled"
                onChange={this.handleChange}
                name="fee"
                value={fee}
                validators={['required']}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
                errorMessages={['Campo obrigatório']}
              />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              disabled={isFetching}
              style={{ height: 55 }}
            >
              {isFetching && <CircularProgress size={20} />}
                Salvar
              </Button>
          </Grid>
        </Grid>
       
      </ValidatorForm >
    )
  }
}

export default PlatformForm;