import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Grid, MenuItem } from '@material-ui/core'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import CircularProgress from '@material-ui/core/CircularProgress'


class NoticeForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired
  }

  state = {
    message: '',
    message_ar: '',
    message_us: '',
    type: '',
  }

  reset() {
    this.setState((state) => ({
      message: '',
      message_ar: '',
      message_us: '',
      type: '',
    }))
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  handleChangeUser = (event) => {
    const value = event.target.value
    const { users } = this.props;
    const destination_user = users[value]['_id'] || null; 
    this.setState({ destination_user })
  }


  handleSubmit = () => {
    this.props.handleSubmit(this.state, () => this.reset())
  }

  render() {
    const { message, type, message_us, message_ar } = this.state
    const { isFetching } = this.props

    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
        
      >
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextValidator
                fullWidth
                label="Tipo de Aviso"
                variant="filled"
                onChange={this.handleChange}
                name="type"
                select
                value={type}
                validators={['required']}
                errorMessages={['Campo obrigatório']}
              >
                <MenuItem value="NOTICE">Comunicado</MenuItem>
                <MenuItem value="WARNING">Alerta</MenuItem>
                <MenuItem value="NEWS">Novidade</MenuItem>
              </TextValidator>
          </Grid>
          <Grid item xs={4}>
            <TextValidator
                fullWidth
                label="Mensagem"
                variant="filled"
                onChange={this.handleChange}
                multiline
                rows={5}
                name="message"
                value={message}
                validators={['required']}
                errorMessages={['Campo obrigatório']}
              />
          </Grid>
          <Grid item xs={4}>
            <TextValidator
                fullWidth
                label="Mensagem (AR)"
                variant="filled"
                onChange={this.handleChange}
                multiline
                rows={5}
                name="message_ar"
                value={message_ar}
                validators={['required']}
                errorMessages={['Campo obrigatório']}
              />
          </Grid>
          <Grid item xs={4}>
            <TextValidator
                fullWidth
                label="Mensagem (US)"
                variant="filled"
                onChange={this.handleChange}
                multiline
                rows={5}
                name="message_us"
                value={message_us}
                validators={['required']}
                errorMessages={['Campo obrigatório']}
              />
          </Grid>
          <Grid item xs={4}>
          <Button 
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              disabled={isFetching}
              style={{ height: 55 }}
            >
              {isFetching && <CircularProgress size={20} />} Salvar
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    )
  }
}

export default NoticeForm;