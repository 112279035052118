import React, { Component } from 'react'
import { Button, Dialog, Typography } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css'
import translations from '../../translations/translations';


class CompleteRegistrationSuccess extends Component {
  render() {
    const { isOpen, handleClose, lang } = this.props

    return (
        <Dialog PaperProps={{style:{padding: 16}}} open={isOpen} onClose={handleClose}>
          <Typography variant='h5' style={{padding:"20px 20px 0 20px", textAlign:'center'}}>
            {translations[lang].registration_finish}
          </Typography>
          <Typography variant='caption' style={{textAlign:"center", padding:'0 20px'}}>{translations[lang].finish_info}</Typography>
          <Button 
            variant="contained"
            fullWidth
            color="primary"
            type="button"
            margin="normal"
            style={{ marginTop: 16 }}
            onClick={handleClose}
          >
            <b>OK</b>
          </Button>
        </Dialog>
    )
  }
}


export default CompleteRegistrationSuccess
