import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormControl, InputLabel, Select, MenuItem, Grid, Card, CardContent, TextField } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { getFiltersFetchData } from '../users/actions';
import translations from '../../translations/translations';



class BasicFilters extends Component {

  handleStartDateChange = (date) => {
    const filters = {
      ...this.props.filters,
      startDate: moment(date).utc().toDate()
    }
    this.props.getFilters(filters);
    this.props.setFilters(filters);
  };

  handleEndDateChange = (date) => {
    const filters = {
      ...this.props.filters,
      endDate: moment(date).utc().toDate()
    }
    
    this.props.getFilters(filters);
    this.props.setFilters(filters);
  };

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    const filters = {
      ...this.props.filters,
      [field]: value
    };

    this.props.getFilters(filters);

    if(field==='identifier'){
      if(value.length > 2 || value.length < 1) {  
        this.props.setFilters(filters);
      }
    } else {
      this.props.setFilters(filters);
    }
  }

  render() {
    const { users, platforms, filters, lang, me } = this.props;
    const { platform = 'all', status = 'all', startDate, endDate, identifier='' } = filters
    return (
      <Card>
        <CardContent>
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  id="date-picker-dialog"
                  label={translations[lang].start_date}
                  format="dd/MM/yyyy"
                  value={moment(startDate).utc(false).toDate()}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
              
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    margin="normal"
                    id="date-picker-dialog"
                    label={translations[lang].end_date}
                    format="dd/MM/yyyy"
                    value={moment(endDate).utc(false).toDate()}
                    onChange={this.handleEndDateChange}
                  />
                </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="space-between">
            {
              platforms ?
              (<Grid item xs={['ADMIN', 'EMPLOYEE'].includes(me.role) && platforms ? 4 : 6}>
                <FormControl fullWidth>
                  <InputLabel id="platform-label">{translations[lang].platform}</InputLabel>
                  <Select
                  labelId="platform-label"
                  id="platfrom"
                  name="platform"
                  value={platform}
                  onChange={this.handleChange}
                  >
                    <MenuItem value="all">{translations[lang].all}</MenuItem>
                    {
                     platforms.length ? platforms.map(platform => <MenuItem key={platform._id} value={platform._id}>{platform.name}</MenuItem>) : null
                    }
                  </Select>
                </FormControl>
              </Grid>)
              : null
            }
            <Grid item xs={ ['ADMIN', 'EMPLOYEE'].includes(me.role) && platforms ? 4 : 6}>
              <FormControl fullWidth>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                labelId="status-label"
                id="status"
                name="status"
                value={status}
                onChange={this.handleChange}
                >
                  <MenuItem value="all">{translations[lang].all}</MenuItem>
                  <MenuItem value="PENDING">{translations[lang].pending}</MenuItem>
                  <MenuItem value="APPROVED">{translations[lang].approved}</MenuItem>
                  <MenuItem value="DECLINED">{translations[lang].declined}</MenuItem>
                  <MenuItem value="CANCELED">{translations[lang].canceled}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
           
           {
             ['ADMIN', 'EMPLOYEE'].includes(me.role) && users ? 
              (<Grid item xs={platforms ? 4 : 6}>
                <FormControl fullWidth>
                  <TextField
                  label='Identificador'
                  id="identifier"
                  name="identifier"
                  value={identifier}
                  onChange={this.handleChange}
                  />
                </FormControl>
              </Grid>)
             : null
           }
          </Grid>
        </CardContent>
      </Card>
      )
  }
}


const mapStateToProps = ({ auth, filters, lang, me}) => {
  return {
    auth,
    filters,
    lang,
    me
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFilters: (filters) => dispatch(getFiltersFetchData(filters)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicFilters)