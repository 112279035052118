export const transfer = (state = {}, action) => {
  switch (action.type) {
    case 'CREATE_TRANSFER':
      return action.transfer
    default:
        return state;
  }
}

export const transfers = (state = [], action) => {
  switch (action.type) {
    case 'GET_TRANSFERS':
      return action.transfers
    default:
        return state;
  }
}

export const recent_transfers = (state = [], action) => {
  switch (action.type) {
    case 'GET_RECENT_TRANSFERS':
      return action.recent_transfers
    default:
        return state;
  }
}