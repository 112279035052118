import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import UserBar from '../auth/UserBar'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import { Drawer, Card, Typography, CardContent } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SendIcon from '@material-ui/icons/Send';
import SaleCreate from './SaleCreate';
import BasicList from '../common/BasicList';
import { getSalesFetchData, getReportFetchData } from './actions'
import { getPlatformsFetchData } from '../platforms/actions'
import Pagination from '@material-ui/lab/Pagination';
import SideView from '../common/SideView';
import BasicFilters from '../common/BasicFilters';
import { getUsersFetchData, getFiltersFetchData, getPlatformRulesFetchData } from '../users/actions';
import _ from 'lodash'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import { CSVLink } from 'react-csv';

import NumberFormat from 'react-number-format';
import translations from '../../translations/translations';
class Sales extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  state = {
    anchorEl: null,
    filters: {
      startDate: moment().subtract(7, 'days').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      platform: 'all',
      user: 'all',
      status: 'all',
    },
    reportData: []
  }

  getSales({ page=1, query={} }) {
    this.props.getSales({ page, query })

    if(this.props.me.role === 'ADMIN') {
      this.fetchReport(query)
    }
  }

  parseQueryParams() {
    return _.chain(this.props.location.search)
    .replace('?', '') // a=b454&c=dhjjh&f=g6hksdfjlksd
    .split('&') // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
    .map(_.partial(_.split, _, '=', 2)) // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
    .fromPairs() // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
    .value()
  }

  setFilters(filters) {
    const { startDate, endDate } = filters;
    if(startDate) {
      filters.startDate = moment(startDate, 'DD/MM/YYYY').utc().toDate()
    }
    if(endDate) {
      filters.endDate = moment(endDate, 'DD/MM/YYYY').utc().toDate()
    }

    this.props.getFilters(({
      ...this.props.filters,
      ...filters
    }))

    this.handlePaginate()
  }

  componentDidMount() {
    this.props.getPlatforms({ all: true })
    this.props.getPlatformRules()
    const search = this.parseQueryParams()
    const filters = {
      startDate: moment().subtract(7, 'days').toDate(),
      endDate: moment().toDate(),
      platform: 'all',
      user: 'all',
      status: 'all'
    }

    if(search) {
      this.setFilters({
        ...filters,
        ...search
      })
    }

  }

  toggleCreate = (createType) => {
    this.setState((state) => {
      if(state.openCreate) {
        this.handlePaginate()
      }
      return ({ openCreate: !state.openCreate })
    })

    if(['SALE'].includes(createType)) {
      this.setState((state) => ({ createType }))
    }
  }

  toggleShow = (document) => {
    this.setState((state) => ({ openShow: !state.openShow, document }))
  }

  updateSale = (document) => {
    this.setState((state) => ({ 
      openShow: false,  
      snackMessage: translations[this.props.lang].sale_success,
      snackbarOpen: true 
    }))
    this.handlePaginate()
  }

  handleCreate = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  fetchReport = (query = {}) => {
    this.props.getReport(query).then((reportData) => {
      this.setState({ reportData })
    }).catch((err) => {
      console.log({err})
    })
  }

  handlePaginate = (event, value) => {
    const { sales, filters } = this.props
    const { page } = sales
    this.getSales({ page: value || page, query: filters })

  }

  render() {
    const { auth, sales, platforms, users, filters, lang, me } = this.props
    const { docs = [], totalPages = 1, page = 1, report } = sales;
    const { openCreate, createType, openShow, document, reportData } = this.state
    const reportHeaders = [
        {
          label: 'id',
          key: '_id'
        },
        {
          label: 'created_at',
          key: 'created_at'
        },
        {
          label: 'user',
          key: 'user.nickname'
        },  
        {
          label: 'platform',
          key: 'platform.name'
        },          
        {
          label: 'identifier',
          key: 'identifier'
        },              
        {
          label: 'status',
          key: 'status'
        },
        {
          label: 'value',
          key: 'value'
        },   
        {
          label: 'comment',
          key: 'comment'
        },
        {
          label: 'message',
          key: 'message'
        },
        {
          label: 'receipt',
          key: 'receipt'
        }     
      ];
    
    
      if(Object.keys(auth).length === 0){
        this.props.history.push('/login')
      }

    const SideCreate = ({ open, close }) => {
      const form = {
        'SALE': { title: translations[lang].sale_create, component: <SaleCreate closeCreate={close} platforms={platforms}/> }
      }

      if (!createType) return null

      return (
        <Drawer open={open} onClose={close} anchor='top' >
          <Container style={{ padding: "16px 8px"}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">{form[createType]['title']}</Typography>
                <Typography variant="body1">{translations[lang].transactions_info}</Typography>
              </Grid>
              <Grid item xs={12}>
                {
                  form[createType]['component']
                }
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      )
    }

    return (
      <div>
        <UserBar auth={auth} />
        <SideCreate open={openCreate} close={this.toggleCreate}/>
        <SideView open={openShow} close={this.toggleShow} document={document} updated={this.updateSale}/>
        
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h5' style={{ marginTop: 8 }}>{translations[lang].sales}</Typography>
            </Grid>
            {
              me && ['ADMIN','USER'].includes(me.role)  ?
              <Grid item xs={12}>
                 <Grid item xs={12}>
                  <Button onClick={() => this.toggleCreate('SALE')} variant="contained" color="primary" fullWidth size="large">
                    <SendIcon style={{ fontSize: 16, marginRight: 8 }} />
                    <Typography variant="body1">{translations[lang].sale_action}</Typography>
                  </Button>
                </Grid>
              </Grid> : null 
            }
            <Grid item xs={12}>
              <BasicFilters users={users.docs || users} platforms={platforms.docs || platforms} filters={filters} setFilters={(e) => { this.getSales({ page: 1, query: e }) }}/>
            </Grid>
            {
              me.role === 'ADMIN' && reportData.length ? 
                <Grid item xs={12}>
                  <CSVLink data={reportData} headers={reportHeaders} filename={"relatorio.csv"} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="primary">
                      <CloudDownloadIcon style={{ marginRight: 8 }} /> Baixar relatório
                    </Button>
                  </CSVLink>
                </Grid>
              : null
            }
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <BasicList docs={docs} toggleShow={this.toggleShow} type="SALE" />
                    </Grid>
                    { me && me.role !== 'USER' && report ?
                      <Grid item xs={12}>
                        <Typography variant="body2" align="right" style={{ padding: '8px 16px', background: '#f7f7f7'}}>Total:  <b><NumberFormat 
                          value={report/100} 
                          displayType={'text'} 
                          thousandSeparator="."
                          decimalSeparator=","
                          fixedDecimalScale="true"
                        /></b></Typography>
                      </Grid> : null 
                    }
                    <Grid item style={{ textAlign: 'center' }} xs={12}>
                      <div>
                        <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={page} count={totalPages} color="primary" onChange={this.handlePaginate} />
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, users, sales, platforms, filters, lang, me }) => {
  return {
    auth,
    me,
    users,
    sales,
    platforms,
    filters,
    lang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSales: (sale) => dispatch(getSalesFetchData(sale)),
    getPlatforms: (query) => dispatch(getPlatformsFetchData(query)),
    getUsers: (query) => dispatch(getUsersFetchData(query)),
    getFilters: (filters) => dispatch(getFiltersFetchData(filters)),
    getReport: (query) => dispatch(getReportFetchData(query)),
    getPlatformRules: () => dispatch(getPlatformRulesFetchData({})),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sales)