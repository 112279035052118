import { combineReducers } from 'redux'
import { auth, lang, signUpInfo } from '../components/auth/reducers'
import { user, users, balance, me, filters, rules} from '../components/users/reducers'
import { sales, sale, recent_sales } from '../components/sales/reducers'
import { purchases, purchase, recent_purchases } from '../components/purchases/reducers'
import { transfers, transfer, recent_transfers } from '../components/transfers/reducers'
import { transactions } from '../components/transactions/reducers'
import { platforms } from '../components/platforms/reducers'
import { notices, myNotices } from '../components/notices/reducers'
import { dollarPrices, recentDollarPrices } from '../components/dollar-prices/reducers'
import { platform_transactions, safe_balance, resume, users_balance } from '../components/safe/reducers'
export default combineReducers({
  auth,
  balance,
  sale,
  sales,
  recent_sales,
  purchase,
  purchases,
  recent_purchases,
  transfer,
  transfers,
  recent_transfers,
  user,
  me,
  users,
  notices,
  myNotices,
  transactions,
  platforms,
  filters,
  platform_transactions,
  safe_balance,
  resume,
  users_balance,
  rules,
  lang,
  dollarPrices,
  recentDollarPrices,
  signUpInfo
})