        
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { TableContainer, TableBody, TableCell, TableRow, TableHead, Table, Grid } from '@material-ui/core' 
import PlatformRow from './PlatformRow';
class PlatformList extends Component {


  state = {
    edit: false,
    platform: null
  }

  onPlatformChange(platform) {
    this.props.toggleShow(platform)
    this.setState({ edit: true, platform })
  }

  render() {
    const { docs } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Taxa</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { docs.length ? docs.map(doc => (
                    <PlatformRow key={doc._id} doc={doc} onPlatformChange={(platform) => this.onPlatformChange(platform)}/>
                )) : <TableRow>
                <TableCell>Não há registros.</TableCell>
                </TableRow> }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid> 
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  }
}



export default withRouter(connect(mapStateToProps)(PlatformList))

      