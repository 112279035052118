import { routes } from './core/api';
import moment from 'moment'
import Big from 'big.js'
import { Auth as AuthCognito } from 'aws-amplify';

export const createPurchase = (purchase) => {
  return {
      type: 'CREATE_PURCHASE',
      purchase
  }
}


export const getPurchases = (purchases) => {
  return {
      type: 'GET_PURCHASES',
      purchases
  }
}

export const getRecentPurchases = (recent_purchases) => {
  return {
      type: 'GET_RECENT_PURCHASES',
      recent_purchases
  }
}

export const updatePurchaseStatus = (purchase) => {
  return {
    type: 'UPDATE_PURCHASE_STATUS',
    purchase
  }
}

export function updatePurchaseStatusFetchData(id, action, body) {
  
  return dispatch => {
    return new Promise((resolve, reject) => {

      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.update+id+`/${action}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
          body: ['approve','decline'].includes(action) ? JSON.stringify({ ...body }) : null
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Recebimento não atualizado!');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(createPurchase(body))
          resolve(body)
        })
      })
    })
  }
}

export function getPurchasesFetchData({page = 1, query = {}}) {
  let qs = '?';
    if(page) {
      qs += 'page='+page
    }


  if(query.platform==='all') {
    delete query.platform
  }
  if(query.user==='all') {
    delete query.user
  }
  if(query.status==='all') {
    delete query.status
  }

  query.startDate = moment(query.startDate).toISOString()
  query.endDate = moment(query.endDate).toISOString()
   
  var queryString = Object.keys(query).map((key) => {
    if(key && query[key]) return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
    return ''
}).join('&');

  let route = routes.get+qs;
  if(queryString.length) {
    route += `&${queryString}`
  }
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
          
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Invalid credentials');
            
           }
          return res.json() 
        })
        .then(body => {
          dispatch(getPurchases(body))
          resolve(body)
        })
      })
    })
  }
}

export function getRecentPurchasesFetchData({user, status}) {
  let qs = '';
  if(user) {
    qs = `&user=${user}`
  }

  if(status) {
    qs += `&status=${status}`
  }

  // qs+='&sort=created_at'
  
  let route = routes.get+`?limit=3${qs}`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Invalid credentials');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(getRecentPurchases(body))
          resolve(body)
        })
      })
    })
  }
}

export function createPurchaseFetchData(body) {
  let purchase = {
    ...body,
    value: Big(body.value).times(100).toNumber()
  }
  return dispatch => {
    return new Promise((resolve, reject) => {

      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.create, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
          body: JSON.stringify(purchase)
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Recebimento não realizado!');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(createPurchase(body))
          resolve(body)
        })
      })
    })
  }
}

export function adminCreatePurchaseFetchData(body) {
  let purchase = {
    ...body,
    value: Big(body.value).times(100).toNumber()
  }
  return dispatch => {
    return new Promise((resolve, reject) => {

      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.adminCreate, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
          body: JSON.stringify(purchase)
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Recebimento não realizado!');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(createPurchase(body))
          resolve(body)
        })
      })
    })
  }
}