import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import UserBar from '../auth/UserBar'
import Container from '@material-ui/core/Container';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import {
  Snackbar, Drawer, Button,
  Card, Typography, CardContent
} from '@material-ui/core';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import SendIcon from '@material-ui/icons/Send';
import TransformIcon from '@material-ui/icons/Transform';
import CloseIcon from '@material-ui/icons/Close';
import SaleCreate from '../sales/SaleCreate';
import PurchaseCreate from '../purchases/PurchaseCreate';
import TransferCreate from '../transfers/TransferCreate';
import { Link } from "react-router-dom";
import { getRecentPurchasesFetchData } from '../purchases/actions'
import { getRecentTransfersFetchData } from '../transfers/actions'
import { getRecentSalesFetchData } from '../sales/actions'
import { getPlatformsFetchData } from '../platforms/actions'
import { getPlatformRulesFetchData, documentVerification } from '../users/actions';
import { agreeToTerms, signInUser } from '../auth/actions'
import WarningIcon from '@material-ui/icons/Warning';
import BasicList from '../common/BasicList'
import TransferList from '../transfers/TransferList'
import SideView from '../common/SideView';
import Notice from '../notices/Notice';
import translations from '../../translations/translations';
import TermsOfUseDialog from '../auth/TermsOfUseDialog';
import { Auth as AuthCognito } from 'aws-amplify'

class Home extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  state = {
    anchorEl: null,
    openCreate: false,
    createType: null,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center',
    termsOfUseOpen: false,
    isFetchingAgree: false
  }

  toggleShow = (document) => {
    this.setState((state) => ({ openShow: !state.openShow, document }))
  }


  handleCloseSnackbar = () => {
    this.setState((state) => ({ ...state, snackbarOpen: false }));
  };

  handleAgree = async () => {
    this.setState({
      isFetchingAgree: true
    })
    this.props.agreeTerms(this.props.auth.id)
      .then(r => {
        AuthCognito.currentAuthenticatedUser({ bypassCache: true })
          .then(user => {
            //in case there is delay returning the custom updated attribute
            if (!user.attributes["custom:agreed_terms_wallet"]) {
              user.attributes["custom:agreed_terms_wallet"] = "agreed"
            }
            this.props.authenticateUser(user)
            this.setState({
              isFetchingAgree: false
            })
          })
          .catch(err => {
            this.setState({
              snackbarOpen: true,
              snackMessage: err.message
            })
          })
      })
      .catch(err => {
        this.setState({
          snackbarOpen: true,
          snackMessage: err.message
        })
      })
  }

  loadTables = () => {
    let filters = {}
    if (this.props.me.role === 'ADMIN') {
      filters = { status: 'PENDING' }
    }
    this.props.getRecentSales(filters)
    this.props.getRecentPurchases(filters)
    this.props.getRecentTransfers(filters)
  }

  async componentDidMount() {
    await this.props.getPlatforms({ all: true })
    await this.props.getPlatformRules()
    this.loadTables();
    if (this.props.auth.agreed_terms_wallet === false) {
      this.setState({
        termsOfUseOpen: true
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth.agreed_terms_wallet !== false) {
        this.setState({
          termsOfUseOpen: false
        })
      }
    }
  }

  updateDocument = (document) => {
    this.setState((state) => ({
      openShow: false,
      snackMessage: 'Documento atualizado com sucesso!',
      snackbarOpen: true
    }))
    this.loadTables()
  }

  documentVerification = () => {
    documentVerification().then(jumio => {
      window.open(jumio.jumio_url)
    })
  }


  toggleCreate = (createType) => {
    if (['SALE', 'PURCHASE', 'TRANSFER'].includes(createType)) {
      this.setState((state) => ({ createType }))
    }

    this.setState((state) => {
      if (state.openCreate) {
        this.loadTables()
      }
      return ({ openCreate: !state.openCreate })
    })
  }

  handleCreate = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  logoutUser = () => {
    this.props.history.push('/logout')
  }

  render() {
    const { auth, recent_transfers, recent_sales, recent_purchases, platforms, lang, myNotices, me } = this.props
    const { openCreate, openShow, document, createType, vertical, horizontal, snackbarOpen, snackMessage, termsOfUseOpen, isFetchingAgree } = this.state

    if (Object.keys(this.props.auth).length === 0) {
      this.props.history.push('/login')
    }

    const SideCreate = ({ open, close, lang }) => {
      const form = {
        'SALE': { title: `${translations[lang].sale_create}`, component: <SaleCreate closeCreate={close} platforms={platforms} /> },
        'PURCHASE': { title: `${translations[lang].purchase_create}`, component: <PurchaseCreate closeCreate={close} platforms={platforms} /> },
        'TRANSFER': { title: ` ${translations[lang].transfer_create}`, component: <TransferCreate closeCreate={close} /> }
      }

      if (!createType) return null

      return (
        <Drawer open={open} onClose={close} anchor='top' >
          <Container style={{ padding: "16px 8px" }}>
            <Grid container justify="flex-end" style={{ textAlign: 'right' }}>
              <Grid item xs={12}>
                <Button onClick={close} ><CloseIcon /></Button>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">{form[createType]['title']}</Typography>
                <Typography variant="body1">{translations[lang].transactions_info}</Typography>
              </Grid>
              <Grid item xs={12}>
                {
                  form[createType]['component']
                }
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      )
    }

    // const ad_gif = {
    //   br: process.env.PUBLIC_URL + "/senseiclub-pt.gif",
    //   ar: process.env.PUBLIC_URL + "/senseiclub-pt.gif",
    //   us: process.env.PUBLIC_URL + "/senseiclub-en.gif"
    // }

    // const ad = {
    //   br: process.env.PUBLIC_URL + "/senseiclub-pt.png",
    //   ar: process.env.PUBLIC_URL + "/senseiclub-pt.png",
    //   us: process.env.PUBLIC_URL + "/senseiclub-en.png"
    // }

    return (
      <div>
        <UserBar auth={auth} />
        <SideCreate open={openCreate} close={this.toggleCreate} lang={lang} />
        <SideView open={openShow} close={this.toggleShow} document={document} updated={this.updateDocument} />
        <Grid container style={{ marginBottom: 64 }}>
          <Grid item sm={me.role === 'USER' ? 12 : 12 } xs={12}>
            <Container>
              <Grid container spacing={1} style={{ padding: "16px 8px" }}>
                {!me.verified_documents && me.role === 'USER' ?
                  <Grid item xs={12}>
                    <Card>
                      <CardContent style={{ textAlign: 'center' }}>
                        <WarningIcon style={{ color: '#ffd60e' }} />
                        <Typography variant="body1">{translations[lang].confirm_id_message}</Typography>
                        <Button style={{ margin: '16px auto', background: '#ffd60e' }} size="large" variant="contained" onClick={this.documentVerification}>{translations[lang].confirm_id_button}</Button>
                      </CardContent>
                    </Card>
                  </Grid>
                  : null}
                {
                  me && me.role === 'USER' ?
                    <Grid container spacing={1} style={{ padding: "16px 8px" }}>
                      <Grid item xs={12} sm={4}>
                        <Button color="primary" variant="contained" fullWidth onClick={() => this.toggleCreate('SALE')}>
                          <SendIcon style={{ fontSize: 18, marginRight: 4 }} />
                          {translations[lang].sale_action}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Button color="primary" variant="contained" fullWidth onClick={() => this.toggleCreate('PURCHASE')}>
                          <CallReceivedIcon style={{ fontSize: 18, marginRight: 4 }} />
                          {translations[lang].purchase_action}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Button color="primary" variant="contained" fullWidth onClick={() => this.toggleCreate('TRANSFER')}>
                          <TransformIcon style={{ fontSize: 18, marginRight: 4 }} />
                          {translations[lang].transfer_action}
                        </Button>
                      </Grid>
                    </Grid>
                    : null
                }
                {
                  myNotices.length > 0 && me.role === 'USER' && (
                    <Grid item xs={12}>
                      <Grid container alignItems="stretch" spacing={3} style={{ padding: "16px 8px" }}>
                        {
                          myNotices.map((n, i) => {
                            return (
                              <Grid key={i} item xs={12} sm={6} md={4} style={{ padding: 6, marginBottom: 16 }}>
                                <Notice doc={n} />
                              </Grid>
                            )
                          })
                        }
                      </Grid>
                    </Grid>
                  )
                }
                <Grid item xs={12} className={me.role === 'USER' ? 'hidden-xs' : null}>
                  <Card>
                    <CardContent>
                      <Grid container >
                        <Grid item xs={6}>
                          <Typography variant="caption">
                            {me.role === 'ADMIN' ? 'Envios pendentes' : translations[lang].sale_recents}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} align="right"><Link style={{ textDecoration: 'none', color: 'white' }} to="/envios"><Button size="small" color="primary" variant="contained">{translations[lang].sale_all}</Button></Link></Grid>
                      </Grid>
                      <BasicList docs={recent_sales.docs || []} toggleShow={this.toggleShow} type="SALE" />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} className={me.role === 'USER' ? 'hidden-xs' : null}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="caption">
                            {me.role === 'ADMIN' ? 'Recebimentos pendentes' : translations[lang].purchase_recents}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} align="right"><Link style={{ textDecoration: 'none', color: 'white' }} to="/recebimentos"><Button size="small" color="primary" variant="contained">{translations[lang].purchase_all}</Button></Link></Grid>
                      </Grid>
                      <BasicList docs={recent_purchases.docs || []} toggleShow={this.toggleShow} type="PURCHASE" />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} className={me.role === 'USER' ? 'hidden-xs' : null}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="caption">
                            {me.role === 'ADMIN' ? 'Transferências pendentes' : translations[lang].transfer_recents}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} align="right"><Link style={{ textDecoration: 'none', color: 'white' }} to="/transferencias"><Button size="small" color="primary" variant="contained">{translations[lang].transfer_all}</Button></Link></Grid>
                      </Grid>
                      <TransferList transfers={recent_transfers.docs || []} toggleShow={this.toggleShow} />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                key={`${vertical},${horizontal}`}
                open={snackbarOpen}
                onClose={this.handleCloseSnackbar}
                ContentProps={{
                  'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{snackMessage}</span>} />
            </Container>
          </Grid>
          {/* <Grid item sm={3} xs={12} style={{ display: me.role === 'USER' ? 'block' : 'none' }}>
            <Container style={{ padding: 0 }}>
              <Grid container style={{ padding: "16px" }}>
                <Grid item xs={12}>
                  <a href="https://club.sensei.poker" target="_blank" rel="noreferrer" >
                    <img alt="anuncio_gif" src={ad_gif[lang]} style={{ width: '100%' }} />
                  </a>
                  <Hidden only="xs">
                    <a href="https://club.sensei.poker" target="_blank" rel="noreferrer" >
                      <img alt="anuncio" src={ad[lang]} style={{ width: '100%' }} />
                    </a>
                  </Hidden>
                </Grid>
              </Grid>
            </Container>
          </Grid> */}
        </Grid>


        {
          me.role === 'USER' ?
            <BottomNavigation
              style={{
                width: '100%',
                position: 'fixed',
                bottom: 0,
                background: '#101010',
                borderTop: '2px solid',
                borderImageSlice: 1,
                borderWidth: 4,
                borderImageSource: 'linear-gradient(to left, #FE2241, #5D0000)'
              }}
              value={"Home"}
              onChange={(event, newValue) => {
                const type = ['SALE', 'PURCHASE', 'TRANSFER'];
                this.toggleCreate(type[newValue])
              }}
              showLabels
            >
              <BottomNavigationAction style={{ color: '#fff' }} label="Enviar" icon={<SendIcon />} />
              <BottomNavigationAction style={{ color: '#fff' }} label="Receber" icon={<CallReceivedIcon />} />
              <BottomNavigationAction style={{ color: '#fff' }} label="Transferir" icon={<TransformIcon />} />
            </BottomNavigation>
            : null
        }
        <TermsOfUseDialog isFetching={isFetchingAgree} handleCancel={this.logoutUser} open={termsOfUseOpen} handleAgree={this.handleAgree} />

      </div>
    )
  }
}


const mapStateToProps = ({ auth, recent_sales, recent_purchases, recent_transfers, platforms, lang, myNotices, me, recentDollarPrices }) => {
  return {
    me,
    auth,
    myNotices,
    recent_sales,
    recent_purchases,
    recent_transfers,
    platforms,
    lang,
    recentDollarPrices
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRecentSales: (filter) => dispatch(getRecentSalesFetchData(filter)),
    getRecentTransfers: (filter) => dispatch(getRecentTransfersFetchData(filter)),
    getRecentPurchases: (filter) => dispatch(getRecentPurchasesFetchData(filter)),
    getPlatforms: () => dispatch(getPlatformsFetchData({})),
    getPlatformRules: () => dispatch(getPlatformRulesFetchData({})),
    agreeTerms: (id) => dispatch(agreeToTerms(id)),
    authenticateUser: (user) => dispatch(signInUser(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)