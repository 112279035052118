import React, { Component } from 'react'
import 'react-phone-input-2/lib/style.css'
import TermsOfUseText from './TermsOfUseText';
import {Button, CircularProgress, Dialog, Typography} from '@material-ui/core'
import {connect} from 'react-redux'
import translations from '../../translations/translations';


class TermsOfUseDialog extends Component {

  render() {
    const {lang, open, handleAgree, handleCancel, isFetching} = this.props;

    return (
      <Dialog PaperProps={{style:{padding: '16px'}}} disableBackdropClick open={open}>
        <Typography variant='h5'>Leia os Termos de uso</Typography>
        <Typography variant='caption'>Você precisa concordar com os termos de uso para utilizar a Sensei Wallet</Typography>
        <div style={{height: 180, margin: '16px 0', background:'#ececec', overflow:'auto', padding: '8px 32px'}}>
          <TermsOfUseText lang={lang} />
        </div>
        <Button 
            variant="contained"
            fullWidth
            color="primary"
            type="submit"
            margin="normal"
            style={{ marginTop: 16 }}
            onClick={handleAgree}
          >
            <b>{isFetching && <CircularProgress size={12} style={{marginRight:8}} color='inherit'/>}{translations[lang].agree}</b>
        </Button>
        <Button 
            variant="contained"
            fullWidth
            type="submit"
            margin="normal"
            style={{ marginTop: 16, color: '#444', backgroundColor:'white', border:'1px solid #444'}}
            onClick={handleCancel}
          >
            <b>{translations[lang].cancel}</b>
        </Button>
      </Dialog>
    )
  }
}

const mapStateToProps = ({lang}) => {
  return{
    lang
  }
}

export default connect(mapStateToProps)(TermsOfUseDialog)
