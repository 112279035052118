import { AppBar, Container, Typography } from '@material-ui/core'
import React from 'react'
import {connect} from 'react-redux'
import TermsOfUseText from '../auth/TermsOfUseText'
import { changeLang } from '../auth/actions'
import SelectLang from '../common/SelectLang'

const TermsOfUse = ({lang}) => {
  return(
    <div>
      <AppBar position="static" style={{ background: '#101010', textAlign: 'right'}}>
        <Typography variant="caption">
          <SelectLang complete lang={lang} changeLang={changeLang}/>
        </Typography>
      </AppBar>
      <Container style={{marginTop:32}}>
        <TermsOfUseText lang={lang}/>
      </Container>
    </div>
  )
}

const mapStateToProps = ({lang}) => {
  return {
    lang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLang: (lang) => dispatch(changeLang(lang))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUse)