import * as types from './constants/ActionTypes'
import { routes } from './core/api'
import Auth2 from '../../utils/auth';
import { Auth as AuthCognito } from 'aws-amplify';



export const createUser = (user) => {
  return {
    type: types.AUTH_CREATE_USER,
    user,
  }
}

export const changeLang = (lang) => {
  return {
    type: 'CHANGE_LANG',
    lang
  }
}

export const resetRecentPurchases = () => {
  return {
      type: 'GET_RECENT_PURCHASES',
      recent_purchases: []
  }
}

export const resetRecentSales = () => {
  return {
      type: 'GET_RECENT_SALES',
      recent_sales: []
  }
}

export const resetRecentTransfers = () => {
  return {
      type: 'GET_RECENT_TRANSFERS',
      recent_transfers: []
  }
}

export const signInUser = (user) => {
  return {
    type: types.AUTH_SIGNIN_USER,
    user: {
      user_name: user.attributes.name,
      user_email: user.attributes.email,
      user_phone: user.attributes.phone_number,
      id: user.username,
      email_verified: user.attributes.email_verified || false,
      extra_accounts: user.attributes['custom:extra_accounts'] || false,
      agreed_terms_wallet: user.attributes['custom:agreed_terms_wallet'] || false,
      mfa_active: user.preferredMFA || false
    }
  }
}

export const setSignUpInfo = (signUpInfo) => {
  return {
      type: 'SET_SIGNUP_INFO',
      signUpInfo,
  }
}

export function setSignUpInfoData(info) {
  return dispatch => {
    dispatch(setSignUpInfo(info))
  }
}

// -- SIGNIN COM COGNITO

export function confirmSignInFetchData(user, code) {
  return dispatch => {
    return AuthCognito.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA')
      .then(confirmedUser => {
        return AuthCognito.currentAuthenticatedUser().then(loggedUser => {
          dispatch(signInUser(loggedUser))
          return new Promise(resolve => {
            resolve(loggedUser);
          })
        })
      })
      .catch(err => {
        return new Promise(function (resolve, reject) {
          reject(err)
        })
      })
  }
}

export function signInFetchData(username, password) {
  return dispatch => {
    return AuthCognito.signIn(username.trim(), password)
      .then(user => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED" || user.challengeName === "SOFTWARE_TOKEN_MFA") {
          return new Promise(resolve => {
            resolve(user);
          })
        }
        dispatch(signInUser(user))
        return new Promise(resolve => {
          resolve(user);
        })
      })
      .catch(err => {
        return new Promise(function (resolve, reject) {
          reject(err)
        })
      })
  }
}


export const authLogoutUser = () => {
  return {
    type: types.AUTH_LOGOUT_USER
  }
}

export function logout() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.signOut()
        .then(res => {
          dispatch(authLogoutUser())
          dispatch(resetRecentPurchases())
          dispatch(resetRecentSales())
          dispatch(resetRecentTransfers())
          Auth2.eraseRole()
          resolve('você foi deslogado')
        })
        .catch(err => {
          reject(err.message);
        })
    })
  }
}

// -- SIGNUP COM COGNITO
export function signUp(user) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const attributes = {
        name: user.name,
        email: user.email,
        phone_number: `+${user.phoneNumber}`,
        "custom:agreed_terms_wallet": "agreed"
      }

      if (user.affiliator) {
        attributes['custom:affiliator'] = user.affiliator
      }

      return AuthCognito.signUp({
        username: user.email,
        password: user.password,
        attributes
      })
        .then(body => {
          Auth2.storeInfoForValidation(body.userSub, body.user.username);
          resolve(body)
        })
        .catch(err => {
          reject(err);
        });
    })
  }
}

//create new user in DB when created in cognito
export function createNewUser(user) {
  return dispatch => {
    AuthCognito.currentSession()
      .then(session => {
        return fetch(routes.signUp, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
          body: JSON.stringify(user)
        })
          .then(res => {
            if (res.ok) return res.json()
            return new Promise(function (_, reject) {
              reject(res)
            })
          })
          .then(body => {
            dispatch(signInUser(body))
            return new Promise(function (resolve) {
              resolve(body)
            })
          })
      })
  }
}

export function agreeToTerms(userId) {
  return dispatch => {
    return new Promise(function (resolve, reject) {

      AuthCognito.currentSession()
        .then(session => {
          return fetch(routes.agreeTerms, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            },
            body: JSON.stringify(
              {
                userId,
                attributes: [
                  {
                    "Name": "custom:agreed_terms_wallet",
                    "Value": "agreed"
                  }
                ]
              }
            )
          })
          .then(body => {
            resolve(body)
          })
          .catch(err=> {
            console.log({err})
            reject(err)
          })
        })
    })
  }
}

export function completeNewPasswordChallenge(user, newPassword, RequiredNameAttribute) {
  //fixing phone_number format
  RequiredNameAttribute.phone_number = '+' + RequiredNameAttribute.phone_number
  return AuthCognito.completeNewPassword(user, newPassword, RequiredNameAttribute)
    .then((res) => {
      return new Promise((resolve) => {
        resolve(res);
      })
    })
    .catch(err => {
      return new Promise((resolve, reject) => {
        reject(err.message);
      })
    })
}

export function validate(validationCode) {
  return dispatch => {
    return AuthCognito.confirmSignUp(Auth2.getValidationEmail(), validationCode)
      .then(res => {
        return new Promise(resolve => resolve({}))
      })
      .catch(err => {
        return new Promise((resolve, reject) => {
          reject(err.message);
        })
      })
  }

}
