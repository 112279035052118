import { Typography } from '@material-ui/core';
import React, { Component } from 'react'
import 'react-phone-input-2/lib/style.css'


class TermsOfUseText extends Component {

  render() {
    const {lang} = this.props;

    const text = {
      'br': (
        <div>
          <Typography variant='h6'>Termos de Uso</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>Este Termos e Condições de Uso (“Termos de Uso”) descreve os termos e as condições aplicáveis ao acesso e uso da plataforma Sensei Poker, disponível no site: https://sensei.poker/, inscrita no CNPJ sob nº  23.217.779/0001-05, com sede e domicílio na QND 07 Casa 39 Frente Parte 1, Taguatinga, Brasília – DF, CEP nº 72.120-070</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>Por favor, leia atentamente os termos e condições de uso abaixo estipulados (“Termos de Uso”) para que você possa usufruir de nosso Site e de todos os serviços oferecidos pela Sensei Poker. Caso você não concorde com quaisquer dispositivos dos Termos de Uso, não acesse ou utilize o nosso Site ou nossos Serviços.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>SEU ACESSO E UTILIZAÇÃO REPRESENTA SUA ACEITAÇÃO INTEGRAL E INCONDICIONAL AOS TERMOS DE USO.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>A Política de Privacidade integra os presentes Termos de Uso. Para fins destes Termos de Uso, “Usuários” ou “Vocês” são todas as pessoas que de qualquer forma interagem, acessam ou utilizam o Site ou os Serviços da Sensei Poker.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>1. SOBRE O SENSEI POKER</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.1.  Esse Site pode apresentar informações, textos, dados, imagens, fotografias, vídeos, sons, ilustrações, nome, logos, marcas e informações diversas sobre a Sensei Poker, suas práticas e informações gerais da atividade de Wallet, por intermédio da facilitação de pagamentos, sendo apenas uma intermediadora e agenciadora dos serviços de cashback e wallet da plataforma, que poderão ser fornecidas na forma de documentos (“Atividades”).</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.2. Salvo disposição expressa em contrário, as atividades não se destinam a, nem constituem, serviços ou recomendações bancárias, emissão de moeda, investimentos financeiros, direcionamentos legais, contábeis, comerciais, financeiros, tributários ou afins. As Atividades elencadas são apenas com o intuito informativo e não deverão ser consideradas de forma genérica ou utilizadas com interpretação extensiva que fuja do real escopo de atuação da Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.3. Se você precisar de recomendações ou serviços sobre um assunto específico, entre em contato conosco diretamente, usando os nossos contatos indicados no Site. Embora a Sensei Poker procure garantir que as Informações contidas neste Site sejam precisas e atualizadas, não nos responsabilizaremos pelos resultados de qualquer ação tomada com base nas Informações disponibilizadas. Ademais, a Sensei Poker não garante que o Site ou qualquer das Informações sejam disponibilizadas de forma ininterrupta ou que quaisquer defeitos serão corrigidos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.4. SENSEI POKER ENVIDARÁ ESFORÇOS RAZOÁVEIS PARA INCLUIR INFORMAÇÕES PRECISAS E ATUALIZADAS NO SITE. NO ENTANTO, NÃO DÁ QUALQUER GARANTIA OU ASSUME QUALQUER RESPONSABILIDADE QUANTO À CONFIABILIDADE, EXATIDÃO, VALIDADE, ATUALIDADE, UTILIDADE, INTEGRIDADE, PERTINÊNCIA, OPORTUNIDADE OU ABRANGÊNCIA DE TAIS INFORMAÇÕES.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.5. Em nenhuma hipótese Sensei Poker será responsável por perdas indiretas ou quaisquer danos decorrentes da perda de uso, dados ou lucros, seja em contrato, ato ou de quaisquer outras formas, decorrentes de ou em conexão com o uso deste Site ou qualquer das Informações.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.6. SENSEI POKER AFASTA TODAS AS GARANTIAS E/OU RESPONSABILIDADES, EXPRESSAS OU IMPLÍCITAS, DECORRENTES OU RELATIVAS À UTILIZAÇÃO DESTE SITE E DE SEU CONTEÚDO, INCLUINDO GARANTIAS DE POSSÍVEL ADEQUAÇÃO A UMA FINALIDADE ESPECÍFICA.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.7. Para acessar a Plataforma Sensei Poker o Usuário precisará fazer cadastro no site, fornecendo as informações necessárias para sua identificação, conforme descrito no tópico 3 deste termo.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.8. Ao realizar o cadastro, o Usuário integrará o Sensei Poker Club, clube de benefícios administrado pela Sensei Poker, por meio do qual o Usuário poderá ter acesso aos links de cadastro das Salas de Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.9. As Salas de Poker, ambiente virtual disponível para apostas nos jogos de poker, são administradas por empresas parceiras, de forma que nós não temos controle sobre o que acontece nas salas virtuais. A Sensei Poker apenas intermedia a relação entre as Salas de Poker e os Usuários, possibilitando o uso de Sensei Coins para aposta.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.10. Para acessar a Plataforma Sensei Wallet, o Sensei Poker poderá conceder ao Usuário, em caráter acessório, uma licença limitada, pessoal, não exclusiva, não transferível e plenamente revogável, para usar a Plataforma Sensei Wallet em plataforma online, desde que em conformidade com os termos aqui contidos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.11. O Usuário declara-se integralmente responsável pelas informações inseridas na Plataforma, pelo cadastramento, permissões, senhas e modo de utilização. O Sensei Poker, em hipótese alguma, será responsável pelo conteúdo (informações, senhas, cópias de informações etc.) oferecido pelo Usuário, não sendo, portanto, estas informações revisadas nem divulgadas em momento algum. A responsabilidade pelas informações é sempre do Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.12. O Sensei Poker reserva-se o direito de atualizar a Plataforma sempre que desejar, bem como deixar de comercializá-la, atualizá-la, ou mesmo descontinuá-la a qualquer tempo.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.13. Em razão das Salas de Poker não estarem sob o comando da Sensei Poker, não nos responsabilizamos por eventuais bugs no sistema das salas, bem como por salas fora do ar ou problemas de conexão com o servidor. Tudo que acontece no ambiente virtual das Salas de Poker é de responsabilidade única e exclusiva de seus administradores.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.14. Os valores gerados através de transferências, recebimentos e eventuais Cashbacks ficarão disponíveis aos usuários na plataforma do Sensei Poker ou na Sensei Wallet, carteira digital da Sensei Poker. O uso e administração dos valores é de inteira responsabilidade do Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.15. A aprovação de transações de fichas está condicionada ao prévio recebimento dos valores que serão convertidos em Sensei Coins na plataforma do Sensei Poker ou na Sensei Wallet. Não nos responsabilizamos por transferências feitas pelo Usuário, mas não computadas em nosso sistema.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.16. As Sensei Coins poderão ser sacadas em Dólar ou Real, ou transferidas para outras e-wallets. Para realização da operação poderão ser cobradas taxas pela Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.17. Em caso de descontinuidade da Plataforma, o Sensei Poker garantirá o funcionamento por até 1 (uma) semana após a data do anúncio de descontinuidade.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>2. DEFINIÇÕES</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>2.1. Para facilitar seu entendimento e evitar repetições, os seguintes termos usados nestes Termos de Uso com a primeira letra em maiúsculo terão o significado estabelecido abaixo, seja no plural ou no singular, sem prejuízo de outros termos definidos ao longo destes Termos de Uso:</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>i.  “Sensei Poker Club”: clube administrado pela Sensei Poker, através de plataforma virtual, por meio do qual o Usuário pode receber benefícios/cashback ao jogar nas salas de poker parceiras.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>ii. “Sala de Poker”: plataforma virtual administrada por empresas parceiras, para onde o Usuário pode enviar seu dinheiro (sensei coins) e comprar entrada em jogos de poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>iii.  “Sensei Wallet”: carteira virtual de armazenamento de Sensei Coins, também conhecida como “wallet”, de titularidade do Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>iv.  “Sensei Coin”: uma moeda virtual, de valor, até a presente data, equivalente a U$ 1,00, utilizada para representar o saldo do Usuário nas plataformas Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>v.  “Cashback”: porcentagem que a Sala de Poker retorna e o Sensei Poker Club transforma em Sensei Coins.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>vi.  “Serviços”: todos os serviços oferecidos pelo grupo Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>vii.   “Usuário”: a pessoa física ou jurídica, por intermédio de representante legal devidamente habilitado, que acessa a Plataforma Sensei Poker e realiza o seu cadastro pessoal, de acordo com o previsto nestes Termos de Uso, de modo a usufruir os Serviços oferecidos pela Sensei Poker, aceitando, nessa oportunidade, incondicionalmente, os presentes Termos de Uso e Políticas de Privacidade.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>3. CADASTRO E VERIFICAÇÃO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.1. Para utilizarem os Serviços, os Usuários deverão realizar um cadastro prévio (“Cadastro”) em que o Usuário deverá informar um e-mail válido, criação de login e senha, bem como os dados previstos nos itens deste termo de uso.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.2. O Usuário responderá pela veracidade e atualização dos dados informados no Cadastro e será exclusivamente responsável pelo correto preenchimento de seu Cadastro, inclusive dos dados fornecidos para Requisições de Recebimento e Requisições de Envio, nos termos do item abaixo.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.3. O Cadastro a ser preenchido pelo Usuário pessoa física incluirá, entre outras informações, (i) nome completo; (ii) data de nascimento; (iii) o número do Cadastro Nacional de Pessoa Física (CPF), (iv) uma imagem de documento com foto; (v) número de telefone e código de Discagem Direta a Distância (DDD); (vi) e-mail; (vii) foto na modalidade “selfie” contendo o Usuário e o documento legível.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.4. Ao preencher o formulário de Cadastro, o Usuário ingressa no banco de dados do Sensei Poker, autorizando o Sensei Poker o acesso a referidos dados disponibilizados.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.5. Sendo constatada irregularidade no preenchimento do Cadastro, omissão de informações, dados equivocados ou preenchimentos errôneos, o Sensei Poker poderá suspender o acesso do Usuário até que a situação se regularize, não se responsabilizando pelo salvamento automático de cadastro anterior.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.6. O Cadastro na Plataforma é gratuito, porém podem ser cobradas taxas pelos Serviços.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.7. A proteção e privacidade dos dados pessoais, de acesso e de transações dos Usuários são obrigação do Sensei Poker como parte de sua Política de Privacidade.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.8. Para a emissão de Requisições de Recebimento ou Requisições de Envio, é necessário que o Cadastro esteja validado pelo Sensei Poker, sendo que a validação do Cadastro se dará mediante a análise de todos os documentos e informações fornecidas pelo Usuário para fins do Cadastro. Para esta validação e consequente cumprimento dos procedimentos mínimos de segurança internos, o Sensei Poker realizará um procedimento de verificação dos dados informados e poderá solicitar ao Usuário o envio de cópia de documentos e/ou documentos adicionais.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.9. O Usuário garante que todas as informações prestadas são válidas e precisas e que a senha determinada é pessoal e intransferível. A atualização dos dados informados também é de responsabilidade do Usuário e este está ciente de que a precisão e veracidade destes dados são imprescindíveis à efetivação dos Serviços.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.10. O Sensei Poker se reserva o direito de recusar qualquer solicitação de cadastro e de suspender ou cancelar um cadastro previamente aceito nos casos (i) de violação de qualquer das disposições destes Termos de Uso; (ii) impossibilidade de verificação da identidade do Usuário ou constatação de falsidade em qualquer das informações por ele fornecidas; (iii) prática pelo Usuário de atos fraudulentos ou dolosos ou a adoção de qualquer comportamento que, a critério do Sensei Poker, seja incompatível com os objetivos da Plataforma, com a urbanidade com outros Usuários ou que possam, de qualquer modo, causar danos a terceiros ou à Plataforma ou ainda, por falta de interesse comercial. Caso o cadastro de qualquer Usuário seja suspenso ou cancelado por quaisquer das razões previstas nestes Termos de Uso, todas as suas Requisições de Recebimento ou Envio ainda não executadas serão automaticamente canceladas.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.11. O Usuário aceita e concorda que toda e qualquer transação deve ser efetuada tão somente pelo titular do Cadastro.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.12. O “nome de usuário” (log-in) não poderá guardar semelhança com o nome “Sensei Poker”. Também poderão ser cancelados Cadastros efetuados com nomes de Usuário (log-in) considerados ofensivos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.13. O Usuário está ciente de que é proibido manter mais de um Cadastro na Plataforma e que, em caso de descumprimento, o Sensei Poker poderá cancelar ou suspender todos os cadastros e Requisições de Envio e Recebimento vinculados.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3,14. O Usuário é o único e exclusivo responsável por manter em segurança e sigilo seu login e senha, evitando assim o uso não autorizado de seu Cadastro. Para proteger sua senha, o Usuário deverá (i) memorizá-la, (ii) não usar uma senha que possa ser facilmente associada a você, tal como seu nome, data de nascimento ou número de telefone; (iii) tomar cuidado para impedir que outra pessoa veja a sua senha quando você a estiver utilizando; e (iv) não manter um registro de sua senha juntamente com o registro de seu login.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.15. O Usuário deverá avisar imediatamente o Sensei Poker, através dos canais de atendimento (instagram, telegram ou email), em caso de suspeita de acesso indevido a sua Carteira Virtual, ou qualquer atividade que possa dar ensejo a tal, para que o Sensei Poker possa tomar as devidas providências.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.16. Para a segurança dos usuários. AO ACESSAR A PLATAFORMA E ANTES DE REALIZAR O LOGIN, VERIFIQUE SE O ENDEREÇO ESTÁ CORRETO (wallet.sensei.poker). O SENSEI POKER NÃO SOLICITA SENHA, DADOS DE CARTÃO DE CRÉDITO OU OUTROS DADOS BANCÁRIOS DE SEUS CLIENTES POR E-MAIL, TELEFONE OU QUALQUER OUTRO CANAL DE ATENDIMENTO PERSONALIZADO. Portanto, se você verificar a existência de website muito idêntico ao da Sensei Wallet, com endereço diferente (como por exemplo, mas sem se limitar a www.senseiwallet.com.br e www.senseiwallet.poker), não realize login e relate a existência deste site por meio dos canais de atendimento/comunicação do Sensei Poker. Se você receber qualquer comunicação com esse tipo de abordagem e conteúdo, não responda, desconsidere a comunicação e, se possível, encaminhe o seu relato através dos canais de atendimento/comunicação do Sensei Poker.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>4. WEBSITES DE TERCEIROS</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>4.1. A Sensei Poker poderá fornecer links para outros websites da internet ou outros recursos. Assim, estes links serão disponibilizados como uma conveniência ao Usuário e, como a Sensei Poker não tem controle sobre tais websites ou recursos externos, o Usuário reconhece e concorda que a Sensei Poker não é responsável pela disponibilidade desses e não endossa ou se responsabiliza por qualquer conteúdo, propaganda, produtos, serviços ou outros materiais contidos ou disponibilizados por meio de tais websites ou recursos. O Usuário expressamente reconhece e concorda que a Sensei Poker não será responsável, direta ou indiretamente, por quaisquer perdas e danos que sejam efetiva ou alegadamente causados por, ou em conexão, pela confiança depositada em tais Informações, bens e serviços disponíveis no ou através de tais websites de terceiros.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>5. PROPRIEDADE INTELECTUAL</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.1. Salvo se diversamente informado, a Sensei Poker é a detentora dos direitos autorais de toda a informação disponibilizada no Site, bem como de todos os direitos de propriedade intelectual e direitos conexos, incluindo, mas não se limitando a direitos sobre o banco de dados, marcas, pedidos de registro, etc.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.2. Exceto se Você tiver a permissão expressa e por escrito da Sensei Poker, Você não deverá utilizar o nome, marca ou logotipo pertencente a Sensei Poker. Você tem permissão para fazer o download, imprimir, armazenar temporariamente, recuperar e exibir as Informações do Site na tela do computador ou dispositivo móvel, imprimir páginas (mas não copiar) e armazenar essas páginas em formato eletrônico no computador ou dispositivo móvel para uso pessoal ou para uso interno em sua organização.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.3. Você não tem permissão (exceto quando receber permissão expressa) para adaptar ou modificar as Informações neste Site ou qualquer parte dele e as Informações ou qualquer parte delas não podem ser copiadas, reproduzidas, republicadas, baixadas, postadas ou transmitidas de qualquer outra forma a terceiros para ganhos comerciais. Nenhuma disposição será interpretada como concessão aos Usuários de qualquer direito, título ou interesse em qualquer propriedade intelectual da Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.4. A Plataforma, o conteúdo e quaisquer outros textos, gráficos, imagens, fotos, ilustrações, marcas comerciais, nomes comerciais, marcas de serviço, logotipos, informações, código-fonte, layouts, nomes de domínio, software, know-how, e outros materiais são todos protegidos por direito de propriedade intelectual (“Direito de Propriedade Intelectual”). Todos os Direitos de Propriedade Intelectual presentes na Plataforma são de titularidade do Sensei Poker, licenciados ao Sensei Poker ou de nossos parceiros (conforme aplicável).
</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.5. A exploração comercial, transmissão, divulgação, modificação, reprodução, cópia ou quaisquer outras formas de utilização comercial de tal conteúdo pelo usuário são rigorosamente proibidas.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.6. Em caso de violação pelo Usuário de qualquer Direito de Propriedade Intelectual do Sensei Poker, o Usuário assume toda e qualquer responsabilidade de caráter civil e/ou criminal advindos de referida violação.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.7. Se você é um proprietário de direitos autorais ou representante de um detentor de tais direitos e acredita que um conteúdo publicado na Plataforma infringe seus direitos, por favor nos envie um aviso com as seguintes informações: a) assinatura eletrônica ou física da pessoa autorizada a agir em nome do proprietário dos direitos; (b) descrição da obra protegida por direitos autorais que alega ter sido indevidamente reproduzida; (c) o endereço virtual (URL) em que se encontra o material que alega apresentar infração ao seu direito; (d) seu endereço, número de telefone e e-mail para contato; (e) declaração de que você tem boas razões para acreditar que o uso contestado não é autorizado pelo proprietário dos direitos ou pela lei; e (f) declaração feita por você de que as informações em sua notificação são precisas e de que você é o proprietário dos direitos ou autorizado a atuar em nome do proprietário dos direitos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.8. Se você é um proprietário de direitos autorais ou representante de um detentor de tais direitos e acredita que um conteúdo publicado na Plataforma infringe seus direitos, por favor nos envie um aviso com as seguintes informações: a) assinatura eletrônica ou física da pessoa autorizada a agir em nome do proprietário dos direitos; (b) descrição da obra protegida por direitos autorais que alega ter sido indevidamente reproduzida; (c) o endereço virtual (URL) em que se encontra o material que alega apresentar infração ao seu direito; (d) seu endereço, número de telefone e e-mail para contato; (e) declaração de que você tem boas razões para acreditar que o uso contestado não é autorizado pelo proprietário dos direitos ou pela lei; e (f) declaração feita por você de que as informações em sua notificação são precisas e de que você é o proprietário dos direitos ou autorizado a atuar em nome do proprietário dos direitos.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>6. LIMITAÇÃO DE RESPONSABILIDADE</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>6.1. A SENSEI POKER NÃO FORNECE QUALQUER GARANTIA COM RELAÇÃO AO CONTEÚDO E SERVIÇOS DISPONIBILIZADOS NO SITE. SEM QUALQUER LIMITAÇÃO, TODO O CONTEÚDO E FUNCIONALIDADES DO SITE/ PLATAFORMA SÃO DISPONIBILIZADOS SEM GARANTIAS DE QUALQUER ESPÉCIE, SEJAM EXPRESSAS OU IMPLÍCITAS, INCLUINDO, ENTRE OUTRAS, GARANTIAS DE ADEQUAÇÃO PARA UM OBJETIVO EM PARTICULAR. A SENSEI POKER NÃO É NEM SERÁ RESPONSÁVEL (A) PELA QUALIDADE DA CONEXÃO DO COMPUTADOR E/OU DISPOSITIVO MÓVEL DO USUÁRIO À INTERNET EM QUALQUER CIRCUNST NCIA, (B) PELA DISPONIBILIDADE OU NÃO DOS SERVIÇOS DE ACESSO À INTERNET PRESTADOS POR TERCEIROS, (C) PELA IMPOSSIBILIDADE DE UTILIZAÇÃO DE QUAISQUER INFORMAÇÕES OU SERVIÇOS ACIMA MENCIONADOS EM RAZÃO DA INCOMPATIBILIDADE DA CONFIGURAÇÃO TÉCNICA DO COMPUTADOR E/OU DISPOSITIVO MÓVEL DOS USUÁRIOS, (D) POR QUAISQUER DANOS SOFRIDOS PELOS USUÁRIOS EM RAZÃO DA UTILIZAÇÃO DO APLICATIVO, E (E) PELA REPARAÇÃO DE DANOS DE QUALQUER NATUREZA CAUSADOS PELA QUEBRA DE PRIVACIDADE OU SEGURANÇA DE DADOS DOS USUÁRIOS DURANTE A CONEXÃO DE SEU DISPOSITIVO MÓVEL À INTERNET ATRAVÉS DE TERCEIROS. (F) PELA AUSÊNCIA DE REPASSE DE VALORES DE OUTRAS PLATAFORMAS REFERENTES A CASHBACK OU MOEDAS VIRTUAIS.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>7. POLÍTICA DE PRIVACIDADE</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>O documento de política de privacidade do Sensei Poker pode ser acessado por <a href="https://drive.google.com/file/d/1lGueUhs8bBPBWhu8h_AfGGlfk57_MS9v/view?usp=sharing" target='_blank' rel='noreferrer'>este link</a></Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>8. ALTERAÇÃO AOS TERMOS DE USO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>8.1. A Sensei Poker se reserva ao direito de, a sua discrição, alterar qualquer parte do Site, das Informações ou dos presentes Termos de Uso. Caso estes Termos de Uso sejam alterados, a Sensei Poker publicará detalhes das alterações no Site. Ao continuar a usar o Site, você concorda em estar vinculado aos Termos de Uso conforme alterados.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>9. VIGÊNCIA</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>9.1. O prazo para utilização da Plataforma e de vigência dos presentes Termos de Uso será por tempo indeterminado.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>10. RESCISÃO, INTERRUPÇÃO, SUSPENSÃO OU CANCELAMENTO DO CADASTRO E/OU SERVIÇOS</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.1.  O Usuário poderá rescindir este instrumento a qualquer momento, por meio da solicitação de encerramento do Cadastro de sua titularidade através dos nossos canais de comunicação e atendimento (telegram, instagram e email).</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.2.  Após a suspensão e/ou cancelamento do Cadastro do Usuário, realizado por ele mesmo ou pelo Sensei Poker, o Usuário reconhece que poderá não mais ter acesso e/ou resgatar informações sobre transações realizadas através da Plataforma, não tendo o Sensei Poker qualquer dever de armazenamento de informações, tampouco de repassar essas informações ao Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.3.  Em caso de suspeita de fraude ou de qualquer outra atividade ilícita, o Sensei Poker poderá, além de recorrer às medidas legais cabíveis, reter eventuais fundos ou ativos armazenados na Carteira Virtual do Usuário ou de qualquer outra forma entregues ao Sensei Poker, até a conclusão das respectivas investigações.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4. O Sensei Poker reserva para si o direito de suspender e/ou cancelar o cadastro dos Usuários imediatamente e indefinidamente, a qualquer tempo e sem aviso prévio, em caso de indícios de, mas sem se limitar a:</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.1. Fornecimento de informações falsas, imprecisas, incompletas ou enganosas;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.2. Não fornecimento de informações e/ou documentos adicionais, seja ou não para a validação do Cadastro;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.3. Agir de má-fé;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.4. Violação de algum dos termos dos presentes Termos de Uso;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.5. Uso dos Serviços em desacordo com a lei ou qualquer regulamento relacionado;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.6. Envolvimento em condutas fraudulentas ou ilegais;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.7. Operações incompatíveis com a capacidade econômica do Usuário. Mesmo em caso de rescisão, todas as taxas pagas e cobranças feitas antes do encerramento não são reembolsáveis.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.5. Contas Inativas: O presente Contrato poderá ser rescindido pelo Sensei Poker uma vez evidenciada a inatividade da conta por mais de 180 (cento e oitenta) dias, de modo que o Usuário receberá um e-mail para que atualize sua conta e manifeste interesse na continuidade dos serviços., em um prazo não superior a 7 (sete) dias.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.5.1. A exclusivo critério do Sensei Poker, a conta que não houver movimentação por mais de 180 (cento e oitenta dias) e que haja saldo, poderá ser classificada como dormente, somente sendo reativada mediante a atualização de informações cadastrais completas.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.6. Direitos e Deveres após a Suspensão e/ou Cancelamento: A rescisão dos presentes Termos de Uso não afetará nenhum direito e obrigação que poderão ser acumulados antes da rescisão ou término destes Termos de Uso e/ou da prestação dos Serviços. As obrigações de quaisquer das partes contraídas pelos presentes Termos de Uso permanecerão após sua rescisão e continuarão em pleno vigor e efeito até que sejam sanadas, incluindo, mas sem se limitar a, Requisições de Recebimento, Requisições de Envio e Requisições de Transferência.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.6.1. Em caso de suspensão e/ou cancelamento de Cadastro, o Usuário autoriza desde já que: o Sensei Poker poderá fazer envio, em nome do Usuário, todo e qualquer fundo mantido na Carteira Virtual para plataforma externa (sala de poker ou e-wallet) informada pelo usuário. Até que tal transferência seja efetuada, os recursos mantidos na Carteira Virtual do Usuário ficarão à disposição deste mediante solicitação ao Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.6.2. As formas de interrupção, suspensão e desativação dos Serviços não isentam os Usuários, a qualquer tempo, de cumprimento destes Termos de Uso.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>11.  RESPONSABILIDADE DO SENSEI POKER</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>11.1. O Sensei Poker não será responsabilizado por casos fortuitos ou de força maior ou fatos decorrentes de culpa exclusiva de terceiros ou do Usuário, como (i) resultados produzidos pela Plataforma, caso esta seja afetada por algum tipo de programa externo, como aqueles conhecidos popularmente como vírus, ou por falha de operação ou corrupção de dados; (ii) qualquer defeito decorrente de culpa exclusiva do Usuário; (iii) integração da Plataforma contratada com qualquer outro software de terceiros ou do Usuário; (iv) danos ou prejuízos decorrentes de decisões administrativas, gerenciais ou comerciais tomadas pelo Usuário com base nas informações fornecidas pela Plataforma; (v) problemas na rede do Usuário; (vi) toda e qualquer responsabilidade por eventuais perdas e danos, lucros cessantes ou emergentes, cujas causas possam ser atribuídas, de forma direta ou indireta, ao fornecimento, utilização ou desempenho da Plataforma; (vii) eventos definidos na legislação civil como caso fortuito ou força maior; (viii) uso perigoso e/ou inadequado do Plataforma; e (ix) fatores de ordem técnica que impossibilitem a transferência de dados e/ou o download de informações da Plataforma contratada pelo Usuário e/ou de suas respectivas versões, se houver.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>11.2. O Sensei Poker não será responsável perante o Usuário por acatar e cumprir qualquer mandado, ordem judicial, sentença ou decisão, independentemente de tal mandado, ordem judicial, sentença ou decisão vir a ser subsequentemente objeto de reforma, modificação, anulação, revogação ou cancelamento.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>12. DISPOSIÇÕES ILEGAIS, INVÁLIDAS OU INEXEQUÍVEIS</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>12.1. Se estes Termos de Uso ou qualquer parte deles forem considerados ilegais, inválidos ou inexequíveis sob as leis de qualquer estado ou país em que estes Termos de Uso se destinem a ser efetivos, então, na medida em que as disposições sejam ilegais, inválidas ou inexequíveis, tais disposições devem ser tratadas como apartadas e excluídas destes Termos de Uso e as demais disposições destes Termos de Uso devem ser mantidas, permanecer em pleno vigor e efeito e continuar a ser vinculativa e exequível nesse estado ou país.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>13. INDENIZAÇÃO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>13.1. O Usuário concorda em isentar e indenizar, defender e manter o Sensei Poker e seus respectivos diretores, agentes, sócios e funcionários indenes de qualquer prejuízo, responsabilidade, ação judicial ou demanda, incluindo honorários advocatícios, devidos a ou decorrentes (i) do uso da Plataforma, (ii) de violação destes Termos de Uso ou da Política de Privacidade, (iii) de falhas ocorridas nos equipamentos do Usuário e/ou (iv) da utilização indevida das informações ou materiais da Plataforma.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>14. VEDAÇÕES</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>14.1. Violar qualquer legislação municipal, nacional ou internacional que seja integrada ao ordenamento jurídico brasileiro, ou ainda, que, por qualquer razão legal, deva ser no Brasil aplicada; praticar atos contrários à moral e aos bons costumes; </Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>14.2. Assumir a personalidade ou identidade de outra pessoa, física ou jurídica; carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer conteúdo que viole quaisquer direitos de terceiro, incluindo Direitos de Propriedade Intelectual (conforme definido abaixo); carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer tipo de anúncio, propaganda ou material promocional não solicitado ou não autorizado pela Plataforma, tais como mensagens não solicitadas ou mensagens enviadas em massa (conhecidos como junk mail ou spam);</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>14.3. Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer conteúdo que contenha vírus ou qualquer outro código, arquivo ou programa de computador com o propósito de interromper, destruir ou limitar a funcionalidade de qualquer software, hardware ou equipamento; ameaçar, coagir, constranger demais Usuários; violar direitos de sigilo e privacidade de outros Usuários ou de terceiros, sempre observada a Política de Privacidade; e praticar quaisquer atos que direta ou indiretamente, no todo ou em parte, possam causar prejuízo ao Sensei Poker, a outros Usuários ou a qualquer terceiro.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>15. FUNCIONAMENTO, REGRAS DE UTILIZAÇÃO DOS SERVIÇOS, LIMITES, RESTRIÇÕES E RESPONSABILIDADES DO USUÁRIO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.1. Funcionamento Geral: Através dos Serviços providos pela Plataforma Sensei Wallet, o Sensei Poker oferece uma plataforma para movimentação de dinheiro e fichas entre diferentes salas de poker e e-wallets, facilitando a gestão de bankroll (caixa) para os jogadores e times de poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.2. O Usuário poderá utilizar os Serviços por meio da Plataforma para (i) armazenar saldo em Sensei Coins, (ii) emitir Requisição de Recebimento, Envio ou transferência, (iii) receber em sua Carteira Virtual Sensei Coins provenientes de Requisições de Recebimento, (iv) enviar seu saldo de Sensei Coins para plataformas de Poker ou e-Wallets por meio de Requisições de Envio e (v) Transferir Sensei Coins para outro usuário da Sensei Wallet por meio de Requisições de Transferência.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.3. O Usuário reconhece e concorda expressamente que a inserção de seu login e senha nos campos indicados na Plataforma constituem uma legítima e suficiente forma para a comprovação da identidade do Usuário e da validade de sua declaração de vontade em contratar os Serviços e transmitir as Requisições de Recebimento, Requisições de Envio, Requisições de Transferência e quaisquer outras instruções no âmbito da Plataforma.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.4. Carteira Virtual: Após conclusão do Cadastro pelo Usuário, é criada automaticamente para o Usuário uma Carteira Virtual de armazenamento de Sensei Coins, de titularidade do Usuário, mantida em seu nome, que poderá ser acessada, consultada e transacionada através do uso de seu login e senha.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.5. Limites: O Sensei Poker poderá utilizar determinados parâmetros próprios para estabelecer determinados limites, tais como, mas não se limitando a, quantidade de transações de Sensei Coins e de valores transferidos e armazenados na Conta Arrecadadora.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.5.1. O Sensei Poker poderá, a seu exclusivo critério, aumentar e diminuir os limites inicialmente estipulados, solicitando as informações e documentações necessárias.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.6. Da Utilização dos Serviços: As transações realizadas entre os Usuários são de inteira responsabilidade dos Usuários.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.6.1. O Usuário se compromete a não utilizar os Serviços para fins diretos ou indiretos de (i) infringir qualquer lei, regulamento, estes Termos de Uso, nem praticar atos contrários à moral e aos bons costumes; (ii) praticar lavagem de dinheiro; e/ou (iii) financiar atividades e/ou organizações que envolvam terrorismo, crime organizado, tráfico de drogas, pessoas e/ou órgãos humanos. Neste caso, a conta será encerrada, sem ônus ou multa, sem a necessidade de especificar o motivo, inclusive podendo ser por desinteresse comercial, sem a necessidade de qualquer aviso prévio.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.6.2. O Usuário declara-se ciente que o Sensei Poker comunicará às salas de poker ou e-wallets as Transações que possam ser consideradas como crimes de lavagem ou ocultação de bens, direitos e valores. Ademais, o Sensei Poker poderá informar os dados cadastrais do Usuário às Autoridades Públicas, para atendimento de normas regulamentares e/ou requisições judiciais.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.6.3. Como condição para a utilização dos Serviços, o Usuário entende e reconhece que as transações de compra e venda de Sensei Coins são realizadas por sua conta e risco e que o Sensei Poker atua exclusivamente como mantenedor da Plataforma para que os Usuários transacionem entre si. Em nenhum momento o Sensei Poker poderá ser considerado responsável por quaisquer perdas, danos, prejuízos e/ou lucros cessantes que possam eventualmente ser sofridos pelos Usuários devido às transações de recebimento/envio/transferência realizadas ou não através da Plataforma.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>16. SUPORTE TÉCNICO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.1. A prestação do serviço de suporte técnico limitar-se-á a esclarecimentos acerca do funcionamento da Plataforma e sua operação, sendo certo que o Usuário, ao aceitar os termos previstos nestes Termos de Uso, declara possuir o conhecimento mínimo do uso do computador e/ou dispositivo móvel e suas funções, bem como do sistema operacional sob o qual o sistema será utilizado. O Usuário declara, ainda, possuir a configuração adequada em seu computador e/ou dispositivo móvel no que se refere à utilização da Plataforma e o bom estado de funcionamento do referido computador/dispositivo móvel.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.2. O suporte técnico ao Usuário se dará dos canais de atendimento do Sensei (Telegram, Instagram e email), por meio de troca de informações, cabendo ao Sensei Poker fornecer uma resposta em até 5 (cinco) dias úteis, contadas do chamado realizado pelo Usuário, não estando incluído neste serviço, o atendimento in loco.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.3. O suporte técnico estará disponível nos dias úteis, no horário de 10h às 19h.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.4. O suporte técnico fornecido pelo Sensei Poker não abrange a conexão com a internet, rede interna e computadores/dispositivos móveis do Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.5. O Sensei Poker procura assegurar que as informações fornecidas sejam precisas, completas, atuais e que o seu uso não tenha interrupções nem erros. No entanto, não podemos garantir a operação da Plataforma em tempo integral, na medida em que esta depende de serviços prestados por terceiros, como empresas de telecomunicações e provedores de acesso à internet. Além disso, suspenderemos o uso e acesso à Plataforma em caso fortuito ou força maior, por exemplo, em caso de ataques cibernéticos e outros que possam prejudicar as funcionalidades da Plataforma e colocar em risco as informações dos Usuários.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>17. CONSIDERAÇÕES FINAIS</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.1. A incapacidade ou a mera liberalidade em não fazer cumprir quaisquer disposições destes Termos de Uso não constitui renúncia de direitos, podendo ser exigido o seu cumprimento em qualquer momento.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.2 Caso qualquer disposição destes Termos de Uso for considerada inexequível ou inválida, essa disposição será isoladamente invalidada e não atingirá o restante das disposições destes Termos de Uso.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.3. Estes Termos de Uso e a relação entre as Partes serão regidos pelas leis da República Federativa do Brasil. As Partes elegem o Foro da Comarca de Curitiba como sendo o único competente para dirimir quaisquer litígios e/ou demandas que venham a envolver as Partes em relação aos Serviços.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.4.  A tolerância de uma Parte com a outra quanto ao descumprimento de qualquer uma das obrigações assumidas nestes Termos de Uso não implicará em novação ou renúncia de direito. A Parte tolerante poderá, a qualquer tempo, exigir da outra Parte o fiel e cabal cumprimento destes Termos de Uso.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.5. A inviabilidade da execução ou invalidade, ainda que em virtude de decisão judicial, de alguma cláusula, não afetará a validade das demais cláusulas destes Termos de Uso, que permanecerão válidos e exequíveis conforme seus termos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.6. O Sensei Poker se reserva o direito de modificar o presente Termos de Uso a qualquer tempo, sendo o Usuário comunicado de tais alterações, mediante o aceite das novas disposições.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>18. LEI APLICÁVEL</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>18.1.  Esses Termos de Uso estão sujeitos a lei brasileira e jurisdição dos tribunais brasileiros.</Typography>
        </div>
      ),
      'ar': (
        <div>
          <Typography variant='h6'>Termos de Uso</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>Este Termos e Condições de Uso (“Termos de Uso”) descreve os termos e as condições aplicáveis ao acesso e uso da plataforma Sensei Poker, disponível no site: https://sensei.poker/, inscrita no CNPJ sob nº  23.217.779/0001-05, com sede e domicílio na QND 07 Casa 39 Frente Parte 1, Taguatinga, Brasília – DF, CEP nº 72.120-070</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>Por favor, leia atentamente os termos e condições de uso abaixo estipulados (“Termos de Uso”) para que você possa usufruir de nosso Site e de todos os serviços oferecidos pela Sensei Poker. Caso você não concorde com quaisquer dispositivos dos Termos de Uso, não acesse ou utilize o nosso Site ou nossos Serviços.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>SEU ACESSO E UTILIZAÇÃO REPRESENTA SUA ACEITAÇÃO INTEGRAL E INCONDICIONAL AOS TERMOS DE USO.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>A Política de Privacidade integra os presentes Termos de Uso. Para fins destes Termos de Uso, “Usuários” ou “Vocês” são todas as pessoas que de qualquer forma interagem, acessam ou utilizam o Site ou os Serviços da Sensei Poker.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>1. SOBRE O SENSEI POKER</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.1.  Esse Site pode apresentar informações, textos, dados, imagens, fotografias, vídeos, sons, ilustrações, nome, logos, marcas e informações diversas sobre a Sensei Poker, suas práticas e informações gerais da atividade de Wallet, por intermédio da facilitação de pagamentos, sendo apenas uma intermediadora e agenciadora dos serviços de cashback e wallet da plataforma, que poderão ser fornecidas na forma de documentos (“Atividades”).</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.2. Salvo disposição expressa em contrário, as atividades não se destinam a, nem constituem, serviços ou recomendações bancárias, emissão de moeda, investimentos financeiros, direcionamentos legais, contábeis, comerciais, financeiros, tributários ou afins. As Atividades elencadas são apenas com o intuito informativo e não deverão ser consideradas de forma genérica ou utilizadas com interpretação extensiva que fuja do real escopo de atuação da Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.3. Se você precisar de recomendações ou serviços sobre um assunto específico, entre em contato conosco diretamente, usando os nossos contatos indicados no Site. Embora a Sensei Poker procure garantir que as Informações contidas neste Site sejam precisas e atualizadas, não nos responsabilizaremos pelos resultados de qualquer ação tomada com base nas Informações disponibilizadas. Ademais, a Sensei Poker não garante que o Site ou qualquer das Informações sejam disponibilizadas de forma ininterrupta ou que quaisquer defeitos serão corrigidos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.4. SENSEI POKER ENVIDARÁ ESFORÇOS RAZOÁVEIS PARA INCLUIR INFORMAÇÕES PRECISAS E ATUALIZADAS NO SITE. NO ENTANTO, NÃO DÁ QUALQUER GARANTIA OU ASSUME QUALQUER RESPONSABILIDADE QUANTO À CONFIABILIDADE, EXATIDÃO, VALIDADE, ATUALIDADE, UTILIDADE, INTEGRIDADE, PERTINÊNCIA, OPORTUNIDADE OU ABRANGÊNCIA DE TAIS INFORMAÇÕES.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.5. Em nenhuma hipótese Sensei Poker será responsável por perdas indiretas ou quaisquer danos decorrentes da perda de uso, dados ou lucros, seja em contrato, ato ou de quaisquer outras formas, decorrentes de ou em conexão com o uso deste Site ou qualquer das Informações.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.6. SENSEI POKER AFASTA TODAS AS GARANTIAS E/OU RESPONSABILIDADES, EXPRESSAS OU IMPLÍCITAS, DECORRENTES OU RELATIVAS À UTILIZAÇÃO DESTE SITE E DE SEU CONTEÚDO, INCLUINDO GARANTIAS DE POSSÍVEL ADEQUAÇÃO A UMA FINALIDADE ESPECÍFICA.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.7. Para acessar a Plataforma Sensei Poker o Usuário precisará fazer cadastro no site, fornecendo as informações necessárias para sua identificação, conforme descrito no tópico 3 deste termo.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.8. Ao realizar o cadastro, o Usuário integrará o Sensei Poker Club, clube de benefícios administrado pela Sensei Poker, por meio do qual o Usuário poderá ter acesso aos links de cadastro das Salas de Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.9. As Salas de Poker, ambiente virtual disponível para apostas nos jogos de poker, são administradas por empresas parceiras, de forma que nós não temos controle sobre o que acontece nas salas virtuais. A Sensei Poker apenas intermedia a relação entre as Salas de Poker e os Usuários, possibilitando o uso de Sensei Coins para aposta.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.10. Para acessar a Plataforma Sensei Wallet, o Sensei Poker poderá conceder ao Usuário, em caráter acessório, uma licença limitada, pessoal, não exclusiva, não transferível e plenamente revogável, para usar a Plataforma Sensei Wallet em plataforma online, desde que em conformidade com os termos aqui contidos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.11. O Usuário declara-se integralmente responsável pelas informações inseridas na Plataforma, pelo cadastramento, permissões, senhas e modo de utilização. O Sensei Poker, em hipótese alguma, será responsável pelo conteúdo (informações, senhas, cópias de informações etc.) oferecido pelo Usuário, não sendo, portanto, estas informações revisadas nem divulgadas em momento algum. A responsabilidade pelas informações é sempre do Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.12. O Sensei Poker reserva-se o direito de atualizar a Plataforma sempre que desejar, bem como deixar de comercializá-la, atualizá-la, ou mesmo descontinuá-la a qualquer tempo.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.13. Em razão das Salas de Poker não estarem sob o comando da Sensei Poker, não nos responsabilizamos por eventuais bugs no sistema das salas, bem como por salas fora do ar ou problemas de conexão com o servidor. Tudo que acontece no ambiente virtual das Salas de Poker é de responsabilidade única e exclusiva de seus administradores.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.14. Os valores gerados através de transferências, recebimentos e eventuais Cashbacks ficarão disponíveis aos usuários na plataforma do Sensei Poker ou na Sensei Wallet, carteira digital da Sensei Poker. O uso e administração dos valores é de inteira responsabilidade do Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.15. A aprovação de transações de fichas está condicionada ao prévio recebimento dos valores que serão convertidos em Sensei Coins na plataforma do Sensei Poker ou na Sensei Wallet. Não nos responsabilizamos por transferências feitas pelo Usuário, mas não computadas em nosso sistema.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.16. As Sensei Coins poderão ser sacadas em Dólar ou Real, ou transferidas para outras e-wallets. Para realização da operação poderão ser cobradas taxas pela Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.17. Em caso de descontinuidade da Plataforma, o Sensei Poker garantirá o funcionamento por até 1 (uma) semana após a data do anúncio de descontinuidade.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>2. DEFINIÇÕES</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>2.1. Para facilitar seu entendimento e evitar repetições, os seguintes termos usados nestes Termos de Uso com a primeira letra em maiúsculo terão o significado estabelecido abaixo, seja no plural ou no singular, sem prejuízo de outros termos definidos ao longo destes Termos de Uso:</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>i.  “Sensei Poker Club”: clube administrado pela Sensei Poker, através de plataforma virtual, por meio do qual o Usuário pode receber benefícios/cashback ao jogar nas salas de poker parceiras.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>ii. “Sala de Poker”: plataforma virtual administrada por empresas parceiras, para onde o Usuário pode enviar seu dinheiro (sensei coins) e comprar entrada em jogos de poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>iii.  “Sensei Wallet”: carteira virtual de armazenamento de Sensei Coins, também conhecida como “wallet”, de titularidade do Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>iv.  “Sensei Coin”: uma moeda virtual, de valor, até a presente data, equivalente a U$ 1,00, utilizada para representar o saldo do Usuário nas plataformas Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>v.  “Cashback”: porcentagem que a Sala de Poker retorna e o Sensei Poker Club transforma em Sensei Coins.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>vi.  “Serviços”: todos os serviços oferecidos pelo grupo Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>vii.   “Usuário”: a pessoa física ou jurídica, por intermédio de representante legal devidamente habilitado, que acessa a Plataforma Sensei Poker e realiza o seu cadastro pessoal, de acordo com o previsto nestes Termos de Uso, de modo a usufruir os Serviços oferecidos pela Sensei Poker, aceitando, nessa oportunidade, incondicionalmente, os presentes Termos de Uso e Políticas de Privacidade.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>3. CADASTRO E VERIFICAÇÃO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.1. Para utilizarem os Serviços, os Usuários deverão realizar um cadastro prévio (“Cadastro”) em que o Usuário deverá informar um e-mail válido, criação de login e senha, bem como os dados previstos nos itens deste termo de uso.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.2. O Usuário responderá pela veracidade e atualização dos dados informados no Cadastro e será exclusivamente responsável pelo correto preenchimento de seu Cadastro, inclusive dos dados fornecidos para Requisições de Recebimento e Requisições de Envio, nos termos do item abaixo.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.3. O Cadastro a ser preenchido pelo Usuário pessoa física incluirá, entre outras informações, (i) nome completo; (ii) data de nascimento; (iii) o número do Cadastro Nacional de Pessoa Física (CPF), (iv) uma imagem de documento com foto; (v) número de telefone e código de Discagem Direta a Distância (DDD); (vi) e-mail; (vii) foto na modalidade “selfie” contendo o Usuário e o documento legível.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.4. Ao preencher o formulário de Cadastro, o Usuário ingressa no banco de dados do Sensei Poker, autorizando o Sensei Poker o acesso a referidos dados disponibilizados.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.5. Sendo constatada irregularidade no preenchimento do Cadastro, omissão de informações, dados equivocados ou preenchimentos errôneos, o Sensei Poker poderá suspender o acesso do Usuário até que a situação se regularize, não se responsabilizando pelo salvamento automático de cadastro anterior.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.6. O Cadastro na Plataforma é gratuito, porém podem ser cobradas taxas pelos Serviços.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.7. A proteção e privacidade dos dados pessoais, de acesso e de transações dos Usuários são obrigação do Sensei Poker como parte de sua Política de Privacidade.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.8. Para a emissão de Requisições de Recebimento ou Requisições de Envio, é necessário que o Cadastro esteja validado pelo Sensei Poker, sendo que a validação do Cadastro se dará mediante a análise de todos os documentos e informações fornecidas pelo Usuário para fins do Cadastro. Para esta validação e consequente cumprimento dos procedimentos mínimos de segurança internos, o Sensei Poker realizará um procedimento de verificação dos dados informados e poderá solicitar ao Usuário o envio de cópia de documentos e/ou documentos adicionais.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.9. O Usuário garante que todas as informações prestadas são válidas e precisas e que a senha determinada é pessoal e intransferível. A atualização dos dados informados também é de responsabilidade do Usuário e este está ciente de que a precisão e veracidade destes dados são imprescindíveis à efetivação dos Serviços.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.10. O Sensei Poker se reserva o direito de recusar qualquer solicitação de cadastro e de suspender ou cancelar um cadastro previamente aceito nos casos (i) de violação de qualquer das disposições destes Termos de Uso; (ii) impossibilidade de verificação da identidade do Usuário ou constatação de falsidade em qualquer das informações por ele fornecidas; (iii) prática pelo Usuário de atos fraudulentos ou dolosos ou a adoção de qualquer comportamento que, a critério do Sensei Poker, seja incompatível com os objetivos da Plataforma, com a urbanidade com outros Usuários ou que possam, de qualquer modo, causar danos a terceiros ou à Plataforma ou ainda, por falta de interesse comercial. Caso o cadastro de qualquer Usuário seja suspenso ou cancelado por quaisquer das razões previstas nestes Termos de Uso, todas as suas Requisições de Recebimento ou Envio ainda não executadas serão automaticamente canceladas.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.11. O Usuário aceita e concorda que toda e qualquer transação deve ser efetuada tão somente pelo titular do Cadastro.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.12. O “nome de usuário” (log-in) não poderá guardar semelhança com o nome “Sensei Poker”. Também poderão ser cancelados Cadastros efetuados com nomes de Usuário (log-in) considerados ofensivos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.13. O Usuário está ciente de que é proibido manter mais de um Cadastro na Plataforma e que, em caso de descumprimento, o Sensei Poker poderá cancelar ou suspender todos os cadastros e Requisições de Envio e Recebimento vinculados.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3,14. O Usuário é o único e exclusivo responsável por manter em segurança e sigilo seu login e senha, evitando assim o uso não autorizado de seu Cadastro. Para proteger sua senha, o Usuário deverá (i) memorizá-la, (ii) não usar uma senha que possa ser facilmente associada a você, tal como seu nome, data de nascimento ou número de telefone; (iii) tomar cuidado para impedir que outra pessoa veja a sua senha quando você a estiver utilizando; e (iv) não manter um registro de sua senha juntamente com o registro de seu login.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.15. O Usuário deverá avisar imediatamente o Sensei Poker, através dos canais de atendimento (instagram, telegram ou email), em caso de suspeita de acesso indevido a sua Carteira Virtual, ou qualquer atividade que possa dar ensejo a tal, para que o Sensei Poker possa tomar as devidas providências.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.16. Para a segurança dos usuários. AO ACESSAR A PLATAFORMA E ANTES DE REALIZAR O LOGIN, VERIFIQUE SE O ENDEREÇO ESTÁ CORRETO (wallet.sensei.poker). O SENSEI POKER NÃO SOLICITA SENHA, DADOS DE CARTÃO DE CRÉDITO OU OUTROS DADOS BANCÁRIOS DE SEUS CLIENTES POR E-MAIL, TELEFONE OU QUALQUER OUTRO CANAL DE ATENDIMENTO PERSONALIZADO. Portanto, se você verificar a existência de website muito idêntico ao da Sensei Wallet, com endereço diferente (como por exemplo, mas sem se limitar a www.senseiwallet.com.br e www.senseiwallet.poker), não realize login e relate a existência deste site por meio dos canais de atendimento/comunicação do Sensei Poker. Se você receber qualquer comunicação com esse tipo de abordagem e conteúdo, não responda, desconsidere a comunicação e, se possível, encaminhe o seu relato através dos canais de atendimento/comunicação do Sensei Poker.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>4. WEBSITES DE TERCEIROS</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>4.1. A Sensei Poker poderá fornecer links para outros websites da internet ou outros recursos. Assim, estes links serão disponibilizados como uma conveniência ao Usuário e, como a Sensei Poker não tem controle sobre tais websites ou recursos externos, o Usuário reconhece e concorda que a Sensei Poker não é responsável pela disponibilidade desses e não endossa ou se responsabiliza por qualquer conteúdo, propaganda, produtos, serviços ou outros materiais contidos ou disponibilizados por meio de tais websites ou recursos. O Usuário expressamente reconhece e concorda que a Sensei Poker não será responsável, direta ou indiretamente, por quaisquer perdas e danos que sejam efetiva ou alegadamente causados por, ou em conexão, pela confiança depositada em tais Informações, bens e serviços disponíveis no ou através de tais websites de terceiros.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>5. PROPRIEDADE INTELECTUAL</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.1. Salvo se diversamente informado, a Sensei Poker é a detentora dos direitos autorais de toda a informação disponibilizada no Site, bem como de todos os direitos de propriedade intelectual e direitos conexos, incluindo, mas não se limitando a direitos sobre o banco de dados, marcas, pedidos de registro, etc.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.2. Exceto se Você tiver a permissão expressa e por escrito da Sensei Poker, Você não deverá utilizar o nome, marca ou logotipo pertencente a Sensei Poker. Você tem permissão para fazer o download, imprimir, armazenar temporariamente, recuperar e exibir as Informações do Site na tela do computador ou dispositivo móvel, imprimir páginas (mas não copiar) e armazenar essas páginas em formato eletrônico no computador ou dispositivo móvel para uso pessoal ou para uso interno em sua organização.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.3. Você não tem permissão (exceto quando receber permissão expressa) para adaptar ou modificar as Informações neste Site ou qualquer parte dele e as Informações ou qualquer parte delas não podem ser copiadas, reproduzidas, republicadas, baixadas, postadas ou transmitidas de qualquer outra forma a terceiros para ganhos comerciais. Nenhuma disposição será interpretada como concessão aos Usuários de qualquer direito, título ou interesse em qualquer propriedade intelectual da Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.4. A Plataforma, o conteúdo e quaisquer outros textos, gráficos, imagens, fotos, ilustrações, marcas comerciais, nomes comerciais, marcas de serviço, logotipos, informações, código-fonte, layouts, nomes de domínio, software, know-how, e outros materiais são todos protegidos por direito de propriedade intelectual (“Direito de Propriedade Intelectual”). Todos os Direitos de Propriedade Intelectual presentes na Plataforma são de titularidade do Sensei Poker, licenciados ao Sensei Poker ou de nossos parceiros (conforme aplicável).
</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.5. A exploração comercial, transmissão, divulgação, modificação, reprodução, cópia ou quaisquer outras formas de utilização comercial de tal conteúdo pelo usuário são rigorosamente proibidas.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.6. Em caso de violação pelo Usuário de qualquer Direito de Propriedade Intelectual do Sensei Poker, o Usuário assume toda e qualquer responsabilidade de caráter civil e/ou criminal advindos de referida violação.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.7. Se você é um proprietário de direitos autorais ou representante de um detentor de tais direitos e acredita que um conteúdo publicado na Plataforma infringe seus direitos, por favor nos envie um aviso com as seguintes informações: a) assinatura eletrônica ou física da pessoa autorizada a agir em nome do proprietário dos direitos; (b) descrição da obra protegida por direitos autorais que alega ter sido indevidamente reproduzida; (c) o endereço virtual (URL) em que se encontra o material que alega apresentar infração ao seu direito; (d) seu endereço, número de telefone e e-mail para contato; (e) declaração de que você tem boas razões para acreditar que o uso contestado não é autorizado pelo proprietário dos direitos ou pela lei; e (f) declaração feita por você de que as informações em sua notificação são precisas e de que você é o proprietário dos direitos ou autorizado a atuar em nome do proprietário dos direitos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.8. Se você é um proprietário de direitos autorais ou representante de um detentor de tais direitos e acredita que um conteúdo publicado na Plataforma infringe seus direitos, por favor nos envie um aviso com as seguintes informações: a) assinatura eletrônica ou física da pessoa autorizada a agir em nome do proprietário dos direitos; (b) descrição da obra protegida por direitos autorais que alega ter sido indevidamente reproduzida; (c) o endereço virtual (URL) em que se encontra o material que alega apresentar infração ao seu direito; (d) seu endereço, número de telefone e e-mail para contato; (e) declaração de que você tem boas razões para acreditar que o uso contestado não é autorizado pelo proprietário dos direitos ou pela lei; e (f) declaração feita por você de que as informações em sua notificação são precisas e de que você é o proprietário dos direitos ou autorizado a atuar em nome do proprietário dos direitos.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>6. LIMITAÇÃO DE RESPONSABILIDADE</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>6.1. A SENSEI POKER NÃO FORNECE QUALQUER GARANTIA COM RELAÇÃO AO CONTEÚDO E SERVIÇOS DISPONIBILIZADOS NO SITE. SEM QUALQUER LIMITAÇÃO, TODO O CONTEÚDO E FUNCIONALIDADES DO SITE/ PLATAFORMA SÃO DISPONIBILIZADOS SEM GARANTIAS DE QUALQUER ESPÉCIE, SEJAM EXPRESSAS OU IMPLÍCITAS, INCLUINDO, ENTRE OUTRAS, GARANTIAS DE ADEQUAÇÃO PARA UM OBJETIVO EM PARTICULAR. A SENSEI POKER NÃO É NEM SERÁ RESPONSÁVEL (A) PELA QUALIDADE DA CONEXÃO DO COMPUTADOR E/OU DISPOSITIVO MÓVEL DO USUÁRIO À INTERNET EM QUALQUER CIRCUNST NCIA, (B) PELA DISPONIBILIDADE OU NÃO DOS SERVIÇOS DE ACESSO À INTERNET PRESTADOS POR TERCEIROS, (C) PELA IMPOSSIBILIDADE DE UTILIZAÇÃO DE QUAISQUER INFORMAÇÕES OU SERVIÇOS ACIMA MENCIONADOS EM RAZÃO DA INCOMPATIBILIDADE DA CONFIGURAÇÃO TÉCNICA DO COMPUTADOR E/OU DISPOSITIVO MÓVEL DOS USUÁRIOS, (D) POR QUAISQUER DANOS SOFRIDOS PELOS USUÁRIOS EM RAZÃO DA UTILIZAÇÃO DO APLICATIVO, E (E) PELA REPARAÇÃO DE DANOS DE QUALQUER NATUREZA CAUSADOS PELA QUEBRA DE PRIVACIDADE OU SEGURANÇA DE DADOS DOS USUÁRIOS DURANTE A CONEXÃO DE SEU DISPOSITIVO MÓVEL À INTERNET ATRAVÉS DE TERCEIROS. (F) PELA AUSÊNCIA DE REPASSE DE VALORES DE OUTRAS PLATAFORMAS REFERENTES A CASHBACK OU MOEDAS VIRTUAIS.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>7. POLÍTICA DE PRIVACIDADE</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>O documento de política de privacidade do Sensei Poker pode ser acessado por <a href="https://drive.google.com/file/d/1lGueUhs8bBPBWhu8h_AfGGlfk57_MS9v/view?usp=sharing" target='_blank' rel='noreferrer'>este link</a></Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>8. ALTERAÇÃO AOS TERMOS DE USO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>8.1. A Sensei Poker se reserva ao direito de, a sua discrição, alterar qualquer parte do Site, das Informações ou dos presentes Termos de Uso. Caso estes Termos de Uso sejam alterados, a Sensei Poker publicará detalhes das alterações no Site. Ao continuar a usar o Site, você concorda em estar vinculado aos Termos de Uso conforme alterados.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>9. VIGÊNCIA</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>9.1. O prazo para utilização da Plataforma e de vigência dos presentes Termos de Uso será por tempo indeterminado.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>10. RESCISÃO, INTERRUPÇÃO, SUSPENSÃO OU CANCELAMENTO DO CADASTRO E/OU SERVIÇOS</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.1.  O Usuário poderá rescindir este instrumento a qualquer momento, por meio da solicitação de encerramento do Cadastro de sua titularidade através dos nossos canais de comunicação e atendimento (telegram, instagram e email).</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.2.  Após a suspensão e/ou cancelamento do Cadastro do Usuário, realizado por ele mesmo ou pelo Sensei Poker, o Usuário reconhece que poderá não mais ter acesso e/ou resgatar informações sobre transações realizadas através da Plataforma, não tendo o Sensei Poker qualquer dever de armazenamento de informações, tampouco de repassar essas informações ao Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.3.  Em caso de suspeita de fraude ou de qualquer outra atividade ilícita, o Sensei Poker poderá, além de recorrer às medidas legais cabíveis, reter eventuais fundos ou ativos armazenados na Carteira Virtual do Usuário ou de qualquer outra forma entregues ao Sensei Poker, até a conclusão das respectivas investigações.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4. O Sensei Poker reserva para si o direito de suspender e/ou cancelar o cadastro dos Usuários imediatamente e indefinidamente, a qualquer tempo e sem aviso prévio, em caso de indícios de, mas sem se limitar a:</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.1. Fornecimento de informações falsas, imprecisas, incompletas ou enganosas;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.2. Não fornecimento de informações e/ou documentos adicionais, seja ou não para a validação do Cadastro;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.3. Agir de má-fé;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.4. Violação de algum dos termos dos presentes Termos de Uso;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.5. Uso dos Serviços em desacordo com a lei ou qualquer regulamento relacionado;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.6. Envolvimento em condutas fraudulentas ou ilegais;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.7. Operações incompatíveis com a capacidade econômica do Usuário. Mesmo em caso de rescisão, todas as taxas pagas e cobranças feitas antes do encerramento não são reembolsáveis.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.5. Contas Inativas: O presente Contrato poderá ser rescindido pelo Sensei Poker uma vez evidenciada a inatividade da conta por mais de 180 (cento e oitenta) dias, de modo que o Usuário receberá um e-mail para que atualize sua conta e manifeste interesse na continuidade dos serviços., em um prazo não superior a 7 (sete) dias.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.5.1. A exclusivo critério do Sensei Poker, a conta que não houver movimentação por mais de 180 (cento e oitenta dias) e que haja saldo, poderá ser classificada como dormente, somente sendo reativada mediante a atualização de informações cadastrais completas.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.6. Direitos e Deveres após a Suspensão e/ou Cancelamento: A rescisão dos presentes Termos de Uso não afetará nenhum direito e obrigação que poderão ser acumulados antes da rescisão ou término destes Termos de Uso e/ou da prestação dos Serviços. As obrigações de quaisquer das partes contraídas pelos presentes Termos de Uso permanecerão após sua rescisão e continuarão em pleno vigor e efeito até que sejam sanadas, incluindo, mas sem se limitar a, Requisições de Recebimento, Requisições de Envio e Requisições de Transferência.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.6.1. Em caso de suspensão e/ou cancelamento de Cadastro, o Usuário autoriza desde já que: o Sensei Poker poderá fazer envio, em nome do Usuário, todo e qualquer fundo mantido na Carteira Virtual para plataforma externa (sala de poker ou e-wallet) informada pelo usuário. Até que tal transferência seja efetuada, os recursos mantidos na Carteira Virtual do Usuário ficarão à disposição deste mediante solicitação ao Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.6.2. As formas de interrupção, suspensão e desativação dos Serviços não isentam os Usuários, a qualquer tempo, de cumprimento destes Termos de Uso.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>11.  RESPONSABILIDADE DO SENSEI POKER</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>11.1. O Sensei Poker não será responsabilizado por casos fortuitos ou de força maior ou fatos decorrentes de culpa exclusiva de terceiros ou do Usuário, como (i) resultados produzidos pela Plataforma, caso esta seja afetada por algum tipo de programa externo, como aqueles conhecidos popularmente como vírus, ou por falha de operação ou corrupção de dados; (ii) qualquer defeito decorrente de culpa exclusiva do Usuário; (iii) integração da Plataforma contratada com qualquer outro software de terceiros ou do Usuário; (iv) danos ou prejuízos decorrentes de decisões administrativas, gerenciais ou comerciais tomadas pelo Usuário com base nas informações fornecidas pela Plataforma; (v) problemas na rede do Usuário; (vi) toda e qualquer responsabilidade por eventuais perdas e danos, lucros cessantes ou emergentes, cujas causas possam ser atribuídas, de forma direta ou indireta, ao fornecimento, utilização ou desempenho da Plataforma; (vii) eventos definidos na legislação civil como caso fortuito ou força maior; (viii) uso perigoso e/ou inadequado do Plataforma; e (ix) fatores de ordem técnica que impossibilitem a transferência de dados e/ou o download de informações da Plataforma contratada pelo Usuário e/ou de suas respectivas versões, se houver.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>11.2. O Sensei Poker não será responsável perante o Usuário por acatar e cumprir qualquer mandado, ordem judicial, sentença ou decisão, independentemente de tal mandado, ordem judicial, sentença ou decisão vir a ser subsequentemente objeto de reforma, modificação, anulação, revogação ou cancelamento.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>12. DISPOSIÇÕES ILEGAIS, INVÁLIDAS OU INEXEQUÍVEIS</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>12.1. Se estes Termos de Uso ou qualquer parte deles forem considerados ilegais, inválidos ou inexequíveis sob as leis de qualquer estado ou país em que estes Termos de Uso se destinem a ser efetivos, então, na medida em que as disposições sejam ilegais, inválidas ou inexequíveis, tais disposições devem ser tratadas como apartadas e excluídas destes Termos de Uso e as demais disposições destes Termos de Uso devem ser mantidas, permanecer em pleno vigor e efeito e continuar a ser vinculativa e exequível nesse estado ou país.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>13. INDENIZAÇÃO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>13.1. O Usuário concorda em isentar e indenizar, defender e manter o Sensei Poker e seus respectivos diretores, agentes, sócios e funcionários indenes de qualquer prejuízo, responsabilidade, ação judicial ou demanda, incluindo honorários advocatícios, devidos a ou decorrentes (i) do uso da Plataforma, (ii) de violação destes Termos de Uso ou da Política de Privacidade, (iii) de falhas ocorridas nos equipamentos do Usuário e/ou (iv) da utilização indevida das informações ou materiais da Plataforma.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>14. VEDAÇÕES</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>14.1. Violar qualquer legislação municipal, nacional ou internacional que seja integrada ao ordenamento jurídico brasileiro, ou ainda, que, por qualquer razão legal, deva ser no Brasil aplicada; praticar atos contrários à moral e aos bons costumes; </Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>14.2. Assumir a personalidade ou identidade de outra pessoa, física ou jurídica; carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer conteúdo que viole quaisquer direitos de terceiro, incluindo Direitos de Propriedade Intelectual (conforme definido abaixo); carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer tipo de anúncio, propaganda ou material promocional não solicitado ou não autorizado pela Plataforma, tais como mensagens não solicitadas ou mensagens enviadas em massa (conhecidos como junk mail ou spam);</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>14.3. Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer conteúdo que contenha vírus ou qualquer outro código, arquivo ou programa de computador com o propósito de interromper, destruir ou limitar a funcionalidade de qualquer software, hardware ou equipamento; ameaçar, coagir, constranger demais Usuários; violar direitos de sigilo e privacidade de outros Usuários ou de terceiros, sempre observada a Política de Privacidade; e praticar quaisquer atos que direta ou indiretamente, no todo ou em parte, possam causar prejuízo ao Sensei Poker, a outros Usuários ou a qualquer terceiro.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>15. FUNCIONAMENTO, REGRAS DE UTILIZAÇÃO DOS SERVIÇOS, LIMITES, RESTRIÇÕES E RESPONSABILIDADES DO USUÁRIO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.1. Funcionamento Geral: Através dos Serviços providos pela Plataforma Sensei Wallet, o Sensei Poker oferece uma plataforma para movimentação de dinheiro e fichas entre diferentes salas de poker e e-wallets, facilitando a gestão de bankroll (caixa) para os jogadores e times de poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.2. O Usuário poderá utilizar os Serviços por meio da Plataforma para (i) armazenar saldo em Sensei Coins, (ii) emitir Requisição de Recebimento, Envio ou transferência, (iii) receber em sua Carteira Virtual Sensei Coins provenientes de Requisições de Recebimento, (iv) enviar seu saldo de Sensei Coins para plataformas de Poker ou e-Wallets por meio de Requisições de Envio e (v) Transferir Sensei Coins para outro usuário da Sensei Wallet por meio de Requisições de Transferência.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.3. O Usuário reconhece e concorda expressamente que a inserção de seu login e senha nos campos indicados na Plataforma constituem uma legítima e suficiente forma para a comprovação da identidade do Usuário e da validade de sua declaração de vontade em contratar os Serviços e transmitir as Requisições de Recebimento, Requisições de Envio, Requisições de Transferência e quaisquer outras instruções no âmbito da Plataforma.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.4. Carteira Virtual: Após conclusão do Cadastro pelo Usuário, é criada automaticamente para o Usuário uma Carteira Virtual de armazenamento de Sensei Coins, de titularidade do Usuário, mantida em seu nome, que poderá ser acessada, consultada e transacionada através do uso de seu login e senha.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.5. Limites: O Sensei Poker poderá utilizar determinados parâmetros próprios para estabelecer determinados limites, tais como, mas não se limitando a, quantidade de transações de Sensei Coins e de valores transferidos e armazenados na Conta Arrecadadora.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.5.1. O Sensei Poker poderá, a seu exclusivo critério, aumentar e diminuir os limites inicialmente estipulados, solicitando as informações e documentações necessárias.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.6. Da Utilização dos Serviços: As transações realizadas entre os Usuários são de inteira responsabilidade dos Usuários.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.6.1. O Usuário se compromete a não utilizar os Serviços para fins diretos ou indiretos de (i) infringir qualquer lei, regulamento, estes Termos de Uso, nem praticar atos contrários à moral e aos bons costumes; (ii) praticar lavagem de dinheiro; e/ou (iii) financiar atividades e/ou organizações que envolvam terrorismo, crime organizado, tráfico de drogas, pessoas e/ou órgãos humanos. Neste caso, a conta será encerrada, sem ônus ou multa, sem a necessidade de especificar o motivo, inclusive podendo ser por desinteresse comercial, sem a necessidade de qualquer aviso prévio.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.6.2. O Usuário declara-se ciente que o Sensei Poker comunicará às salas de poker ou e-wallets as Transações que possam ser consideradas como crimes de lavagem ou ocultação de bens, direitos e valores. Ademais, o Sensei Poker poderá informar os dados cadastrais do Usuário às Autoridades Públicas, para atendimento de normas regulamentares e/ou requisições judiciais.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.6.3. Como condição para a utilização dos Serviços, o Usuário entende e reconhece que as transações de compra e venda de Sensei Coins são realizadas por sua conta e risco e que o Sensei Poker atua exclusivamente como mantenedor da Plataforma para que os Usuários transacionem entre si. Em nenhum momento o Sensei Poker poderá ser considerado responsável por quaisquer perdas, danos, prejuízos e/ou lucros cessantes que possam eventualmente ser sofridos pelos Usuários devido às transações de recebimento/envio/transferência realizadas ou não através da Plataforma.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>16. SUPORTE TÉCNICO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.1. A prestação do serviço de suporte técnico limitar-se-á a esclarecimentos acerca do funcionamento da Plataforma e sua operação, sendo certo que o Usuário, ao aceitar os termos previstos nestes Termos de Uso, declara possuir o conhecimento mínimo do uso do computador e/ou dispositivo móvel e suas funções, bem como do sistema operacional sob o qual o sistema será utilizado. O Usuário declara, ainda, possuir a configuração adequada em seu computador e/ou dispositivo móvel no que se refere à utilização da Plataforma e o bom estado de funcionamento do referido computador/dispositivo móvel.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.2. O suporte técnico ao Usuário se dará dos canais de atendimento do Sensei (Telegram, Instagram e email), por meio de troca de informações, cabendo ao Sensei Poker fornecer uma resposta em até 5 (cinco) dias úteis, contadas do chamado realizado pelo Usuário, não estando incluído neste serviço, o atendimento in loco.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.3. O suporte técnico estará disponível nos dias úteis, no horário de 10h às 19h.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.4. O suporte técnico fornecido pelo Sensei Poker não abrange a conexão com a internet, rede interna e computadores/dispositivos móveis do Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.5. O Sensei Poker procura assegurar que as informações fornecidas sejam precisas, completas, atuais e que o seu uso não tenha interrupções nem erros. No entanto, não podemos garantir a operação da Plataforma em tempo integral, na medida em que esta depende de serviços prestados por terceiros, como empresas de telecomunicações e provedores de acesso à internet. Além disso, suspenderemos o uso e acesso à Plataforma em caso fortuito ou força maior, por exemplo, em caso de ataques cibernéticos e outros que possam prejudicar as funcionalidades da Plataforma e colocar em risco as informações dos Usuários.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>17. CONSIDERAÇÕES FINAIS</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.1. A incapacidade ou a mera liberalidade em não fazer cumprir quaisquer disposições destes Termos de Uso não constitui renúncia de direitos, podendo ser exigido o seu cumprimento em qualquer momento.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.2 Caso qualquer disposição destes Termos de Uso for considerada inexequível ou inválida, essa disposição será isoladamente invalidada e não atingirá o restante das disposições destes Termos de Uso.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.3. Estes Termos de Uso e a relação entre as Partes serão regidos pelas leis da República Federativa do Brasil. As Partes elegem o Foro da Comarca de Curitiba como sendo o único competente para dirimir quaisquer litígios e/ou demandas que venham a envolver as Partes em relação aos Serviços.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.4.  A tolerância de uma Parte com a outra quanto ao descumprimento de qualquer uma das obrigações assumidas nestes Termos de Uso não implicará em novação ou renúncia de direito. A Parte tolerante poderá, a qualquer tempo, exigir da outra Parte o fiel e cabal cumprimento destes Termos de Uso.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.5. A inviabilidade da execução ou invalidade, ainda que em virtude de decisão judicial, de alguma cláusula, não afetará a validade das demais cláusulas destes Termos de Uso, que permanecerão válidos e exequíveis conforme seus termos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.6. O Sensei Poker se reserva o direito de modificar o presente Termos de Uso a qualquer tempo, sendo o Usuário comunicado de tais alterações, mediante o aceite das novas disposições.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>18. LEI APLICÁVEL</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>18.1.  Esses Termos de Uso estão sujeitos a lei brasileira e jurisdição dos tribunais brasileiros.</Typography>
        </div>
      ),
      'us': (
        <div>
          <Typography variant='h6'>Termos de Uso</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>Este Termos e Condições de Uso (“Termos de Uso”) descreve os termos e as condições aplicáveis ao acesso e uso da plataforma Sensei Poker, disponível no site: https://sensei.poker/, inscrita no CNPJ sob nº  23.217.779/0001-05, com sede e domicílio na QND 07 Casa 39 Frente Parte 1, Taguatinga, Brasília – DF, CEP nº 72.120-070</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>Por favor, leia atentamente os termos e condições de uso abaixo estipulados (“Termos de Uso”) para que você possa usufruir de nosso Site e de todos os serviços oferecidos pela Sensei Poker. Caso você não concorde com quaisquer dispositivos dos Termos de Uso, não acesse ou utilize o nosso Site ou nossos Serviços.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>SEU ACESSO E UTILIZAÇÃO REPRESENTA SUA ACEITAÇÃO INTEGRAL E INCONDICIONAL AOS TERMOS DE USO.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>A Política de Privacidade integra os presentes Termos de Uso. Para fins destes Termos de Uso, “Usuários” ou “Vocês” são todas as pessoas que de qualquer forma interagem, acessam ou utilizam o Site ou os Serviços da Sensei Poker.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>1. SOBRE O SENSEI POKER</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.1.  Esse Site pode apresentar informações, textos, dados, imagens, fotografias, vídeos, sons, ilustrações, nome, logos, marcas e informações diversas sobre a Sensei Poker, suas práticas e informações gerais da atividade de Wallet, por intermédio da facilitação de pagamentos, sendo apenas uma intermediadora e agenciadora dos serviços de cashback e wallet da plataforma, que poderão ser fornecidas na forma de documentos (“Atividades”).</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.2. Salvo disposição expressa em contrário, as atividades não se destinam a, nem constituem, serviços ou recomendações bancárias, emissão de moeda, investimentos financeiros, direcionamentos legais, contábeis, comerciais, financeiros, tributários ou afins. As Atividades elencadas são apenas com o intuito informativo e não deverão ser consideradas de forma genérica ou utilizadas com interpretação extensiva que fuja do real escopo de atuação da Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.3. Se você precisar de recomendações ou serviços sobre um assunto específico, entre em contato conosco diretamente, usando os nossos contatos indicados no Site. Embora a Sensei Poker procure garantir que as Informações contidas neste Site sejam precisas e atualizadas, não nos responsabilizaremos pelos resultados de qualquer ação tomada com base nas Informações disponibilizadas. Ademais, a Sensei Poker não garante que o Site ou qualquer das Informações sejam disponibilizadas de forma ininterrupta ou que quaisquer defeitos serão corrigidos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.4. SENSEI POKER ENVIDARÁ ESFORÇOS RAZOÁVEIS PARA INCLUIR INFORMAÇÕES PRECISAS E ATUALIZADAS NO SITE. NO ENTANTO, NÃO DÁ QUALQUER GARANTIA OU ASSUME QUALQUER RESPONSABILIDADE QUANTO À CONFIABILIDADE, EXATIDÃO, VALIDADE, ATUALIDADE, UTILIDADE, INTEGRIDADE, PERTINÊNCIA, OPORTUNIDADE OU ABRANGÊNCIA DE TAIS INFORMAÇÕES.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.5. Em nenhuma hipótese Sensei Poker será responsável por perdas indiretas ou quaisquer danos decorrentes da perda de uso, dados ou lucros, seja em contrato, ato ou de quaisquer outras formas, decorrentes de ou em conexão com o uso deste Site ou qualquer das Informações.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.6. SENSEI POKER AFASTA TODAS AS GARANTIAS E/OU RESPONSABILIDADES, EXPRESSAS OU IMPLÍCITAS, DECORRENTES OU RELATIVAS À UTILIZAÇÃO DESTE SITE E DE SEU CONTEÚDO, INCLUINDO GARANTIAS DE POSSÍVEL ADEQUAÇÃO A UMA FINALIDADE ESPECÍFICA.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.7. Para acessar a Plataforma Sensei Poker o Usuário precisará fazer cadastro no site, fornecendo as informações necessárias para sua identificação, conforme descrito no tópico 3 deste termo.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.8. Ao realizar o cadastro, o Usuário integrará o Sensei Poker Club, clube de benefícios administrado pela Sensei Poker, por meio do qual o Usuário poderá ter acesso aos links de cadastro das Salas de Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.9. As Salas de Poker, ambiente virtual disponível para apostas nos jogos de poker, são administradas por empresas parceiras, de forma que nós não temos controle sobre o que acontece nas salas virtuais. A Sensei Poker apenas intermedia a relação entre as Salas de Poker e os Usuários, possibilitando o uso de Sensei Coins para aposta.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.10. Para acessar a Plataforma Sensei Wallet, o Sensei Poker poderá conceder ao Usuário, em caráter acessório, uma licença limitada, pessoal, não exclusiva, não transferível e plenamente revogável, para usar a Plataforma Sensei Wallet em plataforma online, desde que em conformidade com os termos aqui contidos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.11. O Usuário declara-se integralmente responsável pelas informações inseridas na Plataforma, pelo cadastramento, permissões, senhas e modo de utilização. O Sensei Poker, em hipótese alguma, será responsável pelo conteúdo (informações, senhas, cópias de informações etc.) oferecido pelo Usuário, não sendo, portanto, estas informações revisadas nem divulgadas em momento algum. A responsabilidade pelas informações é sempre do Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.12. O Sensei Poker reserva-se o direito de atualizar a Plataforma sempre que desejar, bem como deixar de comercializá-la, atualizá-la, ou mesmo descontinuá-la a qualquer tempo.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.13. Em razão das Salas de Poker não estarem sob o comando da Sensei Poker, não nos responsabilizamos por eventuais bugs no sistema das salas, bem como por salas fora do ar ou problemas de conexão com o servidor. Tudo que acontece no ambiente virtual das Salas de Poker é de responsabilidade única e exclusiva de seus administradores.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.14. Os valores gerados através de transferências, recebimentos e eventuais Cashbacks ficarão disponíveis aos usuários na plataforma do Sensei Poker ou na Sensei Wallet, carteira digital da Sensei Poker. O uso e administração dos valores é de inteira responsabilidade do Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.15. A aprovação de transações de fichas está condicionada ao prévio recebimento dos valores que serão convertidos em Sensei Coins na plataforma do Sensei Poker ou na Sensei Wallet. Não nos responsabilizamos por transferências feitas pelo Usuário, mas não computadas em nosso sistema.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.16. As Sensei Coins poderão ser sacadas em Dólar ou Real, ou transferidas para outras e-wallets. Para realização da operação poderão ser cobradas taxas pela Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>1.17. Em caso de descontinuidade da Plataforma, o Sensei Poker garantirá o funcionamento por até 1 (uma) semana após a data do anúncio de descontinuidade.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>2. DEFINIÇÕES</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>2.1. Para facilitar seu entendimento e evitar repetições, os seguintes termos usados nestes Termos de Uso com a primeira letra em maiúsculo terão o significado estabelecido abaixo, seja no plural ou no singular, sem prejuízo de outros termos definidos ao longo destes Termos de Uso:</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>i.  “Sensei Poker Club”: clube administrado pela Sensei Poker, através de plataforma virtual, por meio do qual o Usuário pode receber benefícios/cashback ao jogar nas salas de poker parceiras.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>ii. “Sala de Poker”: plataforma virtual administrada por empresas parceiras, para onde o Usuário pode enviar seu dinheiro (sensei coins) e comprar entrada em jogos de poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>iii.  “Sensei Wallet”: carteira virtual de armazenamento de Sensei Coins, também conhecida como “wallet”, de titularidade do Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>iv.  “Sensei Coin”: uma moeda virtual, de valor, até a presente data, equivalente a U$ 1,00, utilizada para representar o saldo do Usuário nas plataformas Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>v.  “Cashback”: porcentagem que a Sala de Poker retorna e o Sensei Poker Club transforma em Sensei Coins.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>vi.  “Serviços”: todos os serviços oferecidos pelo grupo Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>vii.   “Usuário”: a pessoa física ou jurídica, por intermédio de representante legal devidamente habilitado, que acessa a Plataforma Sensei Poker e realiza o seu cadastro pessoal, de acordo com o previsto nestes Termos de Uso, de modo a usufruir os Serviços oferecidos pela Sensei Poker, aceitando, nessa oportunidade, incondicionalmente, os presentes Termos de Uso e Políticas de Privacidade.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>3. CADASTRO E VERIFICAÇÃO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.1. Para utilizarem os Serviços, os Usuários deverão realizar um cadastro prévio (“Cadastro”) em que o Usuário deverá informar um e-mail válido, criação de login e senha, bem como os dados previstos nos itens deste termo de uso.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.2. O Usuário responderá pela veracidade e atualização dos dados informados no Cadastro e será exclusivamente responsável pelo correto preenchimento de seu Cadastro, inclusive dos dados fornecidos para Requisições de Recebimento e Requisições de Envio, nos termos do item abaixo.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.3. O Cadastro a ser preenchido pelo Usuário pessoa física incluirá, entre outras informações, (i) nome completo; (ii) data de nascimento; (iii) o número do Cadastro Nacional de Pessoa Física (CPF), (iv) uma imagem de documento com foto; (v) número de telefone e código de Discagem Direta a Distância (DDD); (vi) e-mail; (vii) foto na modalidade “selfie” contendo o Usuário e o documento legível.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.4. Ao preencher o formulário de Cadastro, o Usuário ingressa no banco de dados do Sensei Poker, autorizando o Sensei Poker o acesso a referidos dados disponibilizados.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.5. Sendo constatada irregularidade no preenchimento do Cadastro, omissão de informações, dados equivocados ou preenchimentos errôneos, o Sensei Poker poderá suspender o acesso do Usuário até que a situação se regularize, não se responsabilizando pelo salvamento automático de cadastro anterior.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.6. O Cadastro na Plataforma é gratuito, porém podem ser cobradas taxas pelos Serviços.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.7. A proteção e privacidade dos dados pessoais, de acesso e de transações dos Usuários são obrigação do Sensei Poker como parte de sua Política de Privacidade.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.8. Para a emissão de Requisições de Recebimento ou Requisições de Envio, é necessário que o Cadastro esteja validado pelo Sensei Poker, sendo que a validação do Cadastro se dará mediante a análise de todos os documentos e informações fornecidas pelo Usuário para fins do Cadastro. Para esta validação e consequente cumprimento dos procedimentos mínimos de segurança internos, o Sensei Poker realizará um procedimento de verificação dos dados informados e poderá solicitar ao Usuário o envio de cópia de documentos e/ou documentos adicionais.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.9. O Usuário garante que todas as informações prestadas são válidas e precisas e que a senha determinada é pessoal e intransferível. A atualização dos dados informados também é de responsabilidade do Usuário e este está ciente de que a precisão e veracidade destes dados são imprescindíveis à efetivação dos Serviços.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.10. O Sensei Poker se reserva o direito de recusar qualquer solicitação de cadastro e de suspender ou cancelar um cadastro previamente aceito nos casos (i) de violação de qualquer das disposições destes Termos de Uso; (ii) impossibilidade de verificação da identidade do Usuário ou constatação de falsidade em qualquer das informações por ele fornecidas; (iii) prática pelo Usuário de atos fraudulentos ou dolosos ou a adoção de qualquer comportamento que, a critério do Sensei Poker, seja incompatível com os objetivos da Plataforma, com a urbanidade com outros Usuários ou que possam, de qualquer modo, causar danos a terceiros ou à Plataforma ou ainda, por falta de interesse comercial. Caso o cadastro de qualquer Usuário seja suspenso ou cancelado por quaisquer das razões previstas nestes Termos de Uso, todas as suas Requisições de Recebimento ou Envio ainda não executadas serão automaticamente canceladas.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.11. O Usuário aceita e concorda que toda e qualquer transação deve ser efetuada tão somente pelo titular do Cadastro.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.12. O “nome de usuário” (log-in) não poderá guardar semelhança com o nome “Sensei Poker”. Também poderão ser cancelados Cadastros efetuados com nomes de Usuário (log-in) considerados ofensivos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.13. O Usuário está ciente de que é proibido manter mais de um Cadastro na Plataforma e que, em caso de descumprimento, o Sensei Poker poderá cancelar ou suspender todos os cadastros e Requisições de Envio e Recebimento vinculados.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3,14. O Usuário é o único e exclusivo responsável por manter em segurança e sigilo seu login e senha, evitando assim o uso não autorizado de seu Cadastro. Para proteger sua senha, o Usuário deverá (i) memorizá-la, (ii) não usar uma senha que possa ser facilmente associada a você, tal como seu nome, data de nascimento ou número de telefone; (iii) tomar cuidado para impedir que outra pessoa veja a sua senha quando você a estiver utilizando; e (iv) não manter um registro de sua senha juntamente com o registro de seu login.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.15. O Usuário deverá avisar imediatamente o Sensei Poker, através dos canais de atendimento (instagram, telegram ou email), em caso de suspeita de acesso indevido a sua Carteira Virtual, ou qualquer atividade que possa dar ensejo a tal, para que o Sensei Poker possa tomar as devidas providências.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>3.16. Para a segurança dos usuários. AO ACESSAR A PLATAFORMA E ANTES DE REALIZAR O LOGIN, VERIFIQUE SE O ENDEREÇO ESTÁ CORRETO (wallet.sensei.poker). O SENSEI POKER NÃO SOLICITA SENHA, DADOS DE CARTÃO DE CRÉDITO OU OUTROS DADOS BANCÁRIOS DE SEUS CLIENTES POR E-MAIL, TELEFONE OU QUALQUER OUTRO CANAL DE ATENDIMENTO PERSONALIZADO. Portanto, se você verificar a existência de website muito idêntico ao da Sensei Wallet, com endereço diferente (como por exemplo, mas sem se limitar a www.senseiwallet.com.br e www.senseiwallet.poker), não realize login e relate a existência deste site por meio dos canais de atendimento/comunicação do Sensei Poker. Se você receber qualquer comunicação com esse tipo de abordagem e conteúdo, não responda, desconsidere a comunicação e, se possível, encaminhe o seu relato através dos canais de atendimento/comunicação do Sensei Poker.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>4. WEBSITES DE TERCEIROS</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>4.1. A Sensei Poker poderá fornecer links para outros websites da internet ou outros recursos. Assim, estes links serão disponibilizados como uma conveniência ao Usuário e, como a Sensei Poker não tem controle sobre tais websites ou recursos externos, o Usuário reconhece e concorda que a Sensei Poker não é responsável pela disponibilidade desses e não endossa ou se responsabiliza por qualquer conteúdo, propaganda, produtos, serviços ou outros materiais contidos ou disponibilizados por meio de tais websites ou recursos. O Usuário expressamente reconhece e concorda que a Sensei Poker não será responsável, direta ou indiretamente, por quaisquer perdas e danos que sejam efetiva ou alegadamente causados por, ou em conexão, pela confiança depositada em tais Informações, bens e serviços disponíveis no ou através de tais websites de terceiros.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>5. PROPRIEDADE INTELECTUAL</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.1. Salvo se diversamente informado, a Sensei Poker é a detentora dos direitos autorais de toda a informação disponibilizada no Site, bem como de todos os direitos de propriedade intelectual e direitos conexos, incluindo, mas não se limitando a direitos sobre o banco de dados, marcas, pedidos de registro, etc.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.2. Exceto se Você tiver a permissão expressa e por escrito da Sensei Poker, Você não deverá utilizar o nome, marca ou logotipo pertencente a Sensei Poker. Você tem permissão para fazer o download, imprimir, armazenar temporariamente, recuperar e exibir as Informações do Site na tela do computador ou dispositivo móvel, imprimir páginas (mas não copiar) e armazenar essas páginas em formato eletrônico no computador ou dispositivo móvel para uso pessoal ou para uso interno em sua organização.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.3. Você não tem permissão (exceto quando receber permissão expressa) para adaptar ou modificar as Informações neste Site ou qualquer parte dele e as Informações ou qualquer parte delas não podem ser copiadas, reproduzidas, republicadas, baixadas, postadas ou transmitidas de qualquer outra forma a terceiros para ganhos comerciais. Nenhuma disposição será interpretada como concessão aos Usuários de qualquer direito, título ou interesse em qualquer propriedade intelectual da Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.4. A Plataforma, o conteúdo e quaisquer outros textos, gráficos, imagens, fotos, ilustrações, marcas comerciais, nomes comerciais, marcas de serviço, logotipos, informações, código-fonte, layouts, nomes de domínio, software, know-how, e outros materiais são todos protegidos por direito de propriedade intelectual (“Direito de Propriedade Intelectual”). Todos os Direitos de Propriedade Intelectual presentes na Plataforma são de titularidade do Sensei Poker, licenciados ao Sensei Poker ou de nossos parceiros (conforme aplicável).
</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.5. A exploração comercial, transmissão, divulgação, modificação, reprodução, cópia ou quaisquer outras formas de utilização comercial de tal conteúdo pelo usuário são rigorosamente proibidas.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.6. Em caso de violação pelo Usuário de qualquer Direito de Propriedade Intelectual do Sensei Poker, o Usuário assume toda e qualquer responsabilidade de caráter civil e/ou criminal advindos de referida violação.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.7. Se você é um proprietário de direitos autorais ou representante de um detentor de tais direitos e acredita que um conteúdo publicado na Plataforma infringe seus direitos, por favor nos envie um aviso com as seguintes informações: a) assinatura eletrônica ou física da pessoa autorizada a agir em nome do proprietário dos direitos; (b) descrição da obra protegida por direitos autorais que alega ter sido indevidamente reproduzida; (c) o endereço virtual (URL) em que se encontra o material que alega apresentar infração ao seu direito; (d) seu endereço, número de telefone e e-mail para contato; (e) declaração de que você tem boas razões para acreditar que o uso contestado não é autorizado pelo proprietário dos direitos ou pela lei; e (f) declaração feita por você de que as informações em sua notificação são precisas e de que você é o proprietário dos direitos ou autorizado a atuar em nome do proprietário dos direitos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>5.8. Se você é um proprietário de direitos autorais ou representante de um detentor de tais direitos e acredita que um conteúdo publicado na Plataforma infringe seus direitos, por favor nos envie um aviso com as seguintes informações: a) assinatura eletrônica ou física da pessoa autorizada a agir em nome do proprietário dos direitos; (b) descrição da obra protegida por direitos autorais que alega ter sido indevidamente reproduzida; (c) o endereço virtual (URL) em que se encontra o material que alega apresentar infração ao seu direito; (d) seu endereço, número de telefone e e-mail para contato; (e) declaração de que você tem boas razões para acreditar que o uso contestado não é autorizado pelo proprietário dos direitos ou pela lei; e (f) declaração feita por você de que as informações em sua notificação são precisas e de que você é o proprietário dos direitos ou autorizado a atuar em nome do proprietário dos direitos.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>6. LIMITAÇÃO DE RESPONSABILIDADE</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>6.1. A SENSEI POKER NÃO FORNECE QUALQUER GARANTIA COM RELAÇÃO AO CONTEÚDO E SERVIÇOS DISPONIBILIZADOS NO SITE. SEM QUALQUER LIMITAÇÃO, TODO O CONTEÚDO E FUNCIONALIDADES DO SITE/ PLATAFORMA SÃO DISPONIBILIZADOS SEM GARANTIAS DE QUALQUER ESPÉCIE, SEJAM EXPRESSAS OU IMPLÍCITAS, INCLUINDO, ENTRE OUTRAS, GARANTIAS DE ADEQUAÇÃO PARA UM OBJETIVO EM PARTICULAR. A SENSEI POKER NÃO É NEM SERÁ RESPONSÁVEL (A) PELA QUALIDADE DA CONEXÃO DO COMPUTADOR E/OU DISPOSITIVO MÓVEL DO USUÁRIO À INTERNET EM QUALQUER CIRCUNST NCIA, (B) PELA DISPONIBILIDADE OU NÃO DOS SERVIÇOS DE ACESSO À INTERNET PRESTADOS POR TERCEIROS, (C) PELA IMPOSSIBILIDADE DE UTILIZAÇÃO DE QUAISQUER INFORMAÇÕES OU SERVIÇOS ACIMA MENCIONADOS EM RAZÃO DA INCOMPATIBILIDADE DA CONFIGURAÇÃO TÉCNICA DO COMPUTADOR E/OU DISPOSITIVO MÓVEL DOS USUÁRIOS, (D) POR QUAISQUER DANOS SOFRIDOS PELOS USUÁRIOS EM RAZÃO DA UTILIZAÇÃO DO APLICATIVO, E (E) PELA REPARAÇÃO DE DANOS DE QUALQUER NATUREZA CAUSADOS PELA QUEBRA DE PRIVACIDADE OU SEGURANÇA DE DADOS DOS USUÁRIOS DURANTE A CONEXÃO DE SEU DISPOSITIVO MÓVEL À INTERNET ATRAVÉS DE TERCEIROS. (F) PELA AUSÊNCIA DE REPASSE DE VALORES DE OUTRAS PLATAFORMAS REFERENTES A CASHBACK OU MOEDAS VIRTUAIS.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>7. POLÍTICA DE PRIVACIDADE</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>O documento de política de privacidade do Sensei Poker pode ser acessado por <a href="https://drive.google.com/file/d/1lGueUhs8bBPBWhu8h_AfGGlfk57_MS9v/view?usp=sharing" target='_blank' rel='noreferrer'>este link</a></Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>8. ALTERAÇÃO AOS TERMOS DE USO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>8.1. A Sensei Poker se reserva ao direito de, a sua discrição, alterar qualquer parte do Site, das Informações ou dos presentes Termos de Uso. Caso estes Termos de Uso sejam alterados, a Sensei Poker publicará detalhes das alterações no Site. Ao continuar a usar o Site, você concorda em estar vinculado aos Termos de Uso conforme alterados.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>9. VIGÊNCIA</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>9.1. O prazo para utilização da Plataforma e de vigência dos presentes Termos de Uso será por tempo indeterminado.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>10. RESCISÃO, INTERRUPÇÃO, SUSPENSÃO OU CANCELAMENTO DO CADASTRO E/OU SERVIÇOS</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.1.  O Usuário poderá rescindir este instrumento a qualquer momento, por meio da solicitação de encerramento do Cadastro de sua titularidade através dos nossos canais de comunicação e atendimento (telegram, instagram e email).</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.2.  Após a suspensão e/ou cancelamento do Cadastro do Usuário, realizado por ele mesmo ou pelo Sensei Poker, o Usuário reconhece que poderá não mais ter acesso e/ou resgatar informações sobre transações realizadas através da Plataforma, não tendo o Sensei Poker qualquer dever de armazenamento de informações, tampouco de repassar essas informações ao Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.3.  Em caso de suspeita de fraude ou de qualquer outra atividade ilícita, o Sensei Poker poderá, além de recorrer às medidas legais cabíveis, reter eventuais fundos ou ativos armazenados na Carteira Virtual do Usuário ou de qualquer outra forma entregues ao Sensei Poker, até a conclusão das respectivas investigações.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4. O Sensei Poker reserva para si o direito de suspender e/ou cancelar o cadastro dos Usuários imediatamente e indefinidamente, a qualquer tempo e sem aviso prévio, em caso de indícios de, mas sem se limitar a:</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.1. Fornecimento de informações falsas, imprecisas, incompletas ou enganosas;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.2. Não fornecimento de informações e/ou documentos adicionais, seja ou não para a validação do Cadastro;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.3. Agir de má-fé;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.4. Violação de algum dos termos dos presentes Termos de Uso;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.5. Uso dos Serviços em desacordo com a lei ou qualquer regulamento relacionado;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.6. Envolvimento em condutas fraudulentas ou ilegais;</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.4.7. Operações incompatíveis com a capacidade econômica do Usuário. Mesmo em caso de rescisão, todas as taxas pagas e cobranças feitas antes do encerramento não são reembolsáveis.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.5. Contas Inativas: O presente Contrato poderá ser rescindido pelo Sensei Poker uma vez evidenciada a inatividade da conta por mais de 180 (cento e oitenta) dias, de modo que o Usuário receberá um e-mail para que atualize sua conta e manifeste interesse na continuidade dos serviços., em um prazo não superior a 7 (sete) dias.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.5.1. A exclusivo critério do Sensei Poker, a conta que não houver movimentação por mais de 180 (cento e oitenta dias) e que haja saldo, poderá ser classificada como dormente, somente sendo reativada mediante a atualização de informações cadastrais completas.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.6. Direitos e Deveres após a Suspensão e/ou Cancelamento: A rescisão dos presentes Termos de Uso não afetará nenhum direito e obrigação que poderão ser acumulados antes da rescisão ou término destes Termos de Uso e/ou da prestação dos Serviços. As obrigações de quaisquer das partes contraídas pelos presentes Termos de Uso permanecerão após sua rescisão e continuarão em pleno vigor e efeito até que sejam sanadas, incluindo, mas sem se limitar a, Requisições de Recebimento, Requisições de Envio e Requisições de Transferência.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.6.1. Em caso de suspensão e/ou cancelamento de Cadastro, o Usuário autoriza desde já que: o Sensei Poker poderá fazer envio, em nome do Usuário, todo e qualquer fundo mantido na Carteira Virtual para plataforma externa (sala de poker ou e-wallet) informada pelo usuário. Até que tal transferência seja efetuada, os recursos mantidos na Carteira Virtual do Usuário ficarão à disposição deste mediante solicitação ao Sensei Poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>10.6.2. As formas de interrupção, suspensão e desativação dos Serviços não isentam os Usuários, a qualquer tempo, de cumprimento destes Termos de Uso.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>11.  RESPONSABILIDADE DO SENSEI POKER</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>11.1. O Sensei Poker não será responsabilizado por casos fortuitos ou de força maior ou fatos decorrentes de culpa exclusiva de terceiros ou do Usuário, como (i) resultados produzidos pela Plataforma, caso esta seja afetada por algum tipo de programa externo, como aqueles conhecidos popularmente como vírus, ou por falha de operação ou corrupção de dados; (ii) qualquer defeito decorrente de culpa exclusiva do Usuário; (iii) integração da Plataforma contratada com qualquer outro software de terceiros ou do Usuário; (iv) danos ou prejuízos decorrentes de decisões administrativas, gerenciais ou comerciais tomadas pelo Usuário com base nas informações fornecidas pela Plataforma; (v) problemas na rede do Usuário; (vi) toda e qualquer responsabilidade por eventuais perdas e danos, lucros cessantes ou emergentes, cujas causas possam ser atribuídas, de forma direta ou indireta, ao fornecimento, utilização ou desempenho da Plataforma; (vii) eventos definidos na legislação civil como caso fortuito ou força maior; (viii) uso perigoso e/ou inadequado do Plataforma; e (ix) fatores de ordem técnica que impossibilitem a transferência de dados e/ou o download de informações da Plataforma contratada pelo Usuário e/ou de suas respectivas versões, se houver.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>11.2. O Sensei Poker não será responsável perante o Usuário por acatar e cumprir qualquer mandado, ordem judicial, sentença ou decisão, independentemente de tal mandado, ordem judicial, sentença ou decisão vir a ser subsequentemente objeto de reforma, modificação, anulação, revogação ou cancelamento.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>12. DISPOSIÇÕES ILEGAIS, INVÁLIDAS OU INEXEQUÍVEIS</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>12.1. Se estes Termos de Uso ou qualquer parte deles forem considerados ilegais, inválidos ou inexequíveis sob as leis de qualquer estado ou país em que estes Termos de Uso se destinem a ser efetivos, então, na medida em que as disposições sejam ilegais, inválidas ou inexequíveis, tais disposições devem ser tratadas como apartadas e excluídas destes Termos de Uso e as demais disposições destes Termos de Uso devem ser mantidas, permanecer em pleno vigor e efeito e continuar a ser vinculativa e exequível nesse estado ou país.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>13. INDENIZAÇÃO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>13.1. O Usuário concorda em isentar e indenizar, defender e manter o Sensei Poker e seus respectivos diretores, agentes, sócios e funcionários indenes de qualquer prejuízo, responsabilidade, ação judicial ou demanda, incluindo honorários advocatícios, devidos a ou decorrentes (i) do uso da Plataforma, (ii) de violação destes Termos de Uso ou da Política de Privacidade, (iii) de falhas ocorridas nos equipamentos do Usuário e/ou (iv) da utilização indevida das informações ou materiais da Plataforma.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>14. VEDAÇÕES</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>14.1. Violar qualquer legislação municipal, nacional ou internacional que seja integrada ao ordenamento jurídico brasileiro, ou ainda, que, por qualquer razão legal, deva ser no Brasil aplicada; praticar atos contrários à moral e aos bons costumes; </Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>14.2. Assumir a personalidade ou identidade de outra pessoa, física ou jurídica; carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer conteúdo que viole quaisquer direitos de terceiro, incluindo Direitos de Propriedade Intelectual (conforme definido abaixo); carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer tipo de anúncio, propaganda ou material promocional não solicitado ou não autorizado pela Plataforma, tais como mensagens não solicitadas ou mensagens enviadas em massa (conhecidos como junk mail ou spam);</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>14.3. Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer conteúdo que contenha vírus ou qualquer outro código, arquivo ou programa de computador com o propósito de interromper, destruir ou limitar a funcionalidade de qualquer software, hardware ou equipamento; ameaçar, coagir, constranger demais Usuários; violar direitos de sigilo e privacidade de outros Usuários ou de terceiros, sempre observada a Política de Privacidade; e praticar quaisquer atos que direta ou indiretamente, no todo ou em parte, possam causar prejuízo ao Sensei Poker, a outros Usuários ou a qualquer terceiro.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>15. FUNCIONAMENTO, REGRAS DE UTILIZAÇÃO DOS SERVIÇOS, LIMITES, RESTRIÇÕES E RESPONSABILIDADES DO USUÁRIO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.1. Funcionamento Geral: Através dos Serviços providos pela Plataforma Sensei Wallet, o Sensei Poker oferece uma plataforma para movimentação de dinheiro e fichas entre diferentes salas de poker e e-wallets, facilitando a gestão de bankroll (caixa) para os jogadores e times de poker.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.2. O Usuário poderá utilizar os Serviços por meio da Plataforma para (i) armazenar saldo em Sensei Coins, (ii) emitir Requisição de Recebimento, Envio ou transferência, (iii) receber em sua Carteira Virtual Sensei Coins provenientes de Requisições de Recebimento, (iv) enviar seu saldo de Sensei Coins para plataformas de Poker ou e-Wallets por meio de Requisições de Envio e (v) Transferir Sensei Coins para outro usuário da Sensei Wallet por meio de Requisições de Transferência.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.3. O Usuário reconhece e concorda expressamente que a inserção de seu login e senha nos campos indicados na Plataforma constituem uma legítima e suficiente forma para a comprovação da identidade do Usuário e da validade de sua declaração de vontade em contratar os Serviços e transmitir as Requisições de Recebimento, Requisições de Envio, Requisições de Transferência e quaisquer outras instruções no âmbito da Plataforma.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.4. Carteira Virtual: Após conclusão do Cadastro pelo Usuário, é criada automaticamente para o Usuário uma Carteira Virtual de armazenamento de Sensei Coins, de titularidade do Usuário, mantida em seu nome, que poderá ser acessada, consultada e transacionada através do uso de seu login e senha.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.5. Limites: O Sensei Poker poderá utilizar determinados parâmetros próprios para estabelecer determinados limites, tais como, mas não se limitando a, quantidade de transações de Sensei Coins e de valores transferidos e armazenados na Conta Arrecadadora.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.5.1. O Sensei Poker poderá, a seu exclusivo critério, aumentar e diminuir os limites inicialmente estipulados, solicitando as informações e documentações necessárias.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.6. Da Utilização dos Serviços: As transações realizadas entre os Usuários são de inteira responsabilidade dos Usuários.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.6.1. O Usuário se compromete a não utilizar os Serviços para fins diretos ou indiretos de (i) infringir qualquer lei, regulamento, estes Termos de Uso, nem praticar atos contrários à moral e aos bons costumes; (ii) praticar lavagem de dinheiro; e/ou (iii) financiar atividades e/ou organizações que envolvam terrorismo, crime organizado, tráfico de drogas, pessoas e/ou órgãos humanos. Neste caso, a conta será encerrada, sem ônus ou multa, sem a necessidade de especificar o motivo, inclusive podendo ser por desinteresse comercial, sem a necessidade de qualquer aviso prévio.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.6.2. O Usuário declara-se ciente que o Sensei Poker comunicará às salas de poker ou e-wallets as Transações que possam ser consideradas como crimes de lavagem ou ocultação de bens, direitos e valores. Ademais, o Sensei Poker poderá informar os dados cadastrais do Usuário às Autoridades Públicas, para atendimento de normas regulamentares e/ou requisições judiciais.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>15.6.3. Como condição para a utilização dos Serviços, o Usuário entende e reconhece que as transações de compra e venda de Sensei Coins são realizadas por sua conta e risco e que o Sensei Poker atua exclusivamente como mantenedor da Plataforma para que os Usuários transacionem entre si. Em nenhum momento o Sensei Poker poderá ser considerado responsável por quaisquer perdas, danos, prejuízos e/ou lucros cessantes que possam eventualmente ser sofridos pelos Usuários devido às transações de recebimento/envio/transferência realizadas ou não através da Plataforma.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>16. SUPORTE TÉCNICO</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.1. A prestação do serviço de suporte técnico limitar-se-á a esclarecimentos acerca do funcionamento da Plataforma e sua operação, sendo certo que o Usuário, ao aceitar os termos previstos nestes Termos de Uso, declara possuir o conhecimento mínimo do uso do computador e/ou dispositivo móvel e suas funções, bem como do sistema operacional sob o qual o sistema será utilizado. O Usuário declara, ainda, possuir a configuração adequada em seu computador e/ou dispositivo móvel no que se refere à utilização da Plataforma e o bom estado de funcionamento do referido computador/dispositivo móvel.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.2. O suporte técnico ao Usuário se dará dos canais de atendimento do Sensei (Telegram, Instagram e email), por meio de troca de informações, cabendo ao Sensei Poker fornecer uma resposta em até 5 (cinco) dias úteis, contadas do chamado realizado pelo Usuário, não estando incluído neste serviço, o atendimento in loco.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.3. O suporte técnico estará disponível nos dias úteis, no horário de 10h às 19h.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.4. O suporte técnico fornecido pelo Sensei Poker não abrange a conexão com a internet, rede interna e computadores/dispositivos móveis do Usuário.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>16.5. O Sensei Poker procura assegurar que as informações fornecidas sejam precisas, completas, atuais e que o seu uso não tenha interrupções nem erros. No entanto, não podemos garantir a operação da Plataforma em tempo integral, na medida em que esta depende de serviços prestados por terceiros, como empresas de telecomunicações e provedores de acesso à internet. Além disso, suspenderemos o uso e acesso à Plataforma em caso fortuito ou força maior, por exemplo, em caso de ataques cibernéticos e outros que possam prejudicar as funcionalidades da Plataforma e colocar em risco as informações dos Usuários.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>17. CONSIDERAÇÕES FINAIS</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.1. A incapacidade ou a mera liberalidade em não fazer cumprir quaisquer disposições destes Termos de Uso não constitui renúncia de direitos, podendo ser exigido o seu cumprimento em qualquer momento.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.2 Caso qualquer disposição destes Termos de Uso for considerada inexequível ou inválida, essa disposição será isoladamente invalidada e não atingirá o restante das disposições destes Termos de Uso.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.3. Estes Termos de Uso e a relação entre as Partes serão regidos pelas leis da República Federativa do Brasil. As Partes elegem o Foro da Comarca de Curitiba como sendo o único competente para dirimir quaisquer litígios e/ou demandas que venham a envolver as Partes em relação aos Serviços.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.4.  A tolerância de uma Parte com a outra quanto ao descumprimento de qualquer uma das obrigações assumidas nestes Termos de Uso não implicará em novação ou renúncia de direito. A Parte tolerante poderá, a qualquer tempo, exigir da outra Parte o fiel e cabal cumprimento destes Termos de Uso.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.5. A inviabilidade da execução ou invalidade, ainda que em virtude de decisão judicial, de alguma cláusula, não afetará a validade das demais cláusulas destes Termos de Uso, que permanecerão válidos e exequíveis conforme seus termos.</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>17.6. O Sensei Poker se reserva o direito de modificar o presente Termos de Uso a qualquer tempo, sendo o Usuário comunicado de tais alterações, mediante o aceite das novas disposições.</Typography>
          <Typography variant='body1' style={{fontWeight:'bold'}}>18. LEI APLICÁVEL</Typography>
          <Typography variant='caption' style={{fontWeight:'bold'}}>18.1.  Esses Termos de Uso estão sujeitos a lei brasileira e jurisdição dos tribunais brasileiros.</Typography>
        </div>
      ),
    }

    return (
      text[lang]
    )
  }
}

export default TermsOfUseText
