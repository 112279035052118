import { routes } from './core/api';
import moment from 'moment'
import Big from 'big.js'
import { Auth as AuthCognito } from 'aws-amplify';


export const createTransfer = (transfer) => {
  return {
      type: 'CREATE_TRANSFER',
      transfer
  }
}

export const getTransfers = (transfers) => {
  return {
    type: 'GET_TRANSFERS',
    transfers
  }
}

export const getRecentTransfers = (recent_transfers) => {
  return {
    type: 'GET_RECENT_TRANSFERS',
    recent_transfers
  }
}

export const updateTransferStatus = (transfer) => {
  return {
    type: 'UPDATE_TRANSFER_STATUS',
    transfer
  }
}


export function updateTransferStatusFetchData(id, action) {
  
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.update+id+`/${action}`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            }
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Transferência não atualizada!');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(updateTransferStatus(body))
          resolve(body)
        })
      })
    })
  }
}

export function getRecentTransfersFetchData({user, status}) {
  let qs = '';
  if(user) {
    qs = `&origin_user=${user}`
  }
  if(status) {
    qs += `&status=${status}`
  }

  // qs+='&sort=created_at'

  let route = routes.get+`?limit=3${qs}`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            },
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Invalid credentials');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(getRecentTransfers(body))
          resolve(body)
        })
      })
    })
  }
}

export function getTransfersFetchData({page = 1, query = {}}) {

  let qs = '?';
  if(page) {
    qs += 'page='+page
  }

  delete query.platform

  if(query.user!=='all') {
    query.origin_user = query.user
  }
  delete query.user
  if(query.status==='all') {
    delete query.status
  }

  query.startDate = moment(query.startDate).toISOString()
  query.endDate = moment(query.endDate).toISOString()
  var queryString = Object.keys(query).map((key) => {
      if(key && query[key]) return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
      return ''
  }).join('&');

  let route = routes.get+qs;
  if(queryString.length) {
    route += `&${queryString}`
  }
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            },
          
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Invalid credentials');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(getTransfers(body))
          resolve(body)
        })
      })
    })
  }
}

export function createTransferFetchData(body) {
  let transfer = {
    ...body,
    value: Number(Big(body.value).times(100))
  }

  return dispatch => {
    return new Promise((resolve, reject)=>{
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.create, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            },
          body: JSON.stringify(transfer)
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Transferência não realizada!');
            
           }
          return res.json() 
        })
        .then(body => {
          dispatch(createTransfer(body))
          resolve(body)
          
        })
      })
    })
  }
}

export function createAdminTransferFetchData(body) {
  let transfer = {
    ...body,
    value: Number(body.value) * 100
  }
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.adminCreate, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            },
          body: JSON.stringify(transfer)
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Transferência não realizada!');
           }
          return res.json() 
        })
        .then(body => {
          resolve(body)
        })
      })
    })
  }
}