class Auth2 {

    static authenticateUser(user) {
        // localStorage.setItem('token', user.apiToken)
        localStorage.setItem('role', user.role)
    }
  
    static isUserAuthenticated() {
        return localStorage.getItem('token') !== null
    }
  
    static deauthenticateUser() {
        // localStorage.removeItem('token')
        localStorage.removeItem('role')
    }
  
    static getToken() {
        return localStorage.getItem('token')
    }

    static getRole() {
        return localStorage.getItem('role')
    }

    static setRole(role) {
        return localStorage.setItem('role', role)
    }
    
    static eraseRole() {
        return localStorage.removeItem('role')
    }

    static storeInfoForValidation(sub, email) {
        localStorage.setItem('userSub', sub)
        localStorage.setItem('validationEmail', email)
    }

    static getValidationEmail() {
        return localStorage.getItem('validationEmail')
    }
  }
  
  export default Auth2
  