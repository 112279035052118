
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { TableContainer, TableBody, TableCell, TableRow, TableHead, Table, Typography } from '@material-ui/core'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import StatusWidget from '../common/StatusWidgetMini'
import translations from '../../translations/translations'
import TimeCountWidget from '../common/TimeCountWidget'


class TransferList extends Component {

  render() {

    const { transfers = [], lang, me } = this.props;
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><b>{translations[lang].date}</b></TableCell>
              <TableCell className="hidden-xs"><b>{translations[lang].origin}</b></TableCell>
              <TableCell className="hidden-xs" align="left"><b>{translations[lang].destiny}</b></TableCell>
              <TableCell align="right"><b>{translations[lang].value}</b></TableCell>
              <TableCell align="right"><b>{translations[lang].status}</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transfers.length ? transfers.map(transfer => (
              <TableRow key={transfer._id} onClick={() => { this.props.toggleShow({ ...transfer, type: 'TRANSFER' }) }}>
                <TableCell>
                  <Typography variant="body1" style={{ fontSize: 12 }}><b>{moment(transfer.created_at).format('DD/MM/YY')}</b></Typography>
                  {
                    transfer.status === 'PENDING' && me.role === 'ADMIN' ? (
                      <TimeCountWidget
                        hours={
                          moment(new Date()).diff(moment(transfer.created_at), 'hours')
                        }
                      />
                    ) : (
                      <Typography variant="body1" style={{ fontSize: 12 }}>{moment(transfer.created_at).format('HH:mm')}</Typography>
                    )
                  }
                </TableCell>
                <TableCell className="hidden-xs">{transfer.origin_user_cognito ? transfer.origin_user_cognito.email : ''}</TableCell>
                <TableCell className="hidden-xs" align="left">{transfer.destination_user_cognito ? transfer.destination_user_cognito.email : ''}</TableCell>
                <TableCell align="right">

                  {me.role === 'ADMIN' ?
                    <NumberFormat
                      value={transfer.value / 100}
                      displayType={'text'}
                      thousandSeparator="."
                      decimalSeparator=","
                      fixedDecimalScale="true"
                      decimalScale={2}
                    />
                    :
                    <NumberFormat
                      style={{ color: transfer.origin_user.cognito_id === me.cognito_id ? 'red' : 'green' }}
                      value={transfer.value / 100}
                      displayType={'text'}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={transfer.origin_user.cognito_id === me.cognito_id ? '-' : '+' }
                      fixedDecimalScale="true"
                      decimalScale={2}
                    />}
                </TableCell>
                <TableCell align="right">
                  <StatusWidget lang={lang} status={transfer.status} />
                </TableCell>
              </TableRow>
            )) : <TableRow>
              <TableCell>{translations[lang].no_results}</TableCell>
            </TableRow>}
          </TableBody>
        </Table>



      </TableContainer>
    )
  }
}

const mapStateToProps = ({ auth, me, lang }) => {
  return {
    auth,
    me,
    lang
  }
}



export default withRouter(connect(mapStateToProps)(TransferList))

