import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Card, CardContent, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { getFiltersFetchData } from '../users/actions';



class SafeFilters extends Component {

  handleStartDateChange = (date) => {
    const filters = {
      ...this.props.filters,
      startDate: moment(date).utc().toDate()
    }
    this.props.getFilters(filters);
    this.props.setFilters(filters);
  };

  handleEndDateChange = (date) => {
    const filters = {
      ...this.props.filters,
      endDate: date
    }
    this.props.getFilters(filters);
    this.props.setFilters(filters);
  };

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    const filters = {
      ...this.props.filters,
      [field]: value
    };
    this.props.getFilters(filters);
    this.props.setFilters(filters);
  }

  render() {
    const { platforms, filters } = this.props;
    const { platform='all', startDate, endDate } = filters
    return (
      <Card>
        <CardContent>
          <Grid container spacing={3} >
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  id="date-picker-dialog"
                  label="Início"
                  format="dd/MM/yyyy"
                  value={moment(startDate).utc(false).toDate()}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    margin="normal"
                    id="date-picker-dialog"
                    label="Fim"
                    format="dd/MM/yyyy"
                    value={moment(endDate).utc(false).toDate()}
                    onChange={this.handleEndDateChange}
                  />
                </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {
                platforms ?
                (<Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="platform-label">Platforma</InputLabel>
                    <Select
                    labelId="platform-label"
                    id="platfrom"
                    name="platform"
                    value={platform}
                    onChange={this.handleChange}
                    >
                      <MenuItem value="all">Todos</MenuItem>
                      {
                      platforms.length ? platforms.map(platform => <MenuItem key={platform._id} value={platform._id}>{platform.name}</MenuItem>) : null
                      }
                    </Select>
                  </FormControl>
                </Grid>)
                : null
              }
          </Grid>
        </CardContent>
      </Card>
      )
  }
}


const mapStateToProps = ({ auth, filters}) => {
  return {
    auth,
    filters
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFilters: (filters) => dispatch(getFiltersFetchData(filters)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SafeFilters)