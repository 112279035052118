
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { TableContainer, TableBody, TableCell, TableRow, TableHead, Table, Typography } from '@material-ui/core'
import moment from 'moment'
import NumberFormat from 'react-number-format'

import StatusWidget from './StatusWidgetMini'
import translations from '../../translations/translations'
import TimeCountWidget from './TimeCountWidget'

class BasicList extends Component {

  render() {
    const { docs, type, lang, me } = this.props;
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><b>{translations[lang].date}</b></TableCell>
              {
                me && me.role === 'ADMIN' ?
                  <TableCell align="left"><b>Usuário</b></TableCell>
                  : null
              }
              <TableCell className="hidden-xs" align="center"><b>ID</b></TableCell>
              <TableCell align="center"><b>{translations[lang].platform}</b></TableCell>
              <TableCell align="right"><b>{translations[lang].value}</b></TableCell>
              {me && me.role === 'ADMIN' && type === 'SALE' ?
                <TableCell align="right"><b>{translations[lang].tax}</b></TableCell>
                : null}
              <TableCell align="right"><b>{translations[lang].status}</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.length ? docs.map(doc => (
              <TableRow key={doc._id} style={{ cursor: "pointer" }} onClick={() => { this.props.toggleShow({ ...doc, type }) }}>
                <TableCell>
                  <Typography variant="body1" style={{ fontSize: 12 }}><b>{moment(doc.created_at).format('DD/MM/YY')}</b></Typography>
                  {
                    doc.status === 'PENDING' && this.props.me.role==='ADMIN' ? (
                        <TimeCountWidget
                          hours = {
                            moment(new Date()).diff(moment(doc.created_at), 'hours')
                          }
                        />
                    ) : (
                      <Typography variant="body1" style={{ fontSize: 12 }}>{moment(doc.created_at).format('HH:mm')}</Typography>
                    )
                  }
                </TableCell>
                {
                  me && me.role === 'ADMIN' ?
                    <TableCell align="left">{doc.user_cognito.email}</TableCell>
                    : null
                }
                <TableCell className="hidden-xs" align="center">{doc.identifier || doc.nickname}</TableCell>
                <TableCell align="center">{doc.platform.name}</TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={doc.value / 100}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    fixedDecimalScale="true"
                    decimalScale={2}
                  />
                </TableCell>
                { me && me.role === 'ADMIN' && type === 'SALE' ?
                  <TableCell align="right">
                    <NumberFormat
                      value={doc.fee / 100 || 0}
                      displayType={'text'}
                      thousandSeparator="."
                      decimalSeparator=","
                      fixedDecimalScale="true"
                      decimalScale={2}
                    />
                  </TableCell>
                  : null}

                <TableCell align="right">
                  <StatusWidget lang={lang} status={doc.status} />
                </TableCell>
              </TableRow>
            )) : <TableRow>
                <TableCell>{translations[lang].no_results}</TableCell>
              </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

const mapStateToProps = ({ auth, lang, me }) => {
  return {
    auth,
    lang,
    me
  }
}



export default withRouter(connect(mapStateToProps)(BasicList))

