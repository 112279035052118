import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import UserBar from '../auth/UserBar'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Card, Typography, CardContent } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import UserCreate from './UserCreate';
import { getUsersFetchData, createPlatformRules } from './actions'
import Pagination from '@material-ui/lab/Pagination';
import UserList from './UserList'

class Users extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  state = {
    anchorEl: null,
    query: {
      nickname: '',
      email: ''
    }
  }

  getUsers({ page, query }) {
    this.props.getUsers({ page, query })
  }

  componentDidMount() {
    this.getUsers({ page: this.state.page, query: this.state.query })
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name

    this.setState((state) => ({ page: 1, query: { ...state.query, [field]: value } }))
    if(value.length > 2 || value.length < 1) {
      this.getUsers({ page: this.state.page, query: { ...this.state.query, [field]: value } })
    }
  }

  toggleCreate = (createType) => {
    this.setState((state) => {
      if(state.openCreate) {
        this.getUsers({})
      }
      return ({ openCreate: !state.openCreate })
    })
    
    if(['USER'].includes(createType)) {
      this.setState((state) => ({ createType }))
    }
  }

  handleCreate = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handlePaginate = (event, value) => {
    this.getUsers({ page: value })
  }

  createRules = () => {
    this.props.createPlatformRules()
  }

  render() {
    const { auth, users } = this.props
    const { docs = [], totalPages = 1 } = users
    const { openCreate, createType, query } = this.state
    const { email } = query
    if(Object.keys(auth).length === 0){
      this.props.history.push('/login')
    }

    const SideCreate = ({ open, close }) => {
      const form = {
        'USER': { title: 'Criar usuário', component: <UserCreate closeCreate={close} /> }
      }

      if (!createType) return null

      return (
        <Drawer open={open} onClose={close} anchor='top' >
          <Container style={{ padding: "16px 0"}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">{form[createType]['title']}</Typography>
              </Grid>
              <Grid item xs={12}>
                {
                  form[createType]['component']
                }
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      )
    }

    return (
      <div>
        <UserBar auth={auth} />
        <SideCreate open={openCreate} close={this.toggleCreate}/>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h5' style={{ marginTop: 8 }}>Usuários</Typography>
            </Grid>
            <Grid item xs={6}>
            <TextField fullWidth id="email-field" label="E-mail" variant="filled" name="email" onChange={this.handleChange} value={email}/>
            </Grid>
            <Grid item xs={12}>
              <List>
                <ListItem button onClick={() => this.toggleCreate('USER')}>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Criar usuário" />
                </ListItem>
                <Divider />
              </List>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                      <Grid item xs={12}>
                        <UserList docs={docs} />
                      </Grid>
                      <Grid item style={{ textAlign: 'center' }} xs={12}>
                        <div>
                          <Pagination style={{ margin: '16px auto', display: 'inline-block' }} count={totalPages} color="primary" onChange={this.handlePaginate} />
                        </div>
                      </Grid>
                    </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, users }) => {
  return {
    auth,
    users
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (users) => dispatch(getUsersFetchData(users)),
    createPlatformRules: () => dispatch(createPlatformRules()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)