import React, { Component } from 'react'
import { AppBar, Box, Grid, Button, ButtonGroup, Container, Drawer, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-scroll'
import { Link as LinkRouter } from 'react-router-dom'


const SideMenu = ({ open, close, lang }) => {
  return (
    <Drawer open={open} onClose={close} style={{ paddingRight: 16 }}>
      <List style={{ minWidth: 300 }}>
        <div style={{ textAlign: 'center', padding: 8, marginBottom: 8 }}>
          <img src={process.env.PUBLIC_URL + "/senseiwallet-vertical.png"} alt="sensei wallet" style={{ maxWidth: '90%', height: 80 }} />
        </div>
        <Divider />
        <Link to="sobre" offset={-60} onClick={close}>
          <ListItem button>
            <ListItemText primary='O que é?' />
          </ListItem>
        </Link>
        <Link to="como-funciona" offset={-60} onClick={close}>
          <ListItem button>
            <ListItemText primary='Como funciona' />
          </ListItem>
        </Link>
        <Link to="beneficios" offset={-60} onClick={close}>
          <ListItem button>
            <ListItemText primary='Benefícios' />
          </ListItem>
        </Link>
        <LinkRouter style={{ color: '#f50057' }} to="/login">
          <ListItem button>
              <ListItemText primary='Entrar' />
            </ListItem>
        </LinkRouter>
      </List>
    </Drawer>
  )
}


class BasicHeader extends Component {

  state = {
    user: {},
    isUserInfoOpen: false,
    menuOpen: false
  }

  headerStyle = {
    height: 60,
    background: '#fff',
    color: '#18191F',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '2px 2px 8px #9e9e9e'
  }

  logoStyle = {
    marginLeft: '16px',
    width: '120px',
    marginTop: 12
  }

  contentStyle = {
    marginTop: '2px'
  }

  authInfofStyle = {
    background: 'white',
    color: '#444',
    borderRadius: 0
  }

  accordionStyle = {
    borderRadius: 0,
    dropShadow: '2px 2px 2px #444',
    color: '#444'
  }

  usernameStyle = {
    fontSize: '12px',
    fontWeight: '700',
    color: '#444',
    marginBottom: '0'
  }

  menuIconStyle = {
    color: 'black',
    display: 'block',
    fontSize: '24px',
    height: '100%'
  }

  handleOpenUserInfo() {
    this.setState((state) => ({
      isUserInfoOpen: !this.state.isUserInfoOpen
    }))
  }

  handleCloseMenu = () => {
    this.setState({ menuOpen: false })
  }

  handleOpenMenu = () => {
    this.setState({ menuOpen: true })
  }

  toggleMenu = () => {
    this.setState((state) => ({ menuOpen: !state.menuOpen }))
  }


  render() {
    const { menuOpen } = this.state;
    return (
      <AppBar style={{ position: 'fixed', width: '100%', zIndex: '1300' }}>
        <header style={this.headerStyle}>
          <Box component="div" display={{ xs: 'inline-flex', sm: 'none' }} onClick={this.handleOpenMenu}>
            <div style={{ background: 'white', padding: '10px' }}>
              <MenuIcon style={this.menuIconStyle} />
            </div>
            <div style={this.contentStyle}>
              <img style={this.logoStyle} alt="logo" src={process.env.PUBLIC_URL + '/logo-preta-wallet.png'} />
            </div>
          </Box>
          <Box component="div" display={{ xs: 'none', sm: 'inline-flex' }} style={{ width: '100%' }}>
            <Container>
              <Grid container alignItems="center" justify="space-between" spacing={6}>
                <Grid item xs={4} style={{ textAlign: 'left' }}>
                  <img style={this.logoStyle} alt="logo" src={process.env.PUBLIC_URL + '/logo-preta-wallet.png'} />
                </Grid>
                <Grid item xs={8} style={{ textAlign: 'right' }}>
                  <ButtonGroup style={{ color: '#18191F', marginTop: 8 }} variant="text" >
                    <Button style={{ padding: '8px 25px' }}><Link to="sobre" offset={-60}>O que é?</Link></Button>
                    <Button style={{ padding: '8px 25px' }}><Link to="como-funciona" offset={-60}>Como funciona</Link></Button>
                    <Button style={{ padding: '8px 25px' }}><Link to="beneficios" offset={-60}>Benefícios</Link></Button>
                    <Button color="secondary" style={{ padding: '8px 25px', color: 'red' }}><LinkRouter style={{ color: '#f50057' }} to="/login">Entrar</LinkRouter></Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </header>
        <SideMenu open={menuOpen} close={this.toggleMenu} />
      </AppBar>
    )
  }
}


export default BasicHeader