import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import UserBar from '../auth/UserBar'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import { Card, Typography, CardContent } from '@material-ui/core';
import { getTransactionsFetchData } from '../transactions/actions'
import TransactionList from '../transactions/TransactionList'
import NumberFormat from 'react-number-format'
import SideView from '../common/SideView';
import SenseiCoin from '../common/SenseiCoin';
import Pagination from '@material-ui/lab/Pagination';
import translations from '../../translations/translations'

class Wallet extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  state = {
    anchorEl: null,
  }

  componentDidMount() {
    this.props.getTransactions({})
  }

  toggleShow = (document) => {
    this.setState((state) => ({ openShow: !state.openShow, document }))
  }

  handlePaginate = (event, value) => {
    const { transactions } = this.props
    const { page } = transactions
    this.props.getTransactions({ page: value || page })
  }


  render() {
    const { auth, balance, transactions, lang } = this.props
    const { docs = [], totalPages = 1, page = 1 } = transactions
    const { openShow, document } = this.state
    
    if(Object.keys(auth).length === 0){
      this.props.history.push('/login')
    }

    return (
      <div>
        <UserBar auth={auth} />
        <SideView open={openShow} close={this.toggleShow} document={document}/>

        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h5' style={{ marginTop: 8 }}>{translations[lang].wallet}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ background: '#e8e8e8', borderRadius: "5px 5px 0 0", padding: 8, fontSize: 18, textAlign: 'center', borderBottom: '1px solid #c62828',
            borderImageSlice: 1,
            borderWidth: 2,
            borderImageSource: 'linear-gradient(to left, #FE2241, #5D0000)' }}>
                <Typography variant="caption">{translations[lang].balance}</Typography>
                <Typography variant="body1">
                  <b><SenseiCoin /><NumberFormat 
                      value={balance/100} 
                      displayType={'text'} 
                      thousandSeparator="."
                      decimalSeparator=","
                      fixedDecimalScale="true"
                      decimalScale={2}
                      /></b>
                </Typography>
              </Card> 
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <TransactionList docs={docs} toggleShow={this.toggleShow} />
                    </Grid>
                    <Grid item style={{ textAlign: 'center' }} xs={12}>
                      <div>
                        <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={page} count={totalPages} color="primary" onChange={this.handlePaginate} />
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, transactions, balance, lang }) => {
  return {
    auth,
    transactions,
    balance,
    lang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransactions: (sale) => dispatch(getTransactionsFetchData(sale))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wallet)