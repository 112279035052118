
const baseApi = `${process.env.REACT_APP_API_URL}/users`
const baseApiRules = `${process.env.REACT_APP_API_URL}/admin/platforms_rules/`
const baseApiPlatformRules = `${process.env.REACT_APP_API_URL}/platforms_rules`
const baseApiBalance = `${process.env.REACT_APP_API_URL}/balance`
const baseApiMe = `${process.env.REACT_APP_API_URL}/me`


export const routes = {
  get: `${baseApi}`,
  getCognito: `${baseApi}/cognito`,
  getRules: baseApiRules,
  getPlatformRules: baseApiPlatformRules,
  resetSaleLimit: `${baseApiPlatformRules}/reset`,
  resetAllSaleLimit: `${baseApiPlatformRules}/reset_all`,
  me: `${baseApiMe}/`,
  create: `${baseApi}/`,
  createCognito: `${baseApi}/cognito`,
  getBalance: `${baseApiBalance}/`,
  changePassword: `${baseApi}/change_password/`,
  changeMyPassword: `${baseApiMe}/change_my_password/`,
  createPlatformRules: `${baseApi}/create_rules/`,
  documentVerification: `${baseApi}/jumio_initiate/`
} 

