        
import React, { Component } from 'react'
import { createPurchaseFetchData, adminCreatePurchaseFetchData } from './actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import BasicForm from '../common/BasicForm';
import _ from 'lodash'

class PurchaseCreate extends Component {

  state = { 
    isFetching: false,
    didInvalidate: false,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center'
  }

  createPurchase({ platform, value, identifier, message, dollar_price, user}) {
    this.setState((state) => ({
      isFetching: true,
      didInvalidate: false,
    }))

    if(this.props.me.role === 'ADMIN'){
      this.props.adminCreatePurchase({ platform, value, identifier, message, dollar_price, user})
      .then(body => {
        this.setState((state) => ({
          isFetching: false,
          didInvalidate: false,
        }));
        this.props.closeCreate('PURCHASE', true);
      })
      .catch(ex => {
        this.setState((state) => ({
          isFetching: false,
          didInvalidate: true,
          snackbarOpen: true,
          snackMessage: ex,
        }))
      })
      
    } else if(this.props.me.role ==='USER'){

      this.props.createPurchase({ platform, value, identifier, message, dollar_price})
      .then(body => {
        this.setState((state) => ({
          isFetching: false,
          didInvalidate: false,
        }));
        this.props.closeCreate('PURCHASE', true);
      })
      .catch(ex => {
        this.setState((state) => ({
          isFetching: false,
          didInvalidate: true,
          snackbarOpen: true,
          snackMessage: ex,
        }))
      })
    }

  }


  render() {
    const { isFetching, vertical, horizontal, snackbarOpen, snackMessage } = this.state
    const { platforms = [], rules, lang, me } = this.props;

    let plat = []
    let filtered_platforms = platforms.docs

    if(me.role ==='USER'){
      plat = _.filter(rules, (rule) => rule.purchase === false)
      plat = plat.map(p => p.platform)

      filtered_platforms = _.filter(platforms, (p) => !plat.includes(p._id) )
    }

    return (
        <div>
            <BasicForm
              type="PURCHASE" 
              handleSubmit={(data) => this.createPurchase(data)}
              platforms={filtered_platforms}
              isFetching={isFetching}
              lang={lang}
            />
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              key={`${vertical},${horizontal}`}
              open={snackbarOpen}
              onClose={this.handleCloseSnackbar}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
            message={<span id="message-id">{snackMessage}</span>}    />
        </div>
    )
  }
}

const mapStateToProps = ({ me, auth, rules, lang }) => {
  return {
    me,
    auth,
    rules,
    lang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getSaleFetchData: () => dispatch(getSaleFetchData()),
    createPurchase: (purchase) => dispatch(createPurchaseFetchData(purchase)),
    adminCreatePurchase: (purchase) => dispatch(adminCreatePurchaseFetchData(purchase))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseCreate))

      