
const baseApi = `${process.env.REACT_APP_API_URL}/login`
const baseApiAgree = `${process.env.REACT_APP_API_URL}/agree-terms`

export const getToken = () => {
  let token = localStorage.getItem('token')
  if (!token) return ''

  return token
}

export const setToken = () => {
  let token = localStorage.getItem('token')
  if (!token) return ''

  return token
}

export const headers = {
  'Content-Type': 'application/json',
}

export const routes = {
  signIn: `${baseApi}/`,
  agreeTerms: `${baseApiAgree}/`
} 

export const sessionAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true
    setTimeout(cb, 100) // fake async
  },
  signout(cb) {
    this.isAuthenticated = false
    setTimeout(cb, 100)
  }
}