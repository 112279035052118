import React, { Component } from 'react'
import { connect } from 'react-redux'
import UserBar from '../auth/UserBar'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Card, Typography, CardContent } from '@material-ui/core';
import DollarPriceCreate from './DollarPriceCreate';
import { getDollarPricesFetchData, createDollarPrice } from './actions'
import Pagination from '@material-ui/lab/Pagination';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import DollarPriceList from './DollarPriceList';


class DollarPrices extends Component {

  state = {
    page: 1,
    anchorEl: null,
    document,
  }

  getDollarPrices({ page, query }) {
    this.props.getDollarPrices({ page, query })
  }

  componentDidMount() {
    this.getDollarPrices({ page: this.state.page, query: this.state.query })
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name

    this.setState((state) => ({ page: 1, query: { ...state.query, [field]: value } }))
    if(value.length > 2 || value.length < 1) {
      this.getDollarPrices({ page: this.state.page, query: { ...this.state.query, [field]: value } })
    }
  }

  toggleShow = (document) => {
    this.setState((state) => (
      { openShow: !state.openShow, document }))
  }

  toggleCreate = (createType) => {
    this.setState((state) => {
      if(state.openCreate) {
        this.getDollarPrices({})
      }
      return ({ openCreate: !state.openCreate })
    })
    if(['DOLLAR_PRICE'].includes(createType)) {
      this.setState((state) => ({ createType }))
    }
  }

  handleCreate = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handlePaginate = (event, value) => {
    this.getUsers({ page: value })
  }

  render() {
    const { auth, dollarPrices } = this.props
    const { docs = [], totalPages = 1 } = dollarPrices
    const { openCreate, createType } = this.state
    if(Object.keys(auth).length === 0){
      this.props.history.push('/login')
    }

    const SideCreate = ({ open, close }) => {
      const form = {
        'DOLLAR_PRICE': { title: 'Novo preço do dólar', component: <DollarPriceCreate closeCreate={close} /> }
      }

      if (!createType) return null

      return (
        <Drawer open={open} onClose={close} anchor='top' >
          <Container style={{ padding: "16px 0"}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">{form[createType]['title']}</Typography>
              </Grid>
              <Grid item xs={12}>
                {
                  form[createType]['component']
                }
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      )
    }

    return (
      <div>
        <UserBar auth={auth} />
        <SideCreate open={openCreate} close={this.toggleCreate}/>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h5' style={{ marginTop: 8 }}>Preços do Dolar</Typography>
            </Grid>
            {/* <Grid item xs={6}>
            <TextField fullWidth id="nickname-field" label="Nickname" name="nickname" variant="filled" onChange={this.handleChange} value={nickname}/>
            </Grid>
            <Grid item xs={6}>
            <TextField fullWidth id="email-field" label="E-mail" variant="filled" name="email" onChange={this.handleChange} value={email}/>
            </Grid> */}
            <Grid item xs={12}>
              <List>
                <ListItem button onClick={() => this.toggleCreate('DOLLAR_PRICE')}>
                  <ListItemIcon>
                    <SmsFailedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Novo Preço do Dólar" />
                </ListItem>
                <Divider />
              </List>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                      <Grid item xs={12}>
                        <DollarPriceList toggleShow={this.toggleShow} docs={docs} />
                      </Grid>
                      <Grid item style={{ textAlign: 'center' }} xs={12}>
                        <div>
                          <Pagination style={{ margin: '16px auto', display: 'inline-block' }} count={totalPages} color="primary" onChange={this.handlePaginate} />
                        </div>
                      </Grid>
                    </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, dollarPrices }) => {
  return {
    auth,
    dollarPrices
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDollarPrices: (data) => dispatch(getDollarPricesFetchData(data)),
    createDollarPrice: () => dispatch(createDollarPrice()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DollarPrices)