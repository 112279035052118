        
import React, { Component } from 'react'
import { createSafeTransactionFetchData } from './actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import SafeTransactionForm from './SafeTransactionForm';


class SafeTransactionCreate extends Component {

  state = { 
    isFetching: false,
    didInvalidate: false,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center'
  }

  createSafeTransaction({ platform, type, value }) {
    this.setState((state) => ({
      isFetching: true,
      didInvalidate: false,
    }))

    this.props.createSafeTransaction({ platform, type, value })
    .then(body => {
      console.log({ body })
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: false,
      }));
      this.props.closeCreate('SUCCESS');
    })
    .catch(ex => {
      console.log({ ex });
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: true,
        snackbarOpen: true,
        snackMessage: 'Error',
      }))
    })
  }


  render() {
    const { isFetching, vertical, horizontal, snackbarOpen, snackMessage } = this.state
    const { platforms = [], type } = this.props

    return (
        <div>
            <SafeTransactionForm 
              handleSubmit={(data) => this.createSafeTransaction(data)}
              platforms={platforms}
              type={type}
              isFetching={isFetching}
            />
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              key={`${vertical},${horizontal}`}
              open={snackbarOpen}
              onClose={this.handleCloseSnackbar}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
            message={<span id="message-id">{snackMessage}</span>}    />
        </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getSaleFetchData: () => dispatch(getSaleFetchData()),
    createSafeTransaction: (safe_transaction) => dispatch(createSafeTransactionFetchData(safe_transaction))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SafeTransactionCreate))

      