        
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { TableContainer, TableBody, TableCell, TableRow, TableHead, Table } from '@material-ui/core'
import moment from 'moment'

class NoticeList extends Component {

  render() {
    const { docs } = this.props;
    const types = {
      'NOTICE': {type: 'Comunicado', style: {color:'#d27d13'}},
      'WARNING': {type: 'Alerta', style: {color:'#ca1a1a'}},
      'NEWS': {type: 'Novidade', style: {color:'#167b1e'}}
    }
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Cadastrado em</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Mensagem</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { docs.length ? docs.map(doc => (
                <TableRow onClick={() => { this.props.toggleShow({ ...doc }) }} key={doc._id} style={{ cursor: 'pointer' }}>
                  <TableCell >{moment(doc.created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
                  <TableCell style={{...types[doc.type].style, fontWeight:'bold'}}>{types[doc.type].type}</TableCell>
                  <TableCell>{doc.message.substring(0, 100)+' (...)'}</TableCell>
                </TableRow>
            )) : <TableRow>
            <TableCell>Não há registros.</TableCell>
            </TableRow> }
        </TableBody>
      </Table>
    </TableContainer>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth
  }
}

export default withRouter(connect(mapStateToProps)(NoticeList))

      