import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import UserBar from '../auth/UserBar'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import { Drawer, Button, Card, Typography, CardContent } from '@material-ui/core';
import TransformIcon from '@material-ui/icons/Transform';
import TransferCreate from './TransferCreate';
import AdminTransferCreate from './AdminTransferCreate';
import TransferList from './TransferList';
import { getTransfersFetchData } from './actions'
import Pagination from '@material-ui/lab/Pagination';
import { getUsersFetchData, getFiltersFetchData } from '../users/actions';
import SideView from '../common/SideView';
import _ from 'lodash'
import moment from 'moment'
import BasicFilters from '../common/BasicFilters';
import translations from '../../translations/translations';


class Transfers extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  state = {
    anchorEl: null,
  }

  toggleCreate = (createType) => {
    this.setState((state) => {
      if(state.openCreate) {
        this.handlePaginate()
      }
      return ({ openCreate: !state.openCreate })
    })
    if(['TRANSFER', 'ADMINTRANSFER'].includes(createType)) {
      this.setState((state) => ({ createType }))
    }
  }
  
  toggleShow = (document) => {
    this.setState((state) => ({ openShow: !state.openShow, document }))
  }

  componentDidMount() {
    const search = this.parseQueryParams()
    const filters = {
      startDate: moment().subtract(7, 'days').toDate(),
      endDate: moment().toDate(),
      platform: 'all',
      user: 'all',
      status: 'all'
    }

    if(search) {
      this.setFilters({
        ...filters,
        ...search
      })
    }
    
  }

  getTransfers({ page, query }) {
    this.props.getTransfers({ page, query })
  }

  getUsers() {
    this.props.getUsers({})
  }

  handlePaginate = (event, value) => {
    const { transfers } = this.props
    const { page } = transfers
    this.getTransfers({ page: value || page, query: this.props.filters })
  }

  parseQueryParams() {
    return _.chain(this.props.location.search)
    .replace('?', '') // a=b454&c=dhjjh&f=g6hksdfjlksd
    .split('&') // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
    .map(_.partial(_.split, _, '=', 2)) // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
    .fromPairs() // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
    .value()
  }

  setFilters(filters) {
    const { startDate, endDate } = filters;
    if(startDate) {
      filters.startDate = moment(startDate, 'DD/MM/YYYY').utc().toDate()
    }
    if(endDate) {
      filters.endDate = moment(endDate, 'DD/MM/YYYY').utc().toDate()
    }

    this.props.getFilters(({
      ...this.props.filters,
      ...filters
    }))

    this.handlePaginate()
    
  }

  updateTransfer = (document) => {
    this.setState((state) => ({ openShow: false }))
    this.handlePaginate()
  }

  handleCreate = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { auth, transfers, users, filters, lang, me } = this.props
    const { docs = [], totalPages = 1, page = 1 } = transfers;
    const { openCreate, createType, openShow, document } = this.state
    if(Object.keys(auth).length === 0){
      this.props.history.push('/login')
    }

    const SideCreate = ({ open, close }) => {
      const form = {
        'TRANSFER': { title: translations[lang].transfer_create, component: <TransferCreate closeCreate={close} users={users}/> },
        'ADMINTRANSFER': { title: translations[lang].transfer_create, component: <AdminTransferCreate closeCreate={close} users={users}/> },
      }

      if (!createType) return null

      return (
        <Drawer open={open} onClose={close} anchor='top' >
          <Container style={{ padding: "16px 8px"}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">{form[createType]['title']}</Typography>
                <Typography variant="body1">{translations[lang].transactions_info}</Typography>
              </Grid>
              <Grid item xs={12}>
                {
                  form[createType]['component']
                }
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      )
    }

    return (
      <div>
        <UserBar auth={auth} />
        <SideCreate open={openCreate} close={this.toggleCreate}/>
        <SideView open={openShow} close={this.toggleShow} document={document} updated={this.updateTransfer}/>

        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h5' style={{ marginTop: 8 }}>{translations[lang].transfers}</Typography>
            </Grid>
            {
              auth && ['ADMIN','USER'].includes(me.role)  ?
                <Grid item xs={12}>
                   <Button onClick={() => this.toggleCreate(me.role === "ADMIN" ? 'ADMINTRANSFER' : 'TRANSFER')} variant="contained" color="primary" size="large" fullWidth>
                      <TransformIcon style={{ fontSize: 16, marginRight: 8 }} />
                      <Typography variant="body1">{translations[lang].transfer_action}</Typography>
                    </Button>
                </Grid>
              : null
            }
            <Grid item xs={12}>
              <BasicFilters users={me.role === 'ADMIN' ? users.docs || users : null}  filters={filters} setFilters={(query) => { this.getTransfers({ page: 1, query }) }}/>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                      <Grid item xs={12}>
                        <TransferList transfers={docs} toggleShow={this.toggleShow} />
                      </Grid>
                      <Grid item style={{ textAlign: 'center' }} xs={12}>
                        <div>
                          <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={page} count={totalPages} color="primary" onChange={this.handlePaginate} />
                        </div>
                      </Grid>
                    </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, transfers, users, filters, lang, me}) => {
  return {
    auth,
    me,
    transfers,
    users,
    filters,
    lang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransfers: (transfer) => dispatch(getTransfersFetchData(transfer)),
    getUsers: (user) => dispatch(getUsersFetchData(user)),
    getFilters: (filters) => dispatch(getFiltersFetchData(filters))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transfers)