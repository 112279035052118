import React from 'react'
import { Typography, Grid, Container } from '@material-ui/core';

export default function RedCard(props) {
    return (
        <div style={{ width: "230px", height: "320px", background: "linear-gradient(0deg, rgba(103,3,3,1) 0%, rgba(163,9,8,1) 100%)", borderRadius: "20px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", marginTop: "32px" }}>
            <Container style={{ paddingTop: "28px" }}>
                <Grid container alignItems="center" >
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <img src={props.img} alt={props.alt}></img>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ textTransform: 'uppercase', textAlign: 'center', fontSize: "22px", fontWeight: 700, color: "#FFFFFF" }} >{props.action}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ textAlign: 'center', fontSize: "15px", color: "#FFFFFF", marginTop: "16px" }} >{props.text}</Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
  }