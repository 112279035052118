import React from 'react';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const Flag = ({ code }) => {
    const images = {
        br: './BR.png',
        us: './US.png',
        ar: './AR.png'
    }
    return (<img style={{width: '32px'}} alt="Flag" src={images[code]} />)
}


export default function SelectLang({ lang, changeLang }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const langText = {
    ar: 'ES-AR',
    br: 'PT-BR',
    us: 'EN-US'
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeLang = (lang) => {
    changeLang(lang)
    setAnchorEl(null);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <Hidden only="xs"><span style={{ color: 'white', marginRight: 8 }}>{langText[lang]}</span></Hidden>
        <Flag code={lang} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
          
        <MenuItem onClick={() => handleChangeLang('br')}>
            <Flag
                code="br"
                />
        </MenuItem>
        <MenuItem onClick={() => handleChangeLang('us')}>
        <Flag
                code="us"
                />
        </MenuItem>
        <MenuItem onClick={() => handleChangeLang('ar')}>
        <Flag
                code="ar"
                />
        </MenuItem>
      </Menu>
    </div>
  );
}