import React, { Component } from 'react'
import { Button, Dialog, Typography } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css'
import translations from '../../translations/translations';


class RegistrationConfirmed extends Component {
  render() {
    const { isOpen, handleClose, lang } = this.props

    return (
      <Dialog open={isOpen} onClose={handleClose} PaperProps={{style:{padding: 16, textAlign:'center', width:'400px'}}}>
        <Typography variant='h5' style={{marginBottom: 16}} >
            {translations[lang].account_confirmed}
        </Typography>
        <Typography variant='body1' padding='0 20px'>{translations[lang].confirmed_info}</Typography>
        <Button 
            variant="contained"
            fullWidth
            color="primary"
            type="button"
            margin="normal"
            style={{ marginTop: 16 }}
            onClick={handleClose}
          >
            <b>OK</b>
          </Button>
      </Dialog>
    )
  }
}


export default RegistrationConfirmed
