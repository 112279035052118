import { routes } from './core/api';
import moment from 'moment';
import axios from 'axios';
import Big from 'big.js';
import { Auth as AuthCognito } from 'aws-amplify';



export const getSales = (sales) => {
  return {
      type: 'GET_SALES',
      sales
  }
}

export const getRecentSales = (recent_sales) => {
  return {
      type: 'GET_RECENT_SALES',
      recent_sales
  }
}

export const getSale = (sale) => {
  return {
      type: 'GET_SALE',
      sale
  }
}

export const createSale = (sale) => {
  return {
      type: 'CREATE_SALE',
      sale
  }
}

export const updateSaleStatus = (sale) => {
  return {
    type: 'UPDATE_SALE_STATUS',
    sale
  }
}

export const uploadReceipt = (file) => {
  let formData = new FormData()
  formData.append('file', file)
  formData.append('category', 'transfers_receipt')
  return new Promise((resolve, reject)=>{
      axios.post(
        process.env.REACT_APP_UPLOAD_FILE_URL,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      .then(res=>{
        resolve(res)
      })
      .catch(err=>{
        reject(err)
      })
  })
}

export function updateSaleStatusFetchData(id, action, body) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.update+id+`/${action}`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            },
          body: ['approve','decline'].includes(action) ? JSON.stringify({ ...body }) : null
        })
        .then(res => { 
          if(res.status !== 200) {
            reject('Envio não atualizado!');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(updateSaleStatus(body))
          resolve(body)
        })
      })
    })
  }
}


export function getSaleFetchData(sale_id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.get+sale_id, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            }
        })
        .then(res => res.json())
        .then(body => {
          let sale = body
          dispatch(getSale(sale))
          resolve(body)
        })
      })
    })
  }
}

export function getReportFetchData(query) {

  let qs = '?';
  
  if(query.platform==='all') {
    delete query.platform
  }
  if(query.user==='all') {
    delete query.user
  }
  if(query.status==='all') {
    delete query.status
  }

  query.startDate = moment(query.startDate).toISOString()
  query.endDate = moment(query.endDate).toISOString()

 
  var queryString = Object.keys(query).map((key) => {
      if(key && query[key]) return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
      return ''
  }).join('&');

  let route = routes.report+qs;
  if(queryString.length) {
    route += `&${queryString}`
  }

  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            }
        })
        .then(res => res.json())
        .then(body => {
          resolve(body)
        })
      })
    })
  }
}


export function getRecentSalesFetchData({user, status}) {
  let qs = '';
  if(user) {
    qs = `&user=${user}`
  }
  if(status) {
    qs += `&status=${status}`
  }

  // qs+='&sort=created_at'

  let route = routes.get+`?limit=3${qs}`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Invalid credentials');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(getRecentSales(body))
          resolve(body)
        })
      })
    })
  }
}

  
export function getSalesFetchData({page = 1, query = {}}) {

  let qs = '?';
  if(page) {
    qs += 'page='+page
  }

  if(query.platform==='all') {
    delete query.platform
  }
  if(query.user==='all') {
    delete query.user
  }
  if(query.status==='all') {
    delete query.status
  }

  query.startDate = moment(query.startDate).toISOString()
  query.endDate = moment(query.endDate).toISOString()

 
  var queryString = Object.keys(query).map((key) => {
      if(key && query[key]) return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
      return ''
  }).join('&');

  let route = routes.get+qs;
  if(queryString.length) {
    route += `&${queryString}`
  }
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
          
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Invalid credentials');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(getSales(body))
          resolve(body)
        })
      })
    })
  }
}

export function createSaleFetchData(body) {
  let sale = {
    ...body,
    value: Big(body.value).times(100).toNumber()
  }
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.create, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            },
          body: JSON.stringify(sale)
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Envio não realizado! Atualize a tela e tente novamente.');
            
           }
          return res.json() 
        })
        .then(body => {
          dispatch(createSale(body))
          resolve(body)
        })
      })
    })
  }
}

export function adminCreateSaleFetchData(body) {
  let sale = {
    ...body,
    value: Big(body.value).times(100).toNumber()
  }
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.adminCreate, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            },
          body: JSON.stringify(sale)
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Envio não realizado! Atualize a tela e tente novamente.');
            
           }
          return res.json() 
        })
        .then(body => {
          dispatch(createSale(body))
          resolve(body)
        })
      })
    })
  }
}