import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import UserBar from '../auth/UserBar'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import { Button, Card, Typography, CardContent, Snackbar, TableContainer } from '@material-ui/core';
import BasicList from '../common/BasicList'
import TransferList from '../transfers/TransferList'
import SideView from '../common/SideView'
import { getRecentPurchasesFetchData } from '../purchases/actions'
import { getRecentTransfersFetchData } from '../transfers/actions'
import { getRecentSalesFetchData } from '../sales/actions'
import { getTransactionsFetchData } from '../transactions/actions'
import TransactionList from '../transactions/TransactionList'
import Pagination from '@material-ui/lab/Pagination';

import { getPlatformsRulesFetchData, getUserFetchData, getUserBalanceFetchData, changeUserPasswordFetchData, upsertRuleFetchData } from './actions';
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormGroup, FormControlLabel, Checkbox, InputLabel, Select, FormControl, MenuItem, TextField, Table, TableCell, TableHead, TableRow, TableBody } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
class User extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  state = {
    document: '',
    new_password: '',
    openShow: false,
    isFetching: false,
    changePassword: false,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center',
    platformRule: '',
    platformSale: true,
    platformPurchase: true,
    platformSaleLimit: 0,
    rules: [],
    rulesForm: false
  }

  handleCloseSnackbar = () => {
    this.setState((state) => ({ ...state, snackbarOpen: false }));
  };


  toggleShow = (document) => {
    this.setState((state) => ({ openShow: !state.openShow, document }))
  }

  toggleChangePassword = () => {
    this.setState((state) => ({ changePassword: !state.changePassword }))
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  handleChangeCheckbox = (event) => {
    const value = event.target.checked
    const field = event.target.name
    this.setState({ [field]: value })
  }


  handleSubmit = () => {
    this.setState({isFetching: true})
    this.props.changeUserPassword({ new_password: this.state.new_password, email: this.props.user.email })
      .then((c) => {
        this.setState((state) => ({
          isFetching: false,
          openShow: false,
          snackMessage: 'Senha alterada com sucesso!',
          snackbarOpen: true,
          changePassword: false,
          new_password: ''
        }))
      })
  }

  handleSubmitRule = () => {
    this.props.upsertRule(this.props.match.params.id, { platform: this.state.platformRule, purchase: this.state.platformPurchase, sale: this.state.platformSale, sale_limit: Number(this.state.platformSaleLimit) * 100 })
      .then((c) => {
        this.props.getPlatformsRules(this.props.match.params.id).then(rules => this.setState((state) => ({ rules, rulesForm: false })))
      })
  }

  updatedAction = (document) => {
    this.setState((state) => ({ openShow: false }))
    this.loadTables()
  }

  loadTables = () => {
    this.props.getRecentSales({ user: this.props.match.params.id, status: 'PENDING' })
    this.props.getRecentPurchases({ user: this.props.match.params.id, status: 'PENDING' })
    this.props.getRecentTransfers({ user: this.props.match.params.id, status: 'PENDING' })
  }

  componentDidMount() {
    this.loadTables()
    this.props.getPlatformsRules(this.props.match.params.id).then(rules => this.setState((state) => ({ rules })))
    this.props.getUser(this.props.match.params.id)
    this.props.getUserBalance(this.props.match.params.id)
    this.props.getTransactions({ query: { user: this.props.match.params.id } })
  }

  handlePaginate = (e, page) => {
    this.props.getTransactions({ page, query: { user: this.props.match.params.id } })
  }

  updateRule = (rule) => {
    this.setState((state) => ({ 
      platformRule: rule.platform._id,
      platformSale: rule.sale,
      platformPurchase: rule.purchase,
      platformSaleLimit: rule.sale_limit / 100,
      rulesForm: true
    }))
  }

  render() {
    const { auth, me, balance, recent_transfers, recent_sales, recent_purchases, user, transactions, platforms } = this.props
    const { document, openShow, changePassword, new_password, isFetching, vertical, horizontal, snackbarOpen, snackMessage, rules, rulesForm, platformSaleLimit } = this.state
    const { docs = [], totalPages = 1, page = 1 } = transactions

    if(Object.keys(auth).length === 0){
      this.props.history.push('/login')
    }

    return (
      <div>
        <UserBar auth={auth} />
        <SideView open={openShow} close={this.toggleShow} document={document} updated={this.updatedAction} />
        <Container>
          <Grid container spacing={3} style={{ paddingTop: 16 }}>
            <Grid item xs={4}>
              <Typography variant="caption">Nickname</Typography>
              <Typography>{user.nickname}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">Nome</Typography>
              <Typography>{user.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">E-mail</Typography>
              <Typography>{user.email}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">Telefone</Typography>
              <Typography>
                <a href={'https://api.whatsapp.com/send?phone=' + user.phone_number} target="_blank" rel="noopener noreferrer" style={{
                  color: '#3f50b6',
                  textDecoration: 'none',
                  fontWeight: 600
                }}>
                  <WhatsAppIcon /> {user.phone_number}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">Saldo</Typography>
              <Typography>
                <NumberFormat
                  value={balance / 100}
                  displayType={'text'}
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale="true"
                  decimalScale={2}
                />
              </Typography>
            </Grid>

            {
              me.role === 'ADMIN' ? 
                <Grid item xs={4}>
                  <Button size="small" color="primary" variant="contained" onClick={this.toggleChangePassword}>{changePassword ? 'Cancelar' : 'Senha Provisória'}</Button>
                </Grid>
              : null
            }
            
            {
              changePassword ?
                <Grid item xs={12}>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}

                  >
                    <Grid container spacing={4}>
                      <Grid item xs={8}>
                        <TextValidator
                          fullWidth
                          label="Nova Senha"
                          variant="filled"
                          onChange={this.handleChange}
                          name="new_password"
                          value={new_password}
                          validators={['required']}
                          errorMessages={['Campo obrigatório']}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          type="submit"
                          disabled={isFetching}
                          style={{ height: 55 }}
                        >
                          {isFetching && <CircularProgress size={20} />} Salvar
                        </Button>
                      </Grid>
                    </Grid>

                  </ValidatorForm>
                </Grid>
                : null
            }

            {
              me.role === 'ADMIN' ?
                <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6}><Typography variant="caption">Configuração das plataformas</Typography></Grid>
                      <Grid item xs={6} align="right">
                        <Button size="small" color="secondary" variant="contained" onClick={() => this.setState((state) => ({ rulesForm: !state.rulesForm }))}>
                          {!rulesForm ? 'Adicionar/Alterar restrições' : 'Fechar formulário de restrições'}
                        </Button>
                      </Grid>
                      {rulesForm ?
                        <div>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Plataforma</InputLabel>
                              <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.platformRule}
                                name='platformRule'
                                onChange={this.handleChange}
                              >
                                {
                                  platforms.docs ? platforms.docs.map(platform => <MenuItem key={platform._id} value={platform._id}>{platform.name}</MenuItem>) : null
                                }
  
  
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox checked={this.state[`platformSale`] || false} onChange={this.handleChangeCheckbox} name={`platformSale`} />}
                                label="Envio"
                              />
                              <FormControlLabel
                                control={<Checkbox checked={this.state['platformPurchase'] || false} onChange={this.handleChangeCheckbox} name={`platformPurchase`} />}
                                label="Recebimento"
                              />
                            </FormGroup>
                            <TextField
                              fullWidth
                              label="Limite Atual"
                              variant="filled"
                              onChange={this.handleChange}
                              name="platformSaleLimit"
                              value={platformSaleLimit}
                              validators={['required']}
                              style={{ marginBottom: 8 }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button size="small" color="secondary" variant="contained" onClick={this.handleSubmitRule}>Salvar</Button>
                          </Grid></div>
                        : null}
  
                      <Grid container>
                          <TableContainer>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Nome</TableCell>
                                  <TableCell>Envio</TableCell>
                                  <TableCell>Recebimento</TableCell>
                                  <TableCell>Limite Atual</TableCell>
                                  <TableCell>Editar</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                              {rules.length === 0 ?   <TableRow><TableCell>Não existem restrições para esse usuário</TableCell></TableRow> :
                                rules.map(rule => (
                                  <TableRow key={rule._id}>
                                    <TableCell><b>{rule.platform.name}</b></TableCell>
                                    <TableCell><b>{rule.sale ? <CheckCircleIcon /> : <CancelIcon />}</b></TableCell>
                                    <TableCell><b>{rule.purchase ? <CheckCircleIcon /> : <CancelIcon />}</b></TableCell>
                                    <TableCell><b><NumberFormat displayType='text' decimalScale={2} decimalSeparator=',' thousandSeparator='.' fixedDecimalScale={true} value={(rule.sale_limit / 100)}/></b></TableCell>
                                    <TableCell onClick={() => this.updateRule(rule)} ><EditIcon style={{ cursor: 'pointer' }}/></TableCell>
                                  </TableRow>))
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                        
                      </Grid>
  
  
                     
  
  
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              : null
            }
            <Grid item xs={6}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={6}><Typography variant="caption">Envios pendentes</Typography></Grid>
                    <Grid item xs={6} align="right">
                      <Link style={{ textDecoration: 'none' }} to={`/admin/envios?user=${user._id}`}>
                        <Button size="small" color="primary" variant="contained">Ver todas</Button>
                      </Link>
                    </Grid>
                  </Grid>
                  <BasicList docs={recent_sales.docs || []} toggleShow={this.toggleShow} type="SALE" />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardContent>
                  <Grid container alignContent="flex-end">
                    <Grid item xs={6}><Typography variant="caption">Recebimentos pendentes</Typography></Grid>
                    <Grid item xs={6} align="right">
                      <Link style={{ textDecoration: 'none' }} to={`/admin/recebimentos?user=${user._id}`}>
                        <Button size="small" color="primary" variant="contained">Ver todas</Button>
                      </Link>
                    </Grid>
                  </Grid>
                  <BasicList docs={recent_purchases.docs || []} toggleShow={this.toggleShow} type="PURCHASE" />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={6}><Typography variant="caption">Transferências pendentes</Typography></Grid>
                    <Grid item xs={6} align="right">
                      <Link style={{ textDecoration: 'none' }} to={`/admin/transferencias?user=${user._id}`}>
                        <Button size="small" color="primary" variant="contained">Ver todas</Button>
                      </Link>
                    </Grid>

                  </Grid>
                  <TransferList transfers={recent_transfers.docs || []} toggleShow={this.toggleShow} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {
            user.role === 'USER' && me.role === 'ADMIN' ?
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant='h5' style={{ marginTop: 8 }}>Carteira</Typography>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ background: '#eee', borderRadius: 5, padding: 8, fontSize: 18, textAlign: 'center' }}>
                    <Typography variant="caption">Saldo</Typography>
                    <Typography>
                      <NumberFormat
                        value={balance / 100}
                        displayType={'text'}
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale="true"
                      />
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <TransactionList docs={docs} toggleShow={this.toggleShow} />
                        </Grid>
                        <Grid item style={{ textAlign: 'center' }} xs={12}>
                          <div>
                            <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={page} count={totalPages} color="primary" onChange={this.handlePaginate} />
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              : null
          }

          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            key={`${vertical},${horizontal}`}
            open={snackbarOpen}
            onClose={this.handleCloseSnackbar}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{snackMessage}</span>} />
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ balance, auth, me, user, recent_sales, recent_purchases, recent_transfers, platforms, transactions }) => {
  return {
    auth,
    me,
    user,
    balance,
    recent_sales,
    recent_purchases,
    recent_transfers,
    platforms,
    transactions
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (user) => dispatch(getUserFetchData(user)),
    getRecentSales: (user) => dispatch(getRecentSalesFetchData(user)),
    getRecentTransfers: (user) => dispatch(getRecentTransfersFetchData(user)),
    getRecentPurchases: (user) => dispatch(getRecentPurchasesFetchData(user)),
    getPlatformsRules: (user) => dispatch(getPlatformsRulesFetchData(user)),
    getUserBalance: (user) => dispatch(getUserBalanceFetchData(user)),
    getTransactions: (user) => dispatch(getTransactionsFetchData(user)),
    changeUserPassword: (new_password, email) => dispatch(changeUserPasswordFetchData(new_password, email)),
    upsertRule: (user, rule) => dispatch(upsertRuleFetchData(user, rule))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(User)