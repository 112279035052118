import React from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Grid, Container, Typography, BottomNavigationAction, BottomNavigation } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import UserBar from '../auth/UserBar';
import {connect} from 'react-redux'
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import SendIcon from '@material-ui/icons/Send';
import TransformIcon from '@material-ui/icons/Transform';
import ReactPlayer from 'react-player';

const FAQ = React.forwardRef((props, ref) => {

  const SupportLink = () => (
      <Grid container alignItems="center" justify="flex-start" style={{color:'blue'}}>
        <Grid item>
          <HelpIcon style={{ fontSize: 16, marginRight: 8 }} /> 
        </Grid>
        <Grid item>
          <Typography variant="caption">Esta resposta não te ajudou? Entre em contato com o Suporte pelo chat no canto inferior direito da página</Typography>
        </Grid>
      </Grid>)
  return (
    <div className="faq" ref={ref}>
      <UserBar auth={props.auth} />
      <Container>
        <div style={{margin:'32px 0'}}>
          <Typography variant='h5'>Ajuda</Typography>
          <Typography variant='body1'>Procure pelo seu problema nas nossas perguntas frequentes ou <span><b>fale conosco pelo chat no canto inferior direito da página</b></span></Typography>
        </div>
        <Grid item xs={12} style={{fontSize: 12, fontWeight: 600}}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{fontWeight: 600}}><span>1.</span> Como funciona?</Typography>
            </AccordionSummary>
            <AccordionDetails style={{display: 'block'}}>
              <Typography style={{ marginBottom: 16 }}>
              A Sensei Wallet funciona como uma carteira intermediária para gestão de bankroll e fácil movimentação de dinheiro entre diferentes plataformas: salas de poker, e-wallets, etc. Você pode colocar saldo na sua carteira requisitando um RECEBIMENTO; pode enviar o saldo da sua carteira para uma sala de poker ou e-wallet requisitando um ENVIO; e pode transferir para outro usuário da Sensei Wallet cadastrando uma TRANSFERÊNCIA. Veja no vídeo abaixo uma demonstração visual do uso da ferramenta!
              </Typography>
              <div style={{display:'flex', justifyContent:'center'}}>
                <ReactPlayer controls={true} url='https://vimeo.com/514525067' />
              </div>
              <SupportLink />
              <div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography style={{fontWeight: 600}}><span>2.</span> Como coloco dinheiro na minha carteira?</Typography>
            </AccordionSummary>
            <AccordionDetails style={{display: 'block'}}>
              <Typography style={{ marginBottom: 16 }}>
                Você insere saldo na sua carteira requisitando um RECEBIMENTO. Ao clicar no botão de "Receber" no seu dashboard, selecione a plataforma por onde quer nos transferir o dinheiro para receber o saldo na sua carteira, e o identificador da conta que fará essa transferência. No próprio formulário, aparecerá o identificador do Sensei na plataforma escolhida. Você usará este identificador para transferir o dinheiro. Uma vez cadastrado o recebimento, ele ficará pendente até que a equipe do Sensei confirme que o dinheiro transferido por você caiu na conta. Uma vez que o recebimento seja confirmado, você conseguirá ver o saldo na sua carteira. O saldo da sua carteira será representado em Sensei Coins. Cada Sensei Coin equivale a U$1,00. O prazo para a aprovação de transações, via de regra, é de até 4h durante horário comercial. Porém, algumas plataformas demoram um pouco mais para concretizar o envio do dinheiro, o que pode acarretar atraso no tempo de aprovação.
              </Typography>
              <SupportLink />
              <div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography style={{fontWeight: 600}}><span>3.</span> Como retiro dinheiro da minha carteira?</Typography>
            </AccordionSummary>
            <AccordionDetails style={{display: 'block'}}>
              <Typography style={{ marginBottom: 16 }}>
                Você retira saldo na sua carteira requisitando um ENVIO. Ao clicar no botão de "Enviar" no seu dashboard, selecione a plataforma e o identificador da conta para onde quer enviar o dinheiro. Uma vez cadastrado o envio, ele ficará pendente até que a equipe do Sensei concretize o envio. Uma vez que o envio seja confirmado, o saldo será descontado da sua carteira e nós disponibilizaremos o comprovante da transação. O prazo para a aprovação de transações, via de regra, é de até 4h durante horário comercial. Porém, algumas plataformas demoram um pouco mais para concretizar o envio do dinheiro, o que pode acarretar eventuais atrasos.
              </Typography>
              <SupportLink />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography style={{fontWeight: 600}}><span>4.</span> Posso transferir dinheiro para outro usuário?</Typography>
            </AccordionSummary>
            <AccordionDetails style={{display: 'block'}}>
              <Typography style={{ marginBottom: 16 }}>
                Você pode transferir Sensei Coins para outro usuário da Sensei Wallet requisitando uma TRANSFERÊNCIA pelo seu dashboard. Basta informar o valor e o identificador do usuário para quem você quer transferir. 
              </Typography>
              <SupportLink />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography style={{fontWeight: 600}}><span>5.</span> O que é a Sensei Coin?</Typography>
            </AccordionSummary>
            <AccordionDetails style={{display: 'block'}}>
              <Typography style={{ marginBottom: 16 }}>
                Todo o seu saldo nas plataformas do Sensei é calculado em Sensei Coins. Cada Sensei Coin equivale a U$ 1,00. Então, sempre que for necessário para alguma transação, o valor do seu saldo em Sensei Coins será convertido com base nessa cotação.
              </Typography>
              <SupportLink />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Container>
      {
          props.me.role === 'USER' ?
            <BottomNavigation
              style={{
                width: '100%',
                position: 'fixed',
                bottom: 0,
                background: '#101010',
                borderTop: '2px solid',
                borderImageSlice: 1,
                borderWidth: 4,
                borderImageSource: 'linear-gradient(to left, #FE2241, #5D0000)'
              }}
              value={"Home"}
              onChange={(event, newValue) => {
                const type = ['SALE', 'PURCHASE', 'TRANSFER'];
                this.toggleCreate(type[newValue])
              }}
              showLabels
            >
              <BottomNavigationAction style={{ color: '#fff' }} label="Enviar" icon={<SendIcon />} />
              <BottomNavigationAction style={{ color: '#fff' }} label="Receber" icon={<CallReceivedIcon />} />
              <BottomNavigationAction style={{ color: '#fff' }} label="Transferir" icon={<TransformIcon />} />
            </BottomNavigation>
            : null
        }
    </div>
  )
})

const mapStateToProps = ({auth,me}) =>{
  return({
    auth,
    me
  })
}


export default connect(mapStateToProps)(FAQ)