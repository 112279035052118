
import Auth2 from '../../../utils/auth';
const baseApi = `${process.env.REACT_APP_API_URL}/platform-transactions`
const baseApiResume = `${process.env.REACT_APP_API_URL}/admin/resume`
const baseApiUsersBalance = `${process.env.REACT_APP_API_URL}/admin/users/balance`
const baseApiBalance = `${process.env.REACT_APP_API_URL}/admin/balance`


export const headers = () => { 
  const token = Auth2.getToken()
  return new Headers({
    'Content-Type': 'application/json',
    'Authorization': token
  }) 
}

export const routes = {
  get: `${baseApi}/`,
  getResume: `${baseApiResume}/`,
  create: `${baseApi}/manual`,
  getBalance: `${baseApiBalance}/`,
  getUsersBalance: `${baseApiUsersBalance}/`
} 

