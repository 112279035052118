import React, { Component } from 'react'
import { Drawer, Container, Grid, Typography, Button, Snackbar, TextField } from '@material-ui/core'
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateTransferStatusFetchData } from '../transfers/actions';
import { deleteNotice } from '../notices/actions';


const styles = theme => ({
  main: {
    color: "#CCC",
    height: '15px'
  },
  label: {
    fontSize: '12px'
  },
  input: {
    fontSize: '15px'
  },
  fileInput: {
    fontSize: '10px',
    textTransform: 'none',
    background: 0,
    boxShadow: 'none',
    textDecoration: 'underline',
    padding: 0,
    "&:hover": {
      boxShadow: 'none',
      background: 0
    }
  }
})

class SideViewNotice extends Component {

  state = {
    receipt: '',
    comment: '',
    fee: '',
    confirmDeleteOpen: false,
    checkDelete: '',
    anchorEl: null,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center',
    isUploading: false,
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  handleDeleteNotice = () => {
    const { document } = this.props
    const {checkDelete} = this.state
    
    if(checkDelete.toLowerCase() === 'apagar'){
      deleteNotice(document._id)
      .then(r=>{
        this.setState({
          confirmDeleteOpen: false,
          checkDelete: ''
        })
        this.props.getNotices({})
        this.props.close()
      })
      .catch(err=>{
        this.setState({
          snackbarOpen: true,
          snackMessage: err.message
        })
      })
    } else {
      this.setState({
        snackbarOpen: true,
        snackMessage: 'Texto de confirmação não confere'
      })
    }
  }

  handleCloseSnackbar = () => {
    this.setState((state) => ({ ...state, snackbarOpen: false }));
  };


  render() {
      const { open, close, document = {}} = this.props;
      const { vertical, horizontal, snackMessage, snackbarOpen, checkDelete } = this.state;

      const types = {
        'NOTICE': {type: 'Comunicado', style: {color:'#d27d13'}},
        'WARNING': {type: 'Alerta', style: {color:'#ca1a1a'}},
        'NEWS': {type: 'Novidade', style: {color:'#167b1e'}}
      }

      return (
          <Drawer variant='temporary' style={{width:'50%'}} open={open} onClose={close} anchor='right' >
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              key={`${vertical},${horizontal}`}
              open={snackbarOpen}
              onClose={this.handleCloseSnackbar}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
            message={<span id="message-id">{snackMessage}</span>}    />
            <Container style={{ padding: "16px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">Visualizar</Typography>
                </Grid>
                {
                  document ? (
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="caption">Data</Typography>
                        <Typography variant="body1">{moment(document.created_at).format('DD/MM/YYYY HH:mm')}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">Tipo</Typography>
                        <Typography style={document.type && {...types[document.type].style, fontWeight:'bold'}} variant="body1">
                          {document.type && types[document.type].type}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">Mensagem</Typography>
                        <Typography variant="body1">
                          {document.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {
                          !this.state.confirmDeleteOpen ? (
                            <Button variant="contained" fullWidth onClick={() => { this.setState({confirmDeleteOpen: true})} } color="primary"><b>Apagar Aviso</b></Button>
                          ) : (
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <TextField
                                  onChange={this.handleChange}
                                  label='Escreva "apagar" para confirmar'
                                  fullWidth
                                  variant='outlined'
                                  name="checkDelete"
                                  value={checkDelete}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button variant="contained" fullWidth onClick={() => { this.handleDeleteNotice()} } color="primary"><b>Confirmar Apagar</b></Button>
                              </Grid>
                            </Grid>
                          )
                        }
                      </Grid>

                     
                      </Grid>
                    </Grid>
                  ) : null
                }
                
              </Grid>
            </Container>
          </Drawer>
        )
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    updateTransferStatus: (id, action) => dispatch(updateTransferStatusFetchData(id, action)),
  }
}


export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(SideViewNotice)))