        
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Grid, Card, Typography, CardContent, CardActions, Divider } from '@material-ui/core'
import NumberFormat from 'react-number-format'

class ResumeWidgets extends Component {

  render() {
    const { resume } = this.props;
    return (
        <Grid container spacing={3}>
            {
                resume.length ? 
                    resume.map(platform => (
                        <Grid item xs={6} key={platform._id}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5">{platform.name}</Typography>
                                    <Divider style={{ margin: '8px 0 '}}/>
                                    <Typography variant="body2">
                                        Saldo Atual: <b><NumberFormat 
                                                            value={platform.balance/100} 
                                                            displayType={'text'} 
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            fixedDecimalScale="true"
                                                            decimalScale={2}
                                                            /></b>
                                    </Typography>
                                    <Typography variant="body2">
                                        Envios Pendentes: <b><NumberFormat 
                                                                value={platform.pending_sales/100} 
                                                                displayType={'text'} 
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                fixedDecimalScale="true"
                                                                decimalScale={2}
                                                                /></b>
                                    </Typography>
                                    <Typography variant="body2">
                                        Recebimentos Pendentes: <b><NumberFormat 
                                                                value={platform.pending_purchases/100} 
                                                                displayType={'text'} 
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                fixedDecimalScale="true"
                                                                decimalScale={2}
                                                                /></b>
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ textAlign: 'center', backgroundColor: '#3f51b5', color: 'white' }}>
                                    
                                    <Typography variant="body2" style={{ margin: '0 auto' }}>
                                        Saldo Esperado: <b><NumberFormat 
                                                                value={platform.projection_balance/100} 
                                                                displayType={'text'} 
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                fixedDecimalScale="true"
                                                                decimalScale={2}
                                                                /></b>
                                    </Typography>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                : null
            }
            
        </Grid>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  }
}



export default withRouter(connect(mapStateToProps)(ResumeWidgets))

      