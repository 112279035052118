        
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { TableContainer, TableBody, TableCell, TableRow, TableHead, Table } from '@material-ui/core'
import moment from 'moment'

class UserList extends Component {

  render() {
    const { docs, history } = this.props;
    
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Cadastrado em</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Telefone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { docs.length ? docs.map(doc => (
                <TableRow onClick={() => {
                  history.push(`/admin/usuarios/${doc.id}`)
                }} key={doc._id} style={{ cursor: 'pointer' }}>
                  <TableCell>{moment(doc.created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
                  <TableCell>{doc.name}</TableCell>
                  <TableCell>{doc.email}</TableCell>
                  <TableCell>{doc.phone_number}</TableCell>
                </TableRow>
            )) : <TableRow>
            <TableCell>Não há registros.</TableCell>
            </TableRow> }
        </TableBody>
      </Table>
    </TableContainer>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth
  }
}

export default withRouter(connect(mapStateToProps)(UserList))

      