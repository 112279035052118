import React, { Component } from 'react'
import { connect } from 'react-redux'
import UserBar from '../auth/UserBar'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Card, Typography, CardContent } from '@material-ui/core';
import NoticeCreate from './NoticeCreate';
import { getNoticesFetchData, createNotice } from './actions'
import Pagination from '@material-ui/lab/Pagination';
import NoticeList from './NoticeList'
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import SideViewNotice from '../common/SideViewNotice';


class Notices extends Component {

  state = {
    page: 1,
    anchorEl: null,
    document,
    // query: {
    //   nickname: '',
    //   email: ''
    // }
  }

  getNotices({ page, query }) {
    this.props.getNotices({ page, query })
  }

  componentDidMount() {
    this.getNotices({ page: this.state.page, query: this.state.query })
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name

    this.setState((state) => ({ page: 1, query: { ...state.query, [field]: value } }))
    if(value.length > 2 || value.length < 1) {
      this.getNotices({ page: this.state.page, query: { ...this.state.query, [field]: value } })
    }
  }

  toggleShow = (document) => {
    this.setState((state) => (
      { openShow: !state.openShow, document }))
  }

  toggleCreate = (createType) => {
    this.setState((state) => {
      if(state.openCreate) {
        this.getNotices({})
      }
      return ({ openCreate: !state.openCreate })
    })
    
    if(['NOTICE'].includes(createType)) {
      this.setState((state) => ({ createType }))
    }
  }

  handleCreate = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handlePaginate = (event, value) => {
    this.getNotices({ page: value })
  }

  createRules = () => {
    this.props.createPlatformRules()
  }

  render() {
    const { auth, notices } = this.props
    const { docs = [], totalPages = 1 } = notices
    const { openCreate, createType, openShow, document } = this.state
    // const { nickname, email } = query
    if(Object.keys(auth).length === 0){
      this.props.history.push('/login')
    }

    const SideCreate = ({ open, close }) => {
      const form = {
        'NOTICE': { title: 'Criar Aviso', component: <NoticeCreate closeCreate={close} /> }
      }

      if (!createType) return null

      return (
        <Drawer open={open} onClose={close} anchor='top' >
          <Container style={{ padding: "16px 0"}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">{form[createType]['title']}</Typography>
              </Grid>
              <Grid item xs={12}>
                {
                  form[createType]['component']
                }
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      )
    }

    return (
      <div>
        <UserBar auth={auth} />
        <SideCreate open={openCreate} close={this.toggleCreate}/>
        <SideViewNotice open={openShow} close={()=>this.toggleShow()} document={document} getNotices={(query)=>this.getNotices(query)}/>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h5' style={{ marginTop: 8 }}>Avisos</Typography>
            </Grid>
            {/* <Grid item xs={6}>
            <TextField fullWidth id="nickname-field" label="Nickname" name="nickname" variant="filled" onChange={this.handleChange} value={nickname}/>
            </Grid>
            <Grid item xs={6}>
            <TextField fullWidth id="email-field" label="E-mail" variant="filled" name="email" onChange={this.handleChange} value={email}/>
            </Grid> */}
            <Grid item xs={12}>
              <List>
                <ListItem button onClick={() => this.toggleCreate('NOTICE')}>
                  <ListItemIcon>
                    <SmsFailedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Criar aviso" />
                </ListItem>
                <Divider />
              </List>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                      <Grid item xs={12}>
                        <NoticeList toggleShow={this.toggleShow} docs={docs} />
                      </Grid>
                      <Grid item style={{ textAlign: 'center' }} xs={12}>
                        <div>
                          <Pagination style={{ margin: '16px auto', display: 'inline-block' }} count={totalPages} color="primary" onChange={this.handlePaginate} />
                        </div>
                      </Grid>
                    </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, notices }) => {
  return {
    auth,
    notices
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNotices: (users) => dispatch(getNoticesFetchData(users)),
    createNotice: () => dispatch(createNotice()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notices)