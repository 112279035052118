import React, { Component } from 'react'
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

class StatusWidgetMini extends Component {
    render() {
        const statusColor =  {
            'PENDING': { 
              color: '#ffbc00',
              icon: <ErrorIcon fontSize='small' style={{ display: 'inline-block', width: 16 }}/>
            },
            'APPROVED': { 
              color: '#439406',
              icon: <CheckCircleIcon fontSize='small' style={{ display: 'inline-block', width: 16 }}/>
            },
            'DECLINED': { 
              color: '#f54646',
              icon: <CancelIcon fontSize='small' style={{ display: 'inline-block', width: 16 }}/>
            },
            'CANCELED': { 
              color: '#7b7b7b',
              icon: <CancelIcon fontSize='small' style={{ display: 'inline-block', width: 16 }}/>
            }
          }

          const { status = 'PENDING' } = this.props;
          const { color , icon } = statusColor[status]

              
          return (
            <div style={{ 
                padding: '2px 0px', 
                boxSizing: 'border-box',
                background: `linear-gradient(147deg, rgb(255, 255, 255) -33%, ${color} 67%)`, 
                display: 'inline-block', 
                borderRadius: 200,
                boxShadow: "1px 1px 4px 0px #ddd",
                textAlign: 'center',
                width: 24,
                height: 24,
                color: 'white'
            }}>
                {icon}
            </div>
          )

          
    }
}

export default StatusWidgetMini
