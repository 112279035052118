import { Container, Typography, Button, Grid } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import UserBar from '../auth/UserBar'
import { Link } from "react-router-dom"
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import translations from '../../translations/translations'

const Verified = ({ auth, lang }) => {
  return (
    <div>
      <UserBar auth={auth} />
      <Container style={{ marginTop: 32, textAlign: 'center' }}>
        <Grid container>
          <Grid item xs={12} style={{ padding: '16px 0'}}>
            <VerifiedUserIcon style={{ fontSize: '40px', color: 'green' }}/>
          </Grid>
          <Grid item xs={12} style={{ padding: '16px 0'}}>
            <Typography variant="h5"> {translations[lang].success_verified} </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: '16px 0'}}>
            <Link to='/dashboard'>
              <Button size="large" color="primary" variant="contained" >{translations[lang].go_homepage}</Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

const mapStateToProps = ({ auth, lang }) => {
  return {
    auth,
    lang
  }
}

export default connect(mapStateToProps, null)(Verified)