import { routes } from './core/api';
import { Auth as AuthCognito } from 'aws-amplify';


export const getTransactions = (transactions) => {
  return {
    type: 'GET_TRANSACTIONS',
    transactions
  }
}

export function getTransactionsFetchData({page = 1, query = {}}) {

  let qs = '?';
    if(page) {
      qs += 'page='+page
    }
   
  var queryString = Object.keys(query).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
  }).join('&');

  let route = routes.get+qs;
  if(queryString.length) {
    route += `&${queryString}`
  }
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
          
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Invalid credentials');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(getTransactions(body))
          resolve(body)
        })
      })
    })
  }
}