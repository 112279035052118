import { routes } from './core/api';
import { Auth as AuthCognito } from 'aws-amplify';
import Auth2 from '../../utils/auth';

export const createUser = (user) => {
  return {
      type: 'CREATE_USER',
      user
  }
}

export const getMe = (me) => {
  return {
    type: 'GET_ME',
    me
  }
}


export function getFiltersFetchData(filters) {
  return dispatch => {
    dispatch(getFilters(filters))
  }
}

export const getFilters = (filters) => {
  return {
      type: 'GET_FILTERS',
      filters
  }
}

export const getBalance = (balance) => {
  return {
      type: 'GET_BALANCE',
      balance
  }
}

export const getPlatformRules = (rules) => {
  return {
      type: 'GET_PLATFORM_RULES',
      rules
  }
}

export const getUser = (user) => {
  return {
      type: 'GET_USER',
      user
  }
}
export const getUsers = (users) => {
  return {
      type: 'GET_USERS',
      users
  }
}

export function createCognitoUser(body) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.createCognito, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
          body: JSON.stringify(body)
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Usuário não criado!');
           }
          return res.json() 
        })
        .then(body => {
          resolve(body)
        })
      })
    })
  }
  
}

export function createUserFetchData(body) {
  return dispatch => {
    AuthCognito.currentSession()
    .then(session=>{
      return fetch(routes.create, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': session.idToken.jwtToken
        },
        body: JSON.stringify(body)
      })
      .then(res => { 
        if(res.status !== 200) { 
          return new Promise((resolve, reject) => {
            reject('Usuário não criado!');
          })
         }
        return res.json() 
      })
      .then(body => {
        dispatch(createUser(body))
        return new Promise(function(resolve) {
          resolve(body)
        })
      })
    })
  }
  
}

export function signUp(user) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const attributes = {
          name: user.name,
          email: user.email,
          phone_number: `+${user.phoneNumber}`,
      }
      
      return AuthCognito.signUp({
            username: user.email,
            password: user.password,
            attributes 
        })
        .then(body => {
          Auth2.storeInfoForValidation(body.userSub, body.user.username);
          resolve(body)
        })
        .catch(err => {
          reject(err);
        });
    })
  }
}

export function validate(validationCode) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return AuthCognito.confirmSignUp(Auth2.getValidationEmail(), validationCode)
      .then(res => {
        resolve({})
      })
      .catch(err=>{
        reject(err.message);
      })
    })
  }

}

export function changeUserPasswordFetchData(body) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
  
        return fetch(routes.changePassword, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            },
          body: JSON.stringify(body)
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Senha não alterada');
           }
          return res.json() 
        })
        .then(body => {
          resolve(body)
        })
      })
    })
  }
  
}

export function changePasswordFetchData(body) {
  return new Promise((resolve, reject) => {
    AuthCognito.currentSession()
    .then(session=>{
      return fetch(routes.changeMyPassword, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': session.idToken.jwtToken,
          'AccessToken': session.accessToken.jwtToken
        },
        body: JSON.stringify(body)
      })
      .then(res => { 
        if(res.status !== 200) { 
          reject('Senha não alterada');

         }
        return res.json() 
      })
      .then(body => {
        resolve(body)
      })
    })
  })
  
}

export function getMeFetchData(cognito_id) {
  return dispatch => {
    return new Promise(function(resolve) {

      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.me+`${cognito_id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          }
        })
        .then(res => { 
          return res.json() 
        })
        .then(body => {
          dispatch(getMe(body))
          Auth2.setRole(body.role)
          resolve(body)
        })
      })
    })
  }
}

export function getPlatformsRulesFetchData(user_id) {
  return dispatch => {
    return new Promise(function(resolve) {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.getRules+`${user_id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          }
        })
        .then(res => { 
          return res.json() 
        })
        .then(body => {
          resolve(body)
        })
      })
    })
  }
}

export function getPlatformRulesFetchData() {
  return dispatch => {
    return new Promise(function(resolve) {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.getPlatformRules, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          }
        })
        .then(res => { 
          return res.json() 
        })
        .then(body => {
          dispatch(getPlatformRules(body))
          resolve(body)
        })
      })
    })
  }
}

export function createPlatformRules() {
  return new Promise(function(resolve) {
    AuthCognito.currentSession()
    .then(session=>{
      return dispatch => {
        return fetch(routes.createPlatformRules, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          }
        })
        .then(res => { 
          return res.json() 
        })
        .then(body => {
          resolve(body)
        })
      }
    })
  })
}

export function resetSaleLimit() {
  return dispatch => {
    return new Promise(function(resolve) {

      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.resetSaleLimit, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          }
        })
        .then(res => { 
          return res.json() 
        })
        .then(body => {
          resolve(body)
        })
      })
    })
  }
}

export function upsertRuleFetchData(user_id, body) {
  return dispatch => {
    return new Promise(function(resolve) {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.getRules+`${user_id}`, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          }
        })
        .then(res => { 
          return res.json() 
        })
        .then(body => {
          resolve(body)
        })
      })
    })
  }
}

export function getUserBalanceFetchData(user_id = "") {
  return dispatch => {
    return new Promise(function(resolve) {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.getBalance+`${user_id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          }
        })
        .then(res => { 
          return res.json() 
        })
        .then(body => {
          dispatch(getBalance(body))
          resolve(body)
        })
      })
    })
  }
}

// export function getUsersFetchData({ page = 1, query = {}, all = false}) {
//   let qs = '?';
//     if(page) {
//       qs += 'page='+page
//     }
  
//   if(all) {
//     qs += '&limit=999'
//   }

//   if(query.nickname === '') {
//     delete query.nickname
//   }


//   if(query.email === '') {
//     delete query.email
//   }

//   var queryString = Object.keys(query).map((key) => {
//       return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
//   }).join('&');

//   let route = routes.get+qs;
//   if(queryString.length) {
//     route += `&${queryString}`
//   }

//   return dispatch => {
//     return new Promise(function(resolve) {
      // AuthCognito.currentSession()
      // .then(session=>{
//         return fetch(route, {
//           method: 'GET',
          // headers: {
          //   'Content-Type': 'application/json',
          //   'Authorization': session.idToken.jwtToken
          // }
//         })
//         .then(res => { 
//           return res.json() 
//         })
//         .then(body => {
//           dispatch(getUsers(body))
//           resolve(body)
//         })
//       })
//     })
//   }
// }

export function getUsersFetchData({ page = 1, query = {}, all = false, noDispatch = false}) {
  console.log({query})
  let qs = '?';
    if(page) {
      qs += 'page='+page
    }
  
  if(all) {
    qs += '&limit=999'
  }
  var queryString = Object.keys(query).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
  }).join('&');

  let route = routes.getCognito+qs;
  if(queryString.length) {
    route += `&${queryString}`
  }

  return dispatch => {
    return new Promise(function(resolve, reject) {

      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          }
        })
        .then(res => { 
          return res.json() 
        })
        .then(body => {
          if(!noDispatch){
            dispatch(getUsers(body))
          }
          resolve(body)
        })
        .catch(err=>{
          reject(err)
        })
      })
    })
  }
}

export function getUserFetchData(id) {
  let sess = {}
  let userDB = {}
  let userCognito = {}

  return dispatch => {
    return new Promise(function(resolve) {
      AuthCognito.currentSession()
      .then(session=>{
        sess = session
        return fetch(`${routes.get}/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': sess.idToken.jwtToken
          }
        })
        .then(res => { 
          return res.json() 
        })
        .then(body => {
          userDB = body
  
          fetch(`${routes.get}/cognito/${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': sess.idToken.jwtToken
            }
          })
          .then(res => { 
            return res.json() 
          })
          .then(body => {
            userCognito = body
            dispatch(getUser({...userDB, ...userCognito}))
            resolve({...userDB, ...userCognito})
          })
        })
      })
    })
  }
}

export function documentVerification() {
    return AuthCognito.currentSession()
    .then(session=>{
      return fetch(routes.documentVerification, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': session.idToken.jwtToken
        }
      })
      .then(res => { 
        if(res.status !== 200) { 
          return new Promise((resolve, reject) => {
            reject('Usuário não criado!');
          })
         }
        return res.json() 
      })
      .then(body => {
        console.log({ body })
        return new Promise(function(resolve) {
          resolve(body)
        })
      })
    })
}