import React from 'react'
import { Route, Redirect } from "react-router-dom";


const AdminRoute = ({ isAuthenticated = false, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && localStorage.role === 'ADMIN' ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

export default AdminRoute