        
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { TableContainer, TableBody, TableCell, TableRow, TableHead, Table } from '@material-ui/core'
import moment from 'moment'
import NumberFormat from 'react-number-format'

class DollarPriceList extends Component {

  render() {
    const { docs } = this.props;
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Cadastrado em</TableCell>
              <TableCell>Preço de Envio</TableCell>
              <TableCell>Preço de Recebimento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { docs.length ? docs.map(doc => (
                <TableRow onClick={() => { this.props.toggleShow({ ...doc }) }} key={doc._id} style={{ cursor: 'pointer' }}>
                  <TableCell >{moment(doc.created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
                  <TableCell>
                    <NumberFormat
                      value={doc.sale_value}
                      prefix='R$ '
                      displayType={'text'}
                      thousandSeparator="."
                      decimalSeparator=","
                      fixedDecimalScale="true"
                      decimalScale={2}
                    />
                  </TableCell>
                  <TableCell>
                  <NumberFormat
                      value={doc.purchase_value}
                      prefix='R$ '
                      displayType={'text'}
                      thousandSeparator="."
                      decimalSeparator=","
                      fixedDecimalScale="true"
                      decimalScale={2}
                    />
                  </TableCell>
                </TableRow>
            )) : <TableRow>
            <TableCell>Não há registros.</TableCell>
            </TableRow> }
        </TableBody>
      </Table>
    </TableContainer>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth
  }
}

export default withRouter(connect(mapStateToProps)(DollarPriceList))

      