
export const notices = (state = [], action) => {
  switch (action.type) {
    case 'GET_NOTICES':
      return action.notices
    default:
      return state;
  }
}

export const myNotices = (state = [], action) => {
  switch (action.type) {
    case 'GET_MY_NOTICES':
      return action.notices
    default:
      return state;
  }
}