
export const dollarPrices = (state = [], action) => {
  switch (action.type) {
    case 'GET_DOLLAR_PRICES':
      return action.dollarPrices
    default:
      return state;
  }
}


export const recentDollarPrices = (state = {}, action) => {
  switch (action.type) {
    case 'GET_RECENT_DOLLAR_PRICES':
      return action.dollarPrices
    default:
      return state;
  }
}