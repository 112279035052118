import React, { Component } from 'react'
import { Dialog, Typography } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css'
import SignUpForm from './form/SignUpForm';
import translations from '../../translations/translations';


class NewRegistration extends Component {

  state = {
    newPhoneNumber: '',
    newName: '',
    newPassword: '',
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  render() {
    const { isOpen, handleClose, handleSubmit, isFetching, lang } = this.props

    return (
      <Dialog PaperProps={{style:{padding: 16}}} open={isOpen} onClose={handleClose}>
      <Typography variant='h5' padding="20px 20px 0 20px" style={{textAlign:"center"}}>
          {translations[lang].create_wallet}
        </Typography >
      <Typography variant='body1' style={{textAlign:"center"}} padding='0 20px'>{translations[lang].signup_info}</Typography>
        <SignUpForm lang={lang} handleSubmit={handleSubmit} isFetching={isFetching} ></SignUpForm>
    </Dialog>
    )
  }
}

export default NewRegistration
