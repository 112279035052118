import React, { Component } from 'react'
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import SignInForm from './form/SignInForm'
import { signInFetchData, completeNewPasswordChallenge, signUp, validate, confirmSignInFetchData } from './actions'
import { Snackbar, Grid, Card, Container, Button, Typography, AppBar, IconButton } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css'
import CompleteRegistrationSuccess from './CompleteRegistrationSuccess';
import CompleteRegistration from './CompleteRegistration';
import NewRegistration from './NewRegistration';
import ValidateRegistration from './ValidateRegistration';
import RegistrationConfirmed from './RegistrationConfirmed';
import TokenSignin from './TokenSignin';
import SelectLang from '../common/SelectLang'
import { changeLang } from '../auth/actions'
import translations from '../../translations/translations';
import qs from 'qs';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

class SignIn extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  state = {
    isFetching: false,
    isSigningUp: false,
    isCompletingNewPassword: false,
    isValidating: false,
    didInvalidate: false,
    newPasswordChallengeOpen: false,
    newRegistrationOpen: false,
    challengeSuccessOpen: false,
    validationOpen: false,
    confirmedOpen: false,
    snackbarOpen: false,
    tokenMfaOpen: false,
    telegramMessage: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center',
  }

  componentDidMount = () => {
    if (Object.keys(this.props.auth).length > 0) {
      this.props.history.push('/dashboard')
    }
    let search = this.props.location.search
    search = search.substring(1)
    const query_params = qs.parse(search)
    if(query_params['signup']) {
      this.handleOpenModalNewRegistration()
    }
  }

  signup({ name, email, phoneNumber, password }) {
    this.setState((state) => ({
      isSigningUp: true,
      didInvalidate: false,
    }))

    const user = {
      name,
      email,
      phoneNumber,
      password
    }


    if(this.props.signUpInfo.ref) {
      user.affiliator = this.props.signUpInfo.ref
    }

    this.props.signUp(user)
      .then(body => {
        this.setState((state) => ({
          isSigningUp: false,
          didInvalidate: false,
        }))

        //setting first login to display stuff on the dashboard
        localStorage.setItem('firstLogin', true);
        this.handleCloseModalNewRegistration();
        this.handleOpenModalValidation();
      })
      .catch(err => {
        this.setState((state) => ({
          isSigningUp: false,
          didInvalidate: true,
          snackbarOpen: true,
          snackMessage: err.message
        }))
      })
  }

  validateSignUp = (code) => {
    this.setState({
      isValidating: true
    })
    this.props.validate(code)
      .then(r => {
        this.setState({
          isValidating: false
        })
        this.handleCloseModalValidation()
        this.handleOpenModalConfirmed()
      })
      .catch(err => {
        this.setState((state) => ({
          isValidating: false,
          didInvalidate: true,
          snackbarOpen: true,
          snackMessage: err
        }))
      })
  }

  login({ username, password }) {
    this.setState((state) => ({
      isFetching: true,
      didInvalidate: false,
    }))

    this.props.signIn(username, password)
      .then(body => {
        if (body.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.handleOpenModalNewPassword()
          this.setState((state) => ({
            isFetching: false,
            user: body
          }))
        } else {
          if (body.challengeName === "SOFTWARE_TOKEN_MFA") {
            this.handleOpenModalTokenMfa()
            this.setState((state) => ({
              isFetching: false,
              user: body
            }))
          } else {
            this.setState((state) => ({
              isFetching: false,
              didInvalidate: false,
            }))
            this.props.history.push('/dashboard')
          }
        }
      })
      .catch(err => {
        this.setState((state) => ({
          isFetching: false,
          didInvalidate: true,
          snackbarOpen: true,
          telegramMessage: true,
          snackMessage: err.message
        }))
      })
  }

  completeNewPassword = (user, newPassword, requiredAttributes) => {
    this.setState((state) => (
      {
        isCompletingNewPassword: true
      }
    ))

    completeNewPasswordChallenge(user, newPassword, requiredAttributes)
      .then(res => {
        this.setState((state) => (
          {
            isCompletingNewPassword: false
          }))

        localStorage.setItem('firstLogin', true);
        this.handleCloseModalNewPassword()
        this.handleOpenModalChallengeSuccess()
      })
      .catch(err => {
        console.log({ err })
        this.setState((state) => (
          {
            isCompletingNewPassword: false,
            didInvalidate: true,
            snackbarOpen: true,
            snackMessage: err
          }
        ))
      })
  }

  submitToken = (user, code) => {
    this.props.confirmSignIn(user, code).then(c => {
      this.props.history.push('/dashboard')
    }).catch(e => {
      this.setState((state) => (
        {
          isCompletingNewPassword: false,
          didInvalidate: true,
          snackbarOpen: true,
          snackMessage: 'Código inválido'
        }
      ))
    })

  }

  handleClose = () => {
    this.setState((state) => ({ ...state, snackbarOpen: false }));
  };

  handleOpenModalNewRegistration = () => {
    this.setState((state) => ({ ...state, newRegistrationOpen: true }));
  };

  handleOpenModalTokenMfa = () => {
    this.setState((state) => ({ ...state, tokenMfaOpen: true }));
  };

  handleCloseModalTokenMfa = () => {
    this.setState((state) => ({ ...state, tokenMfaOpen: false }));
  };

  handleCloseModalNewRegistration = () => {
    this.setState((state) => ({ ...state, newRegistrationOpen: false }));
  };

  handleOpenModalNewPassword = () => {
    this.setState((state) => ({ ...state, newPasswordChallengeOpen: true }));
  };

  handleCloseModalNewPassword = () => {
    this.setState((state) => ({ ...state, newPasswordChallengeOpen: false }));
  };

  handleOpenModalChallengeSuccess = () => {
    this.setState((state) => ({ ...state, challengeSuccessOpen: true }));
  };

  handleCloseModalChallengeSuccess = () => {
    this.setState((state) => ({ ...state, challengeSuccessOpen: false }));
  };

  handleOpenModalValidation = () => {
    this.setState((state) => ({ ...state, validationOpen: true }));
  };

  handleCloseModalValidation = () => {
    this.setState((state) => ({ ...state, validationOpen: false }));
  };

  handleOpenModalConfirmed = () => {
    this.setState((state) => ({ ...state, confirmedOpen: true }));
  };

  handleCloseModalConfirmed = () => {
    this.setState((state) => ({ ...state, confirmedOpen: false }));
  };

  render() {
    const { isFetching, vertical, user, horizontal, snackbarOpen, newPasswordChallengeOpen, isCompletingNewPassword, newRegistrationOpen, validationOpen, confirmedOpen, tokenMfaOpen } = this.state
    const { lang, changeLang } = this.props
  
    return (
      <div>
        <AppBar position="static" style={{ background: '#101010', textAlign: 'right'}}>
          <Typography variant="caption">
            <SelectLang complete lang={lang} changeLang={changeLang}/>
          </Typography>
        </AppBar>
        <Container>
          <Grid container spacing={6} style={{ marginTop: 32 }}>
            <Grid item xs={12} style={{ padding: "8px 24px"}}>
              <IconButton onClick={() => {
                this.props.history.push('/')
              }}>
                <KeyboardBackspaceIcon />
              </IconButton>
            </Grid>
            <Grid style={{ display: 'flex', justifyContent: 'flex-end' }} item xs={12} sm={6}>
              <Card style={{ textAlign: 'center', padding: "16px", width: "100%" }}>
                <img alt="logo sensei wallet" style={{ width: "240px", margin: "30px 0" }} src={process.env.PUBLIC_URL + "/senseiwallet-vertical.png"} />
                <SignInForm
                  lang={lang}
                  handleSubmit={(data) => this.login(data)}
                  isFetching={isFetching}
                  telegramMessage={this.state.telegramMessage}
                />
                <Snackbar
                  anchorOrigin={{ vertical, horizontal }}
                  key={`${vertical},${horizontal}`}
                  open={snackbarOpen}
                  onClose={this.handleClose}
                  ContentProps={{
                    'aria-describedby': 'message-id',
                  }}
                  message={<span id="message-id">{this.state.snackMessage}</span>}
                />
              </Card>
            </Grid>
            <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }} xs={12} sm={6}>
              <Card style={{ textAlign: 'center', padding: "16px", width: "100%" }}>
                <Grid container spacing={3} >
                  <Grid item xs={12}>
                    <Typography variant='h4' style={{ textTransform: 'uppercase', marginTop: 16, marginBottom: 6 }}>{translations[lang].create_wallet}</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <img alt="wallet" style={{ width: "225px" }} src="/wallet-image.png" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1'>{translations[lang].wallet_info}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      type="button"
                      margin="normal"
                      style={{ marginTop: 16 }}
                      onClick={this.handleOpenModalNewRegistration}
                    >
                      <b>{translations[lang].create_wallet}</b>
                    </Button>                </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>

        </Container>


        {/* Dialogs */}
        <NewRegistration lang={lang} isOpen={newRegistrationOpen} handleSubmit={(data) => this.signup(data)} handleClose={this.handleCloseModalNewRegistration} isFetching={this.state.isSigningUp} />

        <ValidateRegistration lang={lang} isValidating={this.state.isValidating} isOpen={validationOpen} handleClose={this.handleCloseModalValidation} handleSubmit={this.validateSignUp} />

        <RegistrationConfirmed lang={lang} isOpen={confirmedOpen} handleClose={this.handleCloseModalConfirmed} />

        <CompleteRegistration lang={lang} isOpen={newPasswordChallengeOpen} handleSubmit={this.completeNewPassword} user={user} isCompletingNewPassword={isCompletingNewPassword} />

        <CompleteRegistrationSuccess lang={lang} isOpen={this.state.challengeSuccessOpen} handleClose={this.handleCloseModalChallengeSuccess} />

        <TokenSignin lang={lang} isOpen={tokenMfaOpen} handleSubmit={(user, answer) => this.submitToken(user, answer)} handleClose={this.handleCloseModalTokenMfa} isFetching={this.state.isSigningUp} user={user} />


      </div>
    )
  }
}

const mapStateToProps = ({ auth, lang, signUpInfo }) => {
  return {
    auth,
    lang,
    signUpInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (username, password) => dispatch(signInFetchData(username, password)),
    confirmSignIn: (user, code) => dispatch(confirmSignInFetchData(user, code)),
    signUp: (user) => dispatch(signUp(user)),
    validate: (code) => dispatch(validate(code)),
    changeLang: (lang) => dispatch(changeLang(lang))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn))
