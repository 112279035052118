import React, { Component } from 'react'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import { Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import translations from '../../translations/translations';


class CompleteRegistration extends Component {

  state = {
    newPhoneNumber: '',
    newName: '',
    newPassword: '',
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  render() {
    const { isOpen, handleClose, handleSubmit, isCompletingNewPassword, user, lang } = this.props
    const { newPhoneNumber, newPassword, newName } = this.state

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <Typography variant='h5' style={{padding:"20px 20px 0 20px", textAlign:'center'}}>
              {translations[lang].complete_registration}
            </Typography>
            <Typography variant='caption' style={{textAlign:"center", padding:'0 20px'}}>{translations[lang].registration_info}</Typography>
            <div style={{padding:'0 20px 20px'}}>
            <ValidatorForm
              ref="form"
              onSubmit={() => handleSubmit(user, this.state.newPassword, {name: this.state.newName, phone_number: this.state.newPhoneNumber})}
            >
              <div style={{padding: '20px'}}>
                <TextValidator
                  fullWidth
                  label={translations[lang].name}
                  type="text"
                  onChange={this.handleChange}
                  variant='outlined'
                  name="newName"
                  value={newName}
                  validators={['required']}
                  errorMessages={[translations[lang].error_required]}
                  margin="dense"
                />
                <PhoneInput
                  country={'br'}
                  value={newPhoneNumber}
                  specialLabel={translations[lang].phone}
                  onChange={newPhoneNumber => this.setState({ newPhoneNumber })}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <TextValidator
                  fullWidth
                  label={translations[lang].password}
                  type='password'
                  onChange={this.handleChange}
                  variant='outlined'
                  name="newPassword"
                  value={newPassword}
                  validators={['required']}
                  errorMessages={[translations[lang].error_required]}
                  margin="dense"
                />
              </div>
              <Button 
                variant="contained"
                fullWidth
                color="primary"
                type="submit"
                disabled={isCompletingNewPassword}
                margin="normal"
                style={{ marginTop: 16 }}
              >
                {isCompletingNewPassword && <CircularProgress color='inherit' size={20} />} <b>{translations[lang].save}</b>
              </Button>
              
            </ValidatorForm>
          </div>
        </Dialog>
    )
  }
}

export default CompleteRegistration
