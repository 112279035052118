import React, { Component } from 'react'
import { Switch, Route } from 'react-router'
import { withRouter } from 'react-router-dom'
import PrivateRoute from './auth/core/PrivateRoute'
import AdminRoute from './auth/core/AdminRoute'
import EmployeeRoute from './auth/core/EmployeeRoute'
import SignIn from './auth/SignIn'
import Logout from './auth/Logout'
import Home from './pages/Home'
import Verified from './pages/Verified'
import ErrorVerified from './pages/ErrorVerified'
import Landing from './pages/Landing'
import Sales from './sales/Sales'
import Purchases from './purchases/Purchases'
import Transfers from './transfers/Transfers'
import Wallet from './users/Wallet'
import Users from './users/Users'
import Profile from './users/Profile'
import User from './users/User'
import Platforms from './platforms/Platforms'
import Safe from './safe/Safe'
import Notices from './notices/Notices'
import DollarPrices from './dollar-prices/DollarPrices'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';
import {connect} from 'react-redux'
import Amplify from 'aws-amplify'
import amplify_config from '../amplify-config'
import {signInUser} from './auth/actions'
import {getPlatformsFetchData} from './platforms/actions'
import {getRecentDollarPricesFetchData} from './dollar-prices/actions'
import './App.css';
import TermsOfUse from './pages/TermsOfUse'
import FAQ from './pages/FAQ'
import ReactWhatsapp from "react-whatsapp";

Amplify.configure(amplify_config);

const darkTheme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: red[800],
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
});

class App extends Component {

  state = { 
    isFetching: true
  }

  async componentDidMount() {
    try {
      const user = await Amplify.Auth.currentAuthenticatedUser({bypassCache: true});
      if(user){
        this.props.getRecentDollarPrices()
        this.props.authenticateUser(user)
        this.props.getPlatforms({ all: true })
      }
      this.setState({isFetching: false})
    }
    catch(e) {
      this.setState({ isFetching: false });
    }
  }

  render() {
    const { auth } = this.props;
    const {isFetching} = this.state;
    return (
      !isFetching && (
        
      <ThemeProvider theme={darkTheme}>
        <Switch>
          <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/dashboard' component={Home} />
          <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/verified' component={Verified} />
          <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/error_verified' component={ErrorVerified} />
          <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/recebimentos' component={Purchases} />
          <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/envios' component={Sales} />
          <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/transferencias' component={Transfers} />
          <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/carteira' component={Wallet} />
          <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/perfil' component={Profile} />
          <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/ajuda' component={FAQ} />
          {
            // EMPLOYEE ROUTES
          }

          <EmployeeRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/admin/usuarios' component={Users} />
          <EmployeeRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/admin/avisos' component={Notices} />
          <EmployeeRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/admin/preco' component={DollarPrices} />
          <EmployeeRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/admin/envios' component={Sales} />
          <EmployeeRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/admin/recebimentos' component={Purchases} />
          <EmployeeRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/admin/transferencias' component={Transfers} />
          <EmployeeRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/admin/usuarios/:id' component={User} />
          {
            // ADMIN ROUTES
          }
        
          <AdminRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/admin/plataformas' component={Platforms} />
          <AdminRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/admin/cofre' component={Safe} />
          <Route exact path='/login' render={(props) => (
            <SignIn {...props} />
          )}/>
          <Route exact path='/logout' render={(props) => (
            <Logout {...props} />
          )}/>
          <Route exact path='/terms-of-use' render={(props) => (
            <TermsOfUse {...props} />
          )}/>

          <Route exact path='/' render={(props) => (
            <Landing {...props} />
          )}/>
          <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} component={SignIn}/>

        </Switch>
        <ReactWhatsapp
                  number="+55 (61) 99614-5707"
                  message="Olá, gostaria de saber sobre o Sensei Poker"
                  style={{
                    width: "80px",
                    position: "fixed",
                    border: "none",
                    bottom: "10px",
                    right: "10px",
                    backgroundColor: "transparent",
                  }}
                >
                  <img src="whatsapp03.png" alt="" style={{ width: "100%" }} />
                </ReactWhatsapp>
      </ThemeProvider>
      
      )
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authenticateUser: (user) => dispatch(signInUser(user)),
    getPlatforms: () => dispatch(getPlatformsFetchData()),
    getRecentDollarPrices: () => dispatch(getRecentDollarPricesFetchData()),
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))

