import React, { Component } from 'react'
import { Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Auth2 from '../../utils/auth';
import translations from '../../translations/translations';


class ValidateRegistration extends Component {

  state = {
    validationCode: ''
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  render() {
    const { isOpen, handleClose, handleSubmit, isValidating, lang } = this.props

    return (
      <Dialog open={isOpen}  onClose={handleClose} PaperProps={{style:{padding: 16, width:'400px'}}}>
        <Typography variant='h5' padding="20px 20px 0 20px" style={{textAlign:"center"}}>
            {translations[lang].confirm_account}
          </Typography>
        <Typography variant='body1' style={{textAlign:"center"}} padding='0 20px'>{translations[lang].insert_code} <strong>{Auth2.getValidationEmail()}</strong> </Typography>
        <ValidatorForm
          style={{
            margin: 'auto'
          }}
          ref="form"
          onSubmit={()=>handleSubmit(this.state.validationCode)}
        >
          <TextValidator
          fullWidth
          value={this.state.validationCode}
          label="Código"
          type='text'
          onChange={this.handleChange}
          name="validationCode"
          validators={['required']}
          errorMessages={[translations[lang].error_required]}
          margin="dense"/>
          <Button 
            variant="contained"
            fullWidth
            color="primary"
            type="submit"
            disabled={isValidating}
            margin="normal"
            style={{ marginTop: 16 }}
          >
            {isValidating && <CircularProgress color='inherit' size={20} />} <b>{translations[lang].confirm_account}</b>
          </Button>
          {/* <span><div onClick={()=>this.resendCode()} style={{textDecoration: 'underline', color: '#444', marginBottom: '20px', textAlign:'center', display: 'block', cursor: 'pointer'}}>{this.state.codeResending ? 'Reenviando código...' : '-> Reenviar código'}</div></span> */}
        </ValidatorForm>
      </Dialog>
    )
  }
}

export default ValidateRegistration
