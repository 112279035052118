import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import UserBar from '../auth/UserBar'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import { Button, Typography, Snackbar } from '@material-ui/core';
import SideView from '../common/SideView'
import { getUserFetchData, changePasswordFetchData, documentVerification } from './actions';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import CircularProgress from '@material-ui/core/CircularProgress'
import translations from '../../translations/translations';
import { Auth } from 'aws-amplify';
import QRCode from 'qrcode.react'
class Profile extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  state = {
    document: '',
    new_password: '',
    old_password: '',
    openShow: false,
    isFetching: false,
    changePassword: false,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center',
    showQRCode: false,
    QRCodeStr: null,
    answer: '',
    jumioUrl: false,
    mfa_active: false
  }

  handleCloseSnackbar = () => {
    this.setState((state) => ({ ...state, snackbarOpen: false }));
  };


  toggleShow = (document) => {
    this.setState((state) => ({ openShow: !state.openShow, document }))
  }

  deactivate2fa = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.setPreferredMFA(user, 'NOMFA').then(c => {
          this.setState({ 
            mfa_active: 'NOMFA',  
            snackMessage: translations[this.props.lang].two_factor_deactivate,
            snackbarOpen: true })
        }).catch(e => {
          this.setState({ 
            snackMessage: translations[this.props.lang].two_factor_error,
            snackbarOpen: true
          })
        })
        
      })
  }

  toggle2faActivate = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.setupTOTP(user).then((code) => {
          this.setState({
            showQRCode: true,
            QRCodeStr: "otpauth://totp/AWSCognito:" + user.username + "?secret=" + code + "&issuer=Sensei Wallet"
          })
        });
      })
  }

  handleSubmitQRCode = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.verifyTotpToken(user, this.state.answer)
          .then(() => {
            Auth.setPreferredMFA(user, 'TOTP');
            this.setState({ 
              mfa_active: 'SOFTWARE_TOKEN_MFA',  
              snackMessage: translations[this.props.lang].two_factor_success,
              snackbarOpen: true,
              showQRCode: false 
            })
          }).catch(e => {
            this.setState({   
              snackMessage: translations[this.props.lang].wrong_code,
              snackbarOpen: true
            })
          });
      })
  }

  documentVerification = () => {
    documentVerification().then(jumio => {
      window.open(jumio.jumio_url)
    })
  }

  toggleChangePassword = () => {
    this.setState((state) => ({ changePassword: !state.changePassword }))
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }


  handleSubmit = () => {
    changePasswordFetchData({ old_password: this.state.old_password, new_password: this.state.new_password })
      .then((c) => {
        this.setState((state) => ({
          openShow: false,
          snackMessage: translations[this.props.lang].password_updated,
          snackbarOpen: true,
          changePassword: false,
          new_password: '',
          old_password: '',
        }))
      })
  }

  updatedAction = (document) => {
    this.setState((state) => ({ openShow: false }))
  }

  componentDidMount() {
    this.props.getUser(this.props.match.params.id)
    Auth.currentAuthenticatedUser()
    .then(user => {
      this.setState({ mfa_active: user.preferredMFA })
    })
  }

  render() {
    const { auth, me, lang } = this.props
    const { document, openShow, changePassword, new_password, old_password, isFetching, vertical, horizontal, snackbarOpen, snackMessage, showQRCode, QRCodeStr, answer, mfa_active } = this.state

    if (Object.keys(auth).length === 0) {
      this.props.history.push('/login')
    }

    return (
      <div>
        <UserBar auth={auth} />
        <SideView open={openShow} close={this.toggleShow} document={document} updated={this.updatedAction} />
        <Container>
          <Grid container spacing={3} style={{ paddingTop: 16 }}>
            <Grid item xs={12}>
              <Typography variant="caption">{translations[lang].name}</Typography>
              <Typography>{auth.user_name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">{translations[lang].email}</Typography>
              <Typography>{auth.user_email}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">{translations[lang].phone}</Typography>
              <Typography>{auth.user_phone}</Typography>
            </Grid>
            <Grid item xs={6}>
              { mfa_active === 'SOFTWARE_TOKEN_MFA' ? 
              <Button size="small" fullWidth color="primary" variant="contained" onClick={this.deactivate2fa}>{translations[lang].deactivate_2fa}</Button>
              :  
              <Button size="small" fullWidth color="primary" variant="contained" onClick={this.toggle2faActivate}>{translations[lang].activate_2fa}</Button>
              }
            </Grid>
            <Grid item xs={6}>
              <Button size="small" fullWidth color="primary" variant="contained" onClick={this.toggleChangePassword}>{changePassword ? translations[lang].cancel : translations[lang].change_password}</Button>
            </Grid>
            {showQRCode ?
              <Grid item xs={12}>
                <Typography variant="body1" style={{ marginBottom: 8 }}>{translations[lang].google_auth_info}</Typography>
                <QRCode value={QRCodeStr} />

                <ValidatorForm
                  ref="form"
                  onSubmit={this.handleSubmitQRCode}
                  style={{ marginTop: 16 }}
                >
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                      <TextValidator
                        fullWidth
                        label={translations[lang].code}
                        variant="filled"
                        onChange={this.handleChange}
                        name="answer"
                        value={answer}
                        validators={['required']}
                        errorMessages={[translations[lang].error_required]}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isFetching}
                        style={{ height: 55 }}
                      >
                        {isFetching && <CircularProgress size={20} />} {translations[lang].save}
                      </Button>
                    </Grid>
                  </Grid>

                </ValidatorForm>

              </Grid>
              : null}

            {
              changePassword ?
                <Grid item xs={12}>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}

                  >
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <TextValidator
                          fullWidth
                          label={translations[lang].current_password}
                          variant="filled"
                          onChange={this.handleChange}
                          name="old_password"
                          value={old_password}
                          validators={['required']}
                          errorMessages={[translations[lang].error_required]}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextValidator
                          fullWidth
                          label={translations[lang].new_password}
                          variant="filled"
                          onChange={this.handleChange}
                          name="new_password"
                          value={new_password}
                          validators={['required']}
                          errorMessages={[translations[lang].error_required]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          type="submit"
                          disabled={isFetching}
                          style={{ height: 55 }}
                        >
                          {isFetching && <CircularProgress size={20} />} {translations[lang].save}
                        </Button>
                      </Grid>
                    </Grid>

                  </ValidatorForm>
                </Grid>
                : null
            }

            <Grid item xs={12}>
              { !me.verified_documents ? <Button size="small" color="primary" variant="contained" onClick={this.documentVerification}>{translations[lang].confirm_id_button}</Button> : null }
            </Grid>
          </Grid>

          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            key={`${vertical},${horizontal}`}
            open={snackbarOpen}
            onClose={this.handleCloseSnackbar}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{snackMessage}</span>} />
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, user, me, lang }) => {
  return {
    auth,
    user,
    me,
    lang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (user) => dispatch(getUserFetchData(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)