        
import React, { Component } from 'react'
import { createTransferFetchData } from './actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import TransferForm from './TransferForm'
import { getUserBalanceFetchData } from '../users/actions'

class TransferCreate extends Component {

  state = { 
    isFetching: false,
    didInvalidate: false,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center'
  }

  createTransfer({ destination_user, value, comment }) {
    this.setState((state) => ({
      isFetching: true,
      didInvalidate: false,
    }))

    this.props.createTransfer({ destination_user, value, comment })
    .then(body => {
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: false,
      }));
      this.props.closeCreate('SUCCESS');
      this.props.getBalance()
    })
    .catch(ex => {
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: true,
        snackbarOpen: true,
        snackMessage: ex,
      }))
    })
  }


  render() {
    const { isFetching, vertical, horizontal, snackbarOpen, snackMessage } = this.state
    const { users, lang } = this.props

    return (
        <div>
            <TransferForm 
              handleSubmit={(data) => this.createTransfer(data)}
              users={users}
              lang={lang}
              isFetching={isFetching}
            />
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              key={`${vertical},${horizontal}`}
              open={snackbarOpen}
              onClose={this.handleCloseSnackbar}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
            message={<span id="message-id">{snackMessage}</span>}    />
        </div>
    )
  }
}

const mapStateToProps = ({ auth, lang }) => {
  return {
    auth,
    lang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createTransfer: (transfer) => dispatch(createTransferFetchData(transfer)),
    getBalance: () => dispatch(getUserBalanceFetchData())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransferCreate))

      