import React, { Component, Fragment } from 'react'
import { Drawer, Container, Grid, Typography, Button, TextField, Snackbar, CircularProgress } from '@material-ui/core'
import moment from 'moment';
import NumberFormat from 'react-number-format'
import StatusWidget from './StatusWidget'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { updatePurchaseStatusFetchData } from '../purchases/actions';
import { updateSaleStatusFetchData, uploadReceipt } from '../sales/actions';
import { updateTransferStatusFetchData } from '../transfers/actions';
import { getPlatformsFetchData } from '../platforms/actions';
import _ from 'lodash';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import translations from '../../translations/translations';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link as LinkRouter } from 'react-router-dom';


const styles = theme => ({
  main: {
    color: "#CCC",
    height: '15px'
  },
  label: {
    fontSize: '12px'
  },
  input: {
    fontSize: '15px'
  },
  fileInput: {
    fontSize: '10px',
    textTransform: 'none',
    background: 0,
    boxShadow: 'none',
    textDecoration: 'underline',
    padding: 0,
    "&:hover": {
      boxShadow: 'none',
      background: 0
    }
  }
})

class SideView extends Component {

  state = {
    receipt: '',
    comment: '',
    fee: '',
    anchorEl: null,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center',
    isUploading: false,
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  handleUploadFile = (event) => {
    this.setState({ isUploading: true })

    event.preventDefault();

    uploadReceipt(event.target.files[0])
      .then(res => {
        this.setState({
          isUploading: false,
          receipt: res.data.url
        })
      })
      .catch(err => {
        this.setState({
          isUploading: false,
          snackbarOpen: true,
          snackMessage: err.message
        })
      })
  }

  componentDidMount() {
    this.props.getPlatforms({ all: true })
  }

  handleConfirmCancel() {
    this.setState({ confirmCancelOpen: true })
  }

  handleAction(action) {
    const { document } = this.props
    const { receipt, comment, confirmTerm } = this.state

    if (action === 'cancel' && confirmTerm !== 'CANCELAR') {
      this.setState(() => ({
        snackMessage: 'Não foi possível cancelar',
        snackbarOpen: true,

      }))
      return
    }

    if (action !== 'cancel' && action !== 'decline' && ['PURCHASE', 'SALE'].includes(document.type) && !receipt) {
      this.setState(() => ({
        snackMessage: 'Recibo obrigatório!',
        snackbarOpen: true
      }))
      return
    }

    if (action !== 'cancel' && action !== 'approve' && ['PURCHASE', 'SALE'].includes(document.type) && !comment) {
      this.setState(() => ({
        snackMessage: 'Comentário obrigatório!',
        snackbarOpen: true
      }))
      return
    }
    if (document.type === 'PURCHASE') {
      this.props.updatePurchaseStatus(document._id, action, { receipt, comment }).then((p) => {
        this.props.updated({ ...document, update: true })
        this.setState({ receipt: '', comment: '', confirmCancelOpen: false, confirmTerm: '' })
      })
    }
    if (document.type === 'SALE') {
      this.props.updateSaleStatus(document._id, action, { receipt, comment }).then((p) => {
        this.props.updated({ ...document, update: true })
        this.setState({ receipt: '', comment: '', confirmCancelOpen: false, confirmTerm: '' })
      })
    }
    if (document.type === 'TRANSFER') {
      this.props.updateTransferStatus(document._id, action).then((p) => {
        this.props.updated({ ...document, update: true })
        this.setState({ receipt: '', comment: '', confirmCancelOpen: false, confirmTerm: '' })
      })
    }
  }

  handleCloseSnackbar = () => {
    this.setState((state) => ({ ...state, snackbarOpen: false }));
  };


  render() {
    const { open, close, document = {}, platforms, classes, lang, me } = this.props;
    const { receipt, vertical, horizontal, snackMessage, snackbarOpen, comment, isUploading, confirmTerm } = this.state;
    const platform = _.find(platforms.docs, { _id: document.platform });
    const title = {
      SALE: `${translations[lang].sale}`,
      PURCHASE: `${translations[lang].purchase}`,
      TRANSFER: `${translations[lang].transfer}`
    }
    return (
      <Drawer open={open} onClose={close} anchor='right' >
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
          open={snackbarOpen}
          onClose={this.handleCloseSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{snackMessage}</span>} />
        <Container style={{ padding: "16px" }}>
          <Grid container justify="flex-end" style={{ textAlign: 'right' }}>
            <Grid item xs={12}>
              <Button onClick={close} ><CloseIcon /></Button>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
                {title[document.type] || translations[lang].view}
              </Typography>
            </Grid>
            {
              document ? (
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="caption">{translations[lang].date}</Typography>
                      <Typography variant="body1">{moment(document.created_at).format('DD/MM/YYYY HH:mm')}</Typography>
                    </Grid>
                    {
                      document.user_cognito ?
                        (<Grid item xs={12} md={6}>
                          <LinkRouter to={`/admin/usuarios/${document.user_cognito.sub}`} style={{ color: 'inherit' }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography variant="caption">{translations[lang].user}</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <div style={{
                                  padding: '4px 8px',
                                  background: `linear-gradient(147deg, rgb(255, 255, 255) -33%, #010101 67%)`,
                                  display: 'inline-block',
                                  borderRadius: 30,
                                  color: 'white',
                                  boxShadow: "1px 1px 4px 0px #ddd"
                                }}>
                                  <Grid container alignItems="center" style={{ lineHeight: 1 }}>
                                    <Grid item>
                                      <AccountCircleIcon style={{ marginRight: 10 }} />
                                    </Grid>
                                    <Grid item>
                                      <Typography variant='body2' style={{ display: 'inline-block' }}>
                                        <b style={{ color: "#fff" }}>{document.user_cognito.email}</b>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>
                          </LinkRouter>
                        </Grid>)
                        : null
                    }
                    <Grid item xs={6}>
                      <Typography variant="caption">{translations[lang].value}</Typography>
                      <Typography variant="body1">
                        <NumberFormat
                          value={document.value / 100}
                          displayType={'text'}
                          thousandSeparator="."
                          decimalSeparator=","
                          fixedDecimalScale="true"
                          decimalScale={2}
                        />
                      </Typography>
                    </Grid>

                    {
                      document.type !== 'PLATFORM_TRANSACTION' ?
                        (<Grid item xs={6}>
                          <Typography variant="caption">Status</Typography>
                          <div>
                            <StatusWidget lang={lang} status={document.status} />
                          </div>
                        </Grid>)
                        : null
                    }

                    {
                      document.fee ?
                        (<Grid item xs={6}>
                          <Typography variant="caption">{translations[lang].tax}</Typography>
                          <Typography variant="body1"><NumberFormat
                            value={document.fee / 100}
                            displayType={'text'}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale="true"
                          /></Typography>
                        </Grid>)
                        : null
                    }

                    {
                      document.brl_value ?
                        (<Grid item xs={6}>
                          <Typography variant="caption">Reais (BRL)</Typography>
                          <Typography variant="body1">R$ <NumberFormat
                            value={document.brl_value / 100}
                            displayType={'text'}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale="true"
                          /></Typography>
                        </Grid>)
                        : null
                    }

                    {
                      document.platform ?
                        (<Grid item xs={6}>

                          <Typography variant="caption">{translations[lang].platform}</Typography>
                          <Typography variant="body1">{document.platform.name || platform.name}</Typography>
                        </Grid>)
                        : null
                    }


                    {
                      document.identifier || document.nickname ?
                        (<Grid item xs={12} md={6}>
                          <Typography variant="caption">{translations[lang].identifier}</Typography>
                          <Typography variant="body1">{document.identifier || document.nickname}</Typography>
                        </Grid>)
                        : null
                    }
                    {
                      document.origin_user ?
                        (<Grid item xs={12} md={6}>
                          <Typography variant="caption">{translations[lang].origin}</Typography>
                          <Typography variant="body1">{document.origin_user_cognito.email}</Typography>
                        </Grid>)
                        : null
                    }
                    {
                      document.destination_user ?
                        (<Grid item xs={12} md={6}>
                          <Typography variant="caption">{translations[lang].destiny}</Typography>
                          <Typography variant="body1">{document.destination_user_cognito.email}</Typography>
                        </Grid>)
                        : null
                    }



                    {
                      document.comment ?
                        (<Grid item xs={12}>

                          <Typography variant="caption">{translations[lang].comment}</Typography>
                          <Typography variant="body1">{document.comment}</Typography>
                        </Grid>)
                        : null
                    }

                    {
                      me && me.role === 'ADMIN' && document.receipt ?
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <Typography variant="caption">{translations[lang].receipt}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <img style={{ width: 520 }} alt="Recibo" src={document.receipt} />
                          </Grid>
                        </Grid>
                        : null
                    }

                    {
                      me && me.role === 'ADMIN' && document.message ?
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <Typography variant="caption">{translations[lang].observation}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="caption">{document.message}</Typography>
                          </Grid>
                        </Grid>
                        : null
                    }
                    {
                      me && me.role === 'ADMIN' && document.history ?
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <Typography variant="caption">Histórico</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {
                              document.history.map(d => {
                                return (
                                  <div>
                                    {moment(d.date).format('MM/DD/YYYY - HH:mm')} - {d.action} por {d.admin_user.email}
                                  </div>
                                )
                              })
                            }
                          </Grid>
                        </Grid>
                        : null
                    }

                  </Grid>
                  {
                    me && me.role === 'ADMIN' && open ?
                      (
                        <Grid container spacing={3} style={{ marginTop: 32 }}>
                          {
                            document.status === 'PENDING' ?
                              (<Fragment>
                                {
                                  receipt ?
                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                      <img style={{ width: 520 }} alt="Recibo" src={receipt} />
                                    </Grid>
                                    : null
                                }
                                {
                                  document.type !== 'TRANSFER' ?
                                    <Fragment>
                                      <Grid item xs={12}>
                                        <TextField
                                          fullWidth
                                          label="Comentário"
                                          variant="filled"
                                          onChange={this.handleChange}
                                          name="comment"
                                          value={comment}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Button
                                          variant="contained"
                                          component="label"
                                          className={classes.fileInput}
                                        >
                                          {isUploading ? <CircularProgress /> : <AttachFileIcon />}
                                        Recibo
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={this.handleUploadFile}
                                          />
                                        </Button>
                                      </Grid>
                                    </Fragment>
                                    : null
                                }

                                <Grid item xs={6}>
                                  <Button variant="contained" fullWidth onClick={() => { this.handleAction('decline') }} color="primary"><b>Recusar</b></Button>
                                </Grid>
                                <Grid item xs={6}>
                                  <Button variant="contained" fullWidth onClick={() => { this.handleAction('approve') }} color="secondary"><b>Aprovar</b></Button>
                                </Grid>
                              </Fragment>
                              ) : null
                          }

                          {
                            ['APPROVED', 'DECLINED'].includes(document.status) ? (
                              <Grid item xs={12}>
                                {
                                  this.state.confirmCancelOpen ? (
                                    <div>
                                      <TextField
                                        fullWidth
                                        label="Escreva CANCELAR para confirmar"
                                        variant="filled"
                                        onChange={this.handleChange}
                                        name="confirmTerm"
                                        value={confirmTerm}
                                      />
                                      <Button variant="contained" onClick={() => { this.handleAction('cancel') }} fullWidth color="primary">Confirmar</Button>
                                    </div>
                                  ) : (
                                    <Button variant="contained" onClick={() => { this.handleConfirmCancel() }} fullWidth color="primary">Cancelar</Button>
                                  )
                                }
                              </Grid>
                            ) : null
                          }
                        </Grid>
                      ) : null
                  }
                </Grid>
              ) : null
            }

            <Grid item xs={12} style={{ textAlign: 'center', marginTop: 32 }}>
              <img src={process.env.PUBLIC_URL + "/senseiwallet-vertical.png"} alt="Sensei Wallet" style={{ height: 80 }} />
            </Grid>

          </Grid>
        </Container>
      </Drawer>
    )
  }
}

const mapStateToProps = ({ auth, platforms, lang, me }) => {
  return {
    me,
    auth,
    platforms,
    lang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePurchaseStatus: (id, action, body) => dispatch(updatePurchaseStatusFetchData(id, action, body)),
    updateSaleStatus: (id, action, body) => dispatch(updateSaleStatusFetchData(id, action, body)),
    updateTransferStatus: (id, action) => dispatch(updateTransferStatusFetchData(id, action)),
    getPlatforms: (p) => dispatch(getPlatformsFetchData(p)),
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SideView)))