import { routes } from './core/api';
import moment from 'moment';
import { Auth as AuthCognito } from 'aws-amplify';


export const getPlatformTransactions = (platform_transactions) => {
  return {
      type: 'GET_PLATFORM_TRANSACTIONS',
      platform_transactions
  }
}

export const createPlatformTransaction = (platform_transaction) => {
  return {
      type: 'CREATE_PLATFORM_TRANSACTIONS',
      platform_transaction
  }
}

export const getSafeBalance = (safe_balance) => {
  return {
      type: 'GET_SAFE_BALANCE',
      safe_balance
  }
}

export const getUsersBalance = (users_balance) => {
  return {
      type: 'GET_USERS_BALANCE',
      users_balance
  }
}

export const getResume = (resume) => {
  return {
      type: 'GET_RESUME',
      resume
  }
}

export function getPlatformsResumeFetchData() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.getResume, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Erro ao buscar o saldo do cofre');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(getResume(body))
          resolve(body)
        })
      })
    })
  }
}

export function getUsersBalanceFetchData() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.getUsersBalance, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Erro ao buscar o saldo do cofre');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(getUsersBalance(body))
          resolve(body)
        })
      })
    })
  }
}

export function getSafeBalanceFetchData() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.getBalance, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Erro ao buscar o saldo do cofre');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(getSafeBalance(body))
          resolve(body)
        })
      })
    })
  }
}
  
export function getPlatformTransactionsFetchData({page = 1, query = {}}) {

  let qs = '?';
  if(page) {
    qs += 'page='+page
  }

  if(query.platform==='all') {
    delete query.platform
  }
  if(query.user==='all') {
    delete query.user
  }

  if(query.startDate) {
    query.startDate = moment(query.startDate).toISOString()
  }

  if(query.endDate) {
    query.endDate = moment(query.endDate).toISOString()
  }
 
  var queryString = Object.keys(query).map((key) => {
      if(key && query[key]) return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
      return ''
  }).join('&');

  let route = routes.get+qs;
  if(queryString.length) {
    route += `&${queryString}`
  }
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
          
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Invalid credentials');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(getPlatformTransactions(body))
          resolve(body)
        })
      })
    })
  }
}

export function createSafeTransactionFetchData(body) {
  let safe_transaction = {
    ...body,
    value: Number(body.value) * 100
  }
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.create, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
          body: JSON.stringify(safe_transaction)
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Transação não realizada!');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(createPlatformTransaction(body))
          resolve(body)
        })
      })
    })
  }
}