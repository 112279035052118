import { routes } from './core/api';
import { Auth as AuthCognito } from 'aws-amplify';


export const getPlatforms = (platforms) => {
  return {
      type: 'GET_PLATFORMS',
      platforms
  }
}

export const createPlatform = (platform) => {
  return {
      type: 'CREATE_PLATFORM',
      platform
  }
}

export function createPlatformFetchData(body) {
  if(body.origin === 'none') {
    delete body.origin;
  }
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.create, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            },
          body: JSON.stringify(body)
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Plataforma não criada!');
           }
          return res.json() 
        })
        .then(body => {
          dispatch(createPlatform(body))
          resolve(body)
          
        })
      })
    })
  }
}

export function updateFeeFetchData(id, fee) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.create+id, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            },
          body: JSON.stringify({ fee })
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Plataforma não atualizada!');
           }
          return res.json() 
        })
        .then(body => {
          resolve({ id, fee })
        })
      })
    })
  }
}


export function updatePlatformFetchData(body, id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.create+id, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            },
          body: JSON.stringify({ ...body })
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Plataforma não atualizada!');
           }
          return res.json() 
        })
        .then(body => {
          resolve({ body })
        })
      })
    })
  }
}

export function getPlatformsFetchData({page = 1, query = {}, all = false}) {
  let qs = '?';
  if(page) {
    qs += 'page='+page
  }

  if(all) {
    qs += '&limit=999'
  }
  var queryString = Object.keys(query).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
  }).join('&');

  let route = routes.get+qs;
  if(queryString.length) {
    route += `&${queryString}`
  }
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken
            },
          
        })
        .then(res => { 
          if(res.status !== 200) { 
            reject('Invalid credentials');
          }
          return res.json() 
        })
        .then(body => {
          dispatch(getPlatforms(body))
          resolve(body)
        })
      })
    })
  }
}
