import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import CircularProgress from '@material-ui/core/CircularProgress'
import ForgotPassword from '../ForgotPassword'
import InsertCode from '../InsertCode'
import ResetSuccess from '../ResetSuccess'
import { Snackbar, Typography } from '@material-ui/core'
import { Auth as AuthCognito } from 'aws-amplify';

import translations from '../../../translations/translations'


class SignInForm extends Component {
  // static propTypes = {
  //   handleSubmit: PropTypes.func.isRequired,
  //   isFetching: PropTypes.bool.isRequired,
  //   username: PropTypes.string,
  // }

  state = {
    username: this.props.username || '',
    password: '',
    emailToResetPassword: '',
    forgotPasswordOpen: false,
    forgotPasswordFetching: false,
    resetSuccessOpen: false,
    insertCodeOpen: false,
    snackbarOpen: false,
    snackMessage: '',
    horizontal: 'center',
    vertical: 'bottom'
  }

  reset() {
    this.setState((state) => ({
      username: '',
      password: '',
    }))
  }

  sendResetPasswordCode = (email) => {
    this.setState({
      forgotPasswordFetching: true,
      emailToResetPassword: email
    })

    AuthCognito.forgotPassword(email)
      .then((res) => {
        this.setState({
          forgotPasswordFetching: false
        })
        this.handleCloseModalForgotPassword()
        this.handleOpenModalInsertResetPasswordCode()
      })
      .catch(err => {
        this.setState({
          forgotPasswordFetching: false,
          snackbarOpen: true,
          snackMessage: err.message
        })
      })
  }

  submitNewPassword = ({ email, resetPasswordCode, newPassword }) => {
    this.setState({
      forgotPasswordFetching: true
    })

    AuthCognito.forgotPasswordSubmit(email, resetPasswordCode, newPassword)
      .then((res) => {
        this.setState({
          forgotPasswordFetching: false
        })
        this.handleCloseModalInsertResetPasswordCode()
        this.handleOpenModalResetSuccess()
      })
      .catch(err => {
        this.setState({
          forgotPasswordFetching: false,
          snackbarOpen: true,
          snackMessage: err.message
        })
      })
  }


  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  handleOpenModalForgotPassword = () => {
    this.setState({ forgotPasswordOpen: true })
  }

  handleCloseModalForgotPassword = () => {
    this.setState({ forgotPasswordOpen: false })
  }

  handleOpenModalInsertResetPasswordCode = () => {
    this.setState({ insertCodeOpen: true })
  }

  handleCloseModalInsertResetPasswordCode = () => {
    this.setState({ insertCodeOpen: false })
  }

  handleOpenModalResetSuccess = () => {
    this.setState({ resetSuccessOpen: true })
  }

  handleCloseModalResetSuccess = () => {
    this.setState({ resetSuccessOpen: false })
  }

  handleSubmit = () => {
    this.props.handleSubmit(this.state, () => this.reset())
  }

  render() {
    const { username, password, vertical, horizontal } = this.state
    const { isFetching, lang } = this.props

    return (
      <div>

        <ValidatorForm
          ref="form"
          onSubmit={this.handleSubmit}
          style={{ width: '100%' }}
        >
          <TextValidator
            fullWidth
            label={translations[lang].email}
            onChange={this.handleChange}
            name="username"
            value={username}
            validators={['required']}
            errorMessages={[translations[lang].error_required]}
            margin="normal"
          />
          <TextValidator
            fullWidth
            label={translations[lang].password}
            type='password'
            onChange={this.handleChange}
            name="password"
            value={password}
            validators={['required']}
            errorMessages={[translations[lang].error_required]}
            margin="normal"
          />
          {
            this.props.telegramMessage && (
              <div style={{textAlign: 'left', color: 'red', padding: 8, border: '2px solid red', marginTop:8}}>
                <Typography style={{fontWeight:'bold'}} variant='caption'>
                  ATENÇÃO: Fizemos uma migração no sistema. Se estiver com problemas para acessar, fale com a gente <a style={{textDecoration:'underline'}} href="https://t.me/suportesenseipoker" target="_blank" rel="noreferrer">clicando aqui</a> ou enviando uma mensagem para @suportesenseipoker no Telegram
                </Typography>
              </div>
            )
          }
          <Button
            variant="contained"
            fullWidth
            color="primary"
            type="submit"
            disabled={isFetching}
            margin="normal"
            style={{ marginTop: 16 }}
          >
            {isFetching && <CircularProgress color='inherit' size={20} />} <b>{translations[lang].login}</b>
          </Button>
        </ValidatorForm>
        
        {/* MODAIS DE REDEFINIÇÃO DE SENHA */}
        <ForgotPassword open={this.state.forgotPasswordOpen} handleClose={this.handleCloseModalForgotPassword} isFetching={this.state.forgotPasswordFetching} handleSubmit={(data) => this.sendResetPasswordCode(data)} />

        <InsertCode open={this.state.insertCodeOpen} handleClose={this.handleCloseModalInsertResetPasswordCode} email={this.state.emailToResetPassword} isFetching={this.state.forgotPasswordFetching} handleSubmit={(data) => this.submitNewPassword(data)} />

        <ResetSuccess open={this.state.resetSuccessOpen} handleClose={this.handleCloseModalResetSuccess} />

        {/* SNACKBAR */}
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
          open={this.state.snackbarOpen}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.snackMessage}</span>}
        />
      </div>
    )
  }
}

export default SignInForm