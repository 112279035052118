        
import React, { Component } from 'react'
import { createPlatformFetchData } from './actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import PlatformForm from './PlatformForm';
import Big from 'big.js';


class PlatformCreate extends Component {

  state = { 
    isFetching: false,
    didInvalidate: false,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center'
  }

  createPlatform({ name, origin, identifier, sale, purchase, sale_limit, purchase_identifier, fee }) {
    this.setState((state) => ({
      isFetching: true,
      didInvalidate: false,
    }))
    this.props.createPlatform({ name, origin, identifier, sale, purchase, purchase_identifier, fee, sale_limit: Big(sale_limit).times(100).toNumber() })
    .then(body => {
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: false,
      }))
      
      this.props.closeCreate('SUCCESS');
    })
    .catch(ex => {
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: true,
        snackbarOpen: true,
        snackMessage: ex
      }))
    })
  }

  handleClose = () => {
    this.setState((state) => ({ ...state, snackbarOpen: false }));
  };

  render() {
    const { isFetching, vertical, horizontal, snackbarOpen, snackMessage } = this.state
    const { platforms } = this.props;
    return (
        <div>
            <PlatformForm 
              handleSubmit={(data) => this.createPlatform(data)}
              platforms={platforms}
              isFetching={isFetching}
            />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                key={`${vertical},${horizontal}`}
                open={snackbarOpen}
                onClose={this.handleClose}
                ContentProps={{
                  'aria-describedby': 'message-id',
                }}
              message={<span id="message-id">{snackMessage}</span>}
            />
        </div>
    )
  }
}

const mapStateToProps = ({ auth, platforms }) => {
  return {
    auth,
    platforms
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createPlatform: (platform) => dispatch(createPlatformFetchData(platform))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlatformCreate))

      