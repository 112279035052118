import React, { Component } from 'react'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import { Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css'


class InsertCode extends Component {

  state = {
    resetPasswordCode: '',
    newPassword: '',
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  render() {
    const {newPassword, resetPasswordCode} = this.state
    const { open, handleClose, handleSubmit, isFetching, isCompletingNewPassword, email } = this.props

    return (
      <Dialog open={open} onClose={handleClose} PaperProps={{style:{margin: 'auto', width: '400px', padding:'16px', textAlign:'center'}}}>
        <Typography style={{fontWeight: 'bold', marginBottom: 8}} padding="20px 20px 0 20px">
            INFORME O CÓDIGO
        </Typography>
        <Typography variant='caption'>Informe aqui o código que te enviamos no email <strong>{email}</strong> e resete sua senha </Typography>
        <ValidatorForm
          style={{
            margin: 'auto'
          }}
          ref="form"
          onSubmit={()=>handleSubmit({email, resetPasswordCode, newPassword})}
        >
          <TextValidator
          fullWidth
          value={this.state.resetPasswordCode}
          label="Código"
          type='text'
          onChange={this.handleChange}
          name="resetPasswordCode"
          validators={['required']}
          errorMessages={['Preencha esse campo']}
          margin="dense"
          // InputProps={{ classes: { root: classes.input } }}
          // InputLabelProps={{
          //   classes: {
          //     root: classes.label
          //   }
          // }}
          />
          <TextValidator
          fullWidth
          value={this.state.newPassword}
          label="Nova Senha"
          type='password'
          onChange={this.handleChange}
          name="newPassword"
          validators={['required']}
          errorMessages={['Preencha esse campo']}
          margin="dense"
          // InputProps={{ classes: { root: classes.input } }}
          // InputLabelProps={{
          //   classes: {
          //     root: classes.label
          //   }
          // }}
          />
          <Button 
            variant="contained"
            fullWidth
            color="primary"
            type="submit"
            disabled={isCompletingNewPassword}
            margin="normal"
            style={{ marginTop: 16 }}
          >
            {isFetching && <CircularProgress color='inherit' size={20} />} <b>Resetar Senha</b>
        </Button>
        </ValidatorForm>
      </Dialog>
    )
  }
}

export default InsertCode
