import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import UserBar from '../auth/UserBar'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import { Drawer, Card, Typography, CardContent, Snackbar, Button} from '@material-ui/core';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import PurchaseCreate from './PurchaseCreate';
import BasicList from '../common/BasicList';
import { getPurchasesFetchData } from './actions'
import { getPlatformsFetchData } from '../platforms/actions'
import Pagination from '@material-ui/lab/Pagination';
import SideView from '../common/SideView';
import BasicFilters from '../common/BasicFilters';
import { getUsersFetchData, getFiltersFetchData, getPlatformRulesFetchData } from '../users/actions';
import _ from 'lodash'
import moment from 'moment'
import translations from '../../translations/translations';

class Purchases extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  state = {
    anchorEl: null,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center',
    openShow: false
  }

  getPurchases({ page, query }) {
    this.props.getPurchases({ page, query })
  }

  componentDidMount() {
    this.props.getPlatforms({ all: true })
    this.props.getPlatformRules()
    const search = this.parseQueryParams()
    const filters = {
      startDate: moment().subtract(7, 'days').toDate(),
      endDate: moment().toDate(),
      platform: 'all',
      user: 'all',
      status: 'all'
    }

    if(search) {
      this.setFilters({
        ...filters,
        ...search
      })
    }
  }

  parseQueryParams() {
    return _.chain(this.props.location.search)
    .replace('?', '') // a=b454&c=dhjjh&f=g6hksdfjlksd
    .split('&') // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
    .map(_.partial(_.split, _, '=', 2)) // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
    .fromPairs() // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
    .value()
  }

  setFilters(filters) {
    const { startDate, endDate } = filters;
    if(startDate) {
      filters.startDate = moment(startDate, 'DD/MM/YYYY').utc().toDate()
    }
    if(endDate) {
      filters.endDate = moment(endDate, 'DD/MM/YYYY').utc().toDate()
    }

    this.props.getFilters(({
      ...this.props.filters,
      ...filters
    }))

    this.handlePaginate()
    
  }

  toggleCreate = (createType, success = false) => {
    this.setState((state) => {
      if(state.openCreate) {
        this.handlePaginate()
      }
      return ({ openCreate: !state.openCreate })
    })
    if(['PURCHASE'].includes(createType)) {
      this.setState((state) => ({ createType }))
    }
    
    if(success === true) {
      this.setState(() => ({
        snackMessage: translations[this.props.lang].purchase_success,
        snackbarOpen: true
      }))
    } 
  }
  
  toggleShow = (document) => {
    this.setState((state) => ({ openShow: !state.openShow, document }))
  }

  updatePurchase = (document) => {
    this.setState((state) => ({ 
      openShow: false,  
      snackMessage: translations[this.props.lang].purchase_success,
      snackbarOpen: true 
    }))
    this.handlePaginate()
  }

  handleCreate = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleCloseSnackbar = () => {
    this.setState((state) => ({ ...state, snackbarOpen: false }));
  };

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handlePaginate = (event, value) => {
    const { purchases } = this.props
    const { page } = purchases
    this.getPurchases({ page: value || page, query: this.props.filters })
  }

  render() {
    const { auth, purchases, platforms, users, filters, lang, me } = this.props
    const { docs = [], totalPages = 1, page = 1 } = purchases;

    const { openCreate, openShow, createType, vertical, horizontal, snackbarOpen, snackMessage, document } = this.state
    if(Object.keys(auth).length === 0){
      this.props.history.push('/login')
    }

    const SideCreate = ({ open, close }) => {
      const form = {
        'PURCHASE': { title: translations[lang].purchase_create, component: <PurchaseCreate closeCreate={close} platforms={platforms} /> }
      }

      if (!createType) return null

      return (
        <Drawer open={open} onClose={close} anchor='top' >
          <Container style={{ padding: "16px 8px"}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">{form[createType]['title']}</Typography>
                <Typography variant="body1">{translations[lang].transaction_info}</Typography>
              </Grid>
              <Grid item xs={12}>
                {
                  form[createType]['component']
                }
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      )
    }

    return (
      <div>
        <UserBar auth={auth} />
        <SideCreate open={openCreate} close={this.toggleCreate}/>
        <SideView open={openShow} close={this.toggleShow} updated={this.updatePurchase} document={document}/>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h5' style={{ marginTop: 8 }}>{translations[lang].purchases}</Typography>
            </Grid>
            {
              me && ['ADMIN','USER'].includes(me.role) ?
              <Grid item xs={12}>
                <Button onClick={() => this.toggleCreate('PURCHASE')} variant="contained" color="primary" size="large" fullWidth>
                  <CallReceivedIcon style={{ fontSize: 16, marginRight: 8 }} />
                  <Typography variant="body1">{translations[lang].purchase_action}</Typography>
                </Button>
              </Grid>
              : null
            }
            <Grid item xs={12}>
              <BasicFilters users={users.docs || users} platforms={platforms.docs || platforms} filters={filters} setFilters={(query) => { this.getPurchases({ page: 1, query }) }}/>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                      <Grid item xs={12}>
                        <BasicList docs={docs} toggleShow={this.toggleShow} type="PURCHASE"/>
                      </Grid>
                      <Grid item style={{ textAlign: 'center' }} xs={12}>
                        <div>
                          <Pagination style={{ margin: '16px auto', display: 'inline-block' }} count={totalPages} page={page} color="primary" onChange={this.handlePaginate} />
                        </div>
                      </Grid>
                    </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              key={`${vertical},${horizontal}`}
              open={snackbarOpen}
              onClose={this.handleCloseSnackbar}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
            message={<span id="message-id">{snackMessage}</span>}    />
        </Container>
      </div>
    )
  }
}


const mapStateToProps = ({ auth, purchases, platforms, filters, users, lang, me }) => {
  return {
    auth,
    me,
    users,
    purchases,
    platforms,
    filters,
    lang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPurchases: (purchase) => dispatch(getPurchasesFetchData(purchase)),
    getPlatforms: () => dispatch(getPlatformsFetchData({})),
    getUsers: (query) => dispatch(getUsersFetchData(query)),
    getFilters: (filters) => dispatch(getFiltersFetchData(filters)),
    getPlatformRules: () => dispatch(getPlatformRulesFetchData({})),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Purchases)