import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, TableContainer, TableBody, TableCell, TableRow, TableHead, Table } from '@material-ui/core'
import moment from 'moment'
import NumberFormat from 'react-number-format'

class PlatformTransactionList extends Component {

  getTransactionUserName = (transaction) => {
    let { sale, purchase, user } = transaction;
    if(!user) {
      if(sale) {
        user = sale.user
      }
      if(purchase) {
        user = purchase.user
      }
    }

    return user.nickname;
  }

  render() {
    const { docs = [] } = this.props
    const colors = {
      'C': 'green',
      'D': 'red'
    }

    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Usuário</TableCell>
              <TableCell>Origem</TableCell>
              <TableCell>Plataforma</TableCell>
              <TableCell align="right">Valor</TableCell>
              <TableCell align="right">Saldo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { docs.length ? docs.map(transaction => (
                  <TableRow key={transaction._id}>
                      <TableCell>{moment(transaction.created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
                      <TableCell>
                        { transaction.user ? transaction.user.nickname : '' }
                      </TableCell>
                      <TableCell>
                        <OriginLink transaction={transaction} toggleShow={this.props.toggleShow} />
                      </TableCell>
                      <TableCell>
                        {transaction.platform.name}
                      </TableCell>
                      <TableCell align="right">
                        <NumberFormat 
                          style={{ color: colors[transaction.type] }}
                          value={transaction.value/100} 
                          displayType={'text'} 
                          prefix={transaction.type === 'D' ? '-' : '+'}
                          thousandSeparator="."
                          decimalSeparator=","
                          fixedDecimalScale="true"
                        />
                      </TableCell>
                      <TableCell align="right">
                        {
                          transaction.new_balance? (
                            <NumberFormat 
                              value={transaction.new_balance/100} 
                              displayType={'text'} 
                              thousandSeparator="."
                              decimalSeparator=","
                              fixedDecimalScale="true"
                            />
                          ) : (
                            <span>-</span>
                          )
                        }
                      </TableCell>
                    </TableRow>
            )) : <TableRow>
              <TableCell>Não há registros.</TableCell>
            </TableRow> }
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

const OriginLink = ({ transaction, toggleShow }) => {
  const { sale, purchase, user, platform } = transaction;
  let text = ''
  let document = {}
  if(user) {
    document = transaction;
    const linkLabel = {
      C: 'DEPÓSITO',
      D: 'SAQUE'
    }
    text = linkLabel[transaction.type]
    document.type = 'PLATFORM_TRANSACTION'
  }
  
  if(sale) {
    document = sale;
    text = 'ENVIO'
    document.type = 'SALE'
  }
  if(purchase) {
    document = purchase;
    text = 'RECEBIMENTO'
    document.type = 'PURCHASE'
  }
  return (<Button size="small" color="primary" variant="contained" onClick={() => { toggleShow(document, user, platform) }}>{text}</Button>)
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  }
}


export default withRouter(connect(mapStateToProps)(PlatformTransactionList))