import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Grid, InputAdornment } from '@material-ui/core'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import CircularProgress from '@material-ui/core/CircularProgress'
import NumberFormat from 'react-number-format';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale="true"
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

class DollarPriceForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired
  }

  state = {
    sale_value: '',
    purchase_value: '',
  }

  reset() {
    this.setState((state) => ({
      sale_value: '',
      purchase_value: ''
    }))
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }


  handleSubmit = () => {
    this.props.handleSubmit(this.state, () => this.reset())
  }

  render() {
    const { sale_value, purchase_value } = this.state
    const { isFetching } = this.props

    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
        
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextValidator
                fullWidth
                label="Preço de envio"
                variant="filled"
                onChange={this.handleChange}
                name="sale_value"
                value={sale_value}
                validators={['required']}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
                errorMessages={['Campo obrigatório']}
              />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
                fullWidth
                label="Preço de recebimento"
                variant="filled"
                onChange={this.handleChange}
                name="purchase_value"
                value={purchase_value}
                validators={['required']}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
                errorMessages={['Campo obrigatório']}
              />
          </Grid>
          
          <Grid item xs={12}>
          <Button 
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              disabled={isFetching}
              style={{ height: 55 }}
            >
              {isFetching && <CircularProgress size={20} />} Salvar
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    )
  }
}

export default DollarPriceForm;