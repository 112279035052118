
import Auth2 from '../../../utils/auth';
const baseApiAdmin = `${process.env.REACT_APP_API_URL}/admin/dollar-prices`
const baseApi = `${process.env.REACT_APP_API_URL}/dollar-prices`


export const headers = () => { 
  const token = Auth2.getToken()
  return new Headers({
    'Content-Type': 'application/json',
    'Authorization': token
  }) 
}

export const routes = {
  adminGet: `${baseApiAdmin}`,
  userGet: `${baseApi}`,
} 

