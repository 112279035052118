import React, { Component } from 'react'
import { Dialog, Typography, Grid, Button, CircularProgress } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import translations from '../../translations/translations';


class TokenSignin extends Component {

  state = {
    answer: ''
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  render() {
    const { isOpen, handleClose, handleSubmit, isFetching, user, lang } = this.props
    const { answer } = this.state

    return (
      <Dialog PaperProps={{ style: { padding: 16 } }} open={isOpen} onClose={handleClose}>
        <Typography variant='body1' padding="20px 20px 0 20px" style={{ textAlign: "center", marginBottom: 16 }}>
          {translations[lang].two_factor_info}
        </Typography >
        <Grid container>
          <Grid item xs={12}>
            <ValidatorForm
              ref="form"
              onSubmit={() => handleSubmit(user, answer)}
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    label={translations[lang].code}
                    variant="filled"
                    onChange={this.handleChange}
                    name="answer"
                    value={answer}
                    validators={['required']}
                    errorMessages={[translations[lang].error_required]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    type="submit"
                    disabled={isFetching}
                    style={{ height: 55 }}
                  >
                    {isFetching && <CircularProgress size={20} />} {translations[lang].login}
                  </Button>
                </Grid>
              </Grid>

            </ValidatorForm>

          </Grid>

        </Grid>
      </Dialog>
    )
  }
}

export default TokenSignin
