import React from "react"

const translations = {
    br: {
        error_verified: 'Erro ao fazer verificação da sua identidade, tente novamente.',
        success_verified: 'Sua identidade está sendo verificada, obrigado!',
        go_homepage: 'Ir para página inicial',
        confirm_id_message: 'Você ainda não confirmou sua identidade. Por favor clique no botão abaixo para confirmar sua identidade.',
        confirm_id_button: 'Verificar identidade',
        help: 'Ajuda',
        agree: 'Concordo',
        password: 'Senha',
        password_updated: 'Senha alterada com sucesso!',
        wrong_code: 'Código incorreto!',
        two_factor_error: 'Não foi possivel desabilitar o 2FA!',
        two_factor_activate: '2FA habilitado com sucesso!',
        two_factor_deactivate: '2FA desabilitado com sucesso!',
        two_factor_info: 'Digite seu código de autenticação dois fatores (2FA) do Google Authenticator',
        finish_info: 'Agora, você pode fazer ou logar com sua senha nova!',
        registration_finish: 'Cadastro concluído',
        registration_info: 'Informe seus dados e crie uma nova senha',
        complete_registration: 'Complete seu cadastro',
        account_confirmed: 'Conta confirmada!',
        password_requirements: 'A senha deve contar pelo menos 8 dígitos, maiúsculas e minúsculas',
        confirmed_info: 'Faça seu login para acessar sua SenseiWallet',
        older_declaration: (name) => {
            return `Eu, ${name}, declaro que tenho mais de 18 anos`
        },
        error_required: 'Campo obrigatório',
        insert_code: 'Informe aqui o código que te enviamos no seguinte e-mail:',
        confirm_account: 'Confirme sua conta',
        password_info: () => {
            return (<span>Sua senha deve conter pelo menos:<br></br><b>*</b> 8 caracteres<br></br><b>*</b> Letras maiúsculas e minúsculas</span>)
        },
        signup_info: 'Preencha os campos abaixo para criar a carteira!',
        notice: 'Comunicado',
        alert: 'Alerta',
        news: 'Novidade',
        sales: "Envios",
        sale: "Envio",
        sale_action: "Enviar",
        sale_create: "Criar envio",
        sale_recents: "Envios recentes",
        sale_all: "Todos envios",
        username: "Nome de usuário",
        login: "Entrar",
        purchases: "Recebimentos",
        purchase: "Recebimento",
        purchase_action: "Receber",
        purchase_create: "Criar recebimento",
        purchase_recents: "Recebimentos recentes",
        purchase_all: "Todos recebimentos",
        
        transfers: "Transferências",
        transfer: "Transferência",
        transfer_action: "Transferir",
        transfer_create: "Criar transferência",
        transfer_recents: "Transferências recentes",
        transfer_all: "Todas transferências",

        start_date: "Início",
        end_date: "Fim",
        deactivate_2fa: "Desativar 2FA",
        activate_2fa: "Ativar 2FA",
        
        transactions_info: "Todas as transações requerem aprovação de um usuário administrador",
        profile: "Perfil",
        wallet: "Carteira",
        home: "Início",
        exit: "Sair",
        account: "Conta",
        balance: "Saldo",
        date: "Data",
        origin: "Origem",
        destiny: "Destino",
        value: "Valor",
        status: "Status",
        platform: "Plataforma",
        no_results: "Não há registros.",
        tax: "Taxa",
        download_report: "Baixar relatório",
        
        all: "Todos",
        pending: "Pendente",
        approved: "Aprovado",
        declined: "Recusado",
        canceled: "Cancelado",
        identifier_description: (description, platform, identifier) => {
            return `Nosso ${description} na ${platform}: ${identifier}`
        },
        form_info: (platform, identifier) => {
            return `Para efetivar sua transação na ${platform}, informe seu ${identifier} no  campo Identificador`
        },
        usdt_info: <span>Para facilitar a identificação utilize a casa dos centavos. Exemplo: $200,<b>87</b></span>,
        purchase_info: 'ATENÇÃO! Antes de concluir o preenchimento deste formulário, é necessário nos enviar o valor pretendido através da plataforma da sua escolha. Ao escolher a plataforma logo abaixo, será informado o identificador da nossa conta nesta plataforma, para onde você deve enviar o dinheiro.',
        sale_success: 'Envio atualizado com sucesso!',
        purchase_success: 'Recebimento atualizado com sucesso!',
        identifier: 'Identificador',
        observation: 'Observação',
        comment: 'Comentário',
        save: 'Salvar',
        name: 'Nome',
        phone: 'Telefone',
        email: 'E-mail',
        new_password: 'Nova senha',
        change_password: 'Alterar senha',
        cancel: 'Cancelar',

        view: 'Visualizar',
        user: 'Usuário',
        receipt: 'Recibo',
        current_password: 'Senha atual',
        google_auth_info: 'Utilize o Google Authenticator para gerar seu código 2FA',
        code: 'Código',
        create_wallet: 'Crie sua SenseiWallet',
        wallet_info: 'Comece a movimentar suas fichas de forma segura e desburocratizada hoje mesmo!'
    },
    ar: {
        usdt_info: <span>Para facilitar la identificación utilice centavos. Ejemplo: $200,<b>87</b></span>,
        confirm_id_message: 'Aún no ha verificado su identidad. Haga clic en el botón de abajo para confirmar su identidad.',
        confirm_id_button: 'Verificar identidad',
        error_verified: 'Error al verificar tu identidad, inténtalo de nuevo.',
        success_verified: 'Se está verificando su identidad, ¡gracias!',
        go_homepage: 'Ir a la página de inicio',
        help: 'Ayuda',
        agree: 'Acuerdo',
        password: 'Contraseña',
        password_updated: '¡Contraseña alterada con éxito!',
        password_requirements: 'La contraseña debe tener al menos 8 dígitos, mayúsculas y minúsculas',
        wrong_code: '¡Código incorrecto!',
        two_factor_error: '¡No fue posible deshabilitar 2FA!',
        two_factor_activate: '2FA habilitado con éxito!',
        two_factor_deactivate: '2FA desactivado con éxito!',
        two_factor_info: 'Ingrese su código de autenticación de dos factores de Google Authenticator (2FA)',
        finish_info: '¡Ahora puede iniciar sesión con su nueva contraseña!',
        registration_finish: 'Registro completado',
        registration_info: 'Ingrese sus datos y cree una nueva contraseña',
        complete_registration: 'Completa tu registro',
        account_confirmed: 'Cuenta confirmada!',
        confirmed_info: 'Inicie sesión para acceder a su SenseiWallet',
        older_declaration: (name) => {
            return `Yo, ${name}, declaro que soy mayor de 18 años.`
        },
        error_required: 'Campo obligatorio',
        insert_code: 'Ingresa aquí el código que te enviamos en el siguiente correo electrónico:',
        confirm_account: 'Confirme su cuenta',
        password_info: () => {
            return (<span>Tu contraseña debe contener al menos:<br></br><b>*</b> 8 caracteres<br></br><b>*</b> Letras mayúsculas y minúsculas</span>)
        },
        signup_info: '¡Complete los campos a continuación para crear SenseiWallet!',
        login: "Entra",
        username: "Nombre de usuario",
        notice: 'Comunicación',
        alert: 'Alerta',
        news: 'Noticia',
        sale: "Envío",
        sales: "Envíos",
        sale_action: "Enviar",
        purchase_action: "Recibir",
        transfer_action: "Transferir",
        sale_create: "Criar envio",
        purchase_create: "Crear recibo",
        transfer_create: "Crear transferencia",
        sale_recents: "Envíos recentes",
        purchase_recents: "Recibos recientes",
        transfer_recents: "Transferencias recientes",
        purchase: "Recibo",
        purchases: "Recibos",
        transfer: "Transferencia",
        transfers: "Transferencias",
        profile: "Perfil",
        wallet: "Cartera",
        home: "Home",
        exit: "Salir",
        account: "Cuenta",
        balance: "Saldo",
        sale_all: "Todos los envios",
        purchase_all: "Todos los recibos",
        transfer_all: "Todas las transferencias",
        date: "Data",
        origin: "Origen",
        destiny: "Destino",
        value: "Valor",
        status: "Status",
        platform: "Plataforma",
        no_results: "No hay resultados.",
        tax: "Taxa",
        download_report: "Descargar informe",
        start_date: "Comienzo",
        end_date: "Fin",
        all: "Todos",
        pending: "Pendiente",
        approved: "Aprobado",
        declined: "Rechazado",
        canceled: "Cancelado",
        identifier_description: (description, platform, identifier) => {
            return `Nuestro ${description} en ${platform}: ${identifier}`
        },
        form_info: (platform, identifier) => {
            return `Para completar su transacción en ${platform}, ingrese su ${identifier} en el campo Identificador`
        },
        purchase_info: '¡ATENCIÓN! Antes de completar este formulario, es necesario que nos envíe la cantidad deseada a través de la plataforma de su elección. Al elegir la plataforma a continuación, se le informará el identificador de nuestra cuenta en esta plataforma, a donde debe enviar el dinero.',
        transactions_info: "Todas las transacciones requieren la aprobación de un usuario administrador",
        identifier: 'Identificador',
        observation: 'Observación',
        comment: 'Comentario',
        save: 'Ahorrar',
        name: 'Nome',
        phone: 'Telefone',
        email: 'E-mail',
        new_password: 'Nueva contraseña',
        change_password: 'Cambiar contraseña',
        cancel: 'Cancelar',

        view: 'Ver',
        user: 'Usuario',
        receipt: 'Recibo',
        current_password: 'Contraseña actual',
        deactivate_2fa: "Desactivar 2FA",
        activate_2fa: "Activar 2FA",
        google_auth_info: 'Utilice Google Authenticator para generar su código 2FA',
        code: 'Código',
        create_wallet: 'Crie sua SenseiWallet',
        wallet_info: '¡Empiece a mover sus fichas de forma segura y sin burocratización hoy mismo!'
    },
    us: {
        usdt_info: <span>To facilitate identification use cents. Example: $200,<b>87</b></span>,
        confirm_id_message: 'You have not yet verified your identity. Please click the button below to confirm your identity.',
        confirm_id_button: 'Verify identity',
        error_verified: 'Error verifying your identity, please try again.',
        success_verified: 'Your identity is being verified, thank you!',
        go_homepage: 'Go to homepage',
        help: 'Help',
        agree: 'Agree',
        password: 'Password',
        password_updated: 'Password updated!',
        password_requirements: 'Password must contain at least 8 digits, uppercase and lowercase',
        wrong_code: 'Wrong code!',
        two_factor_error: '2FA could not be disabled!',
        two_factor_activate: '2FA enabled successfully!',
        two_factor_deactivate: '2FA disabled successfully!',
        two_factor_info: 'Enter your Google Authenticator two-factor authentication code (2FA)',
        finish_info: 'Now, you can login with your new password!',
        registration_finish: 'Registration completed',
        registration_info: 'Enter your details and create a new password',
        complete_registration: 'Complete your registration',
        account_confirmed: 'Account confirmed!',
        confirmed_info: 'Log in to access your SenseiWallet',
        insert_code: 'Enter here the code we sent you in the following email:',
        confirm_account: 'Confirm your account',
        password_info: () => {
            return (<span>Your password must contain at least:<br></br><b>*</b> 8 characters<br></br><b>*</b> Uppercase and lowercase letters</span>)
        },
        signup_info: 'Fill in the fields below to create your SenseiWallet!',
        login: "Login",
        username: "Username",
        notice: 'Notice',
        alert: 'Warning',
        news: 'News',
        sale: "Send",
        sales: "Sends",
        sale_action: "Send",
        purchase_action: "Receive",
        transfer_action: "Transfer",
        sale_recents: "Recent sends",
        purchase_recents: "Recent receives",
        transfer_recents: "Recent transfers",
        sale_create: "Create send",
        purchase_create: "Create receive",
        transfer_create: "Create transfer",
        purchase: "Receive",
        purchases: "Receives",
        transfer: "Transfer",
        transfers: "Transfers",
        profile: "Profile",
        wallet: "Wallet",
        home: "Home",
        exit: "Logout",
        account: "Account",
        balance: "Balance",
        sale_all: "All sends",
        purchase_all: "All receives",
        transfer_all: "All transfers",
        date: "Date",
        origin: "Origin",
        destiny: "Destiny",
        value: "Value",
        status: "Status",
        platform: "Platform",
        tax: "Tax",
        no_results: "No results.",
        download_report: "Download report",
        start_date: "Start",
        end_date: "End",
        all: "All",
        pending: "Pending",
        approved: "Approved",
        declined: "Declined",
        canceled: "Canceled",
        identifier_description: (description, platform, identifier) => {
            return `Our ${description} on ${platform}: ${identifier}`
        },
        form_info: (platform, identifier) => {
            return `To complete your transaction on ${platform}, enter your ${identifier} in the Identifier field`
        },
        purchase_info: 'ATTENTION! Before completing this form, it is necessary to send us the desired amount through the platform of your choice. When choosing the platform below, you will be informed the identifier of our account on this platform, where you should send the money to.',
        transactions_info: "All transactions require approval by an administrator user",
        identifier: 'Identifier',
        observation: 'Comment',
        save: 'Save',
        comment: 'Comment',
        name: 'Name',
        phone: 'Phone',
        email: 'Email',
        new_password: 'New password',
        change_password: 'Update password',
        cancel: 'Cancel',

        view: 'View',
        user: 'User',
        receipt: 'Receipt',
        current_password: 'Current password',
        deactivate_2fa: "Deactivate 2FA",
        activate_2fa: "Activate 2FA",
        google_auth_info: 'Use Google Authenticator to generate your 2FA code',
        code: 'Code',
        create_wallet: 'Create your SenseiWallet',
        wallet_info: 'Start moving your chips safely and unbureaucratized today!',
        error_required: 'Required Field',
        older_declaration: (name) => {
            return `I, ${name}, declare that I am over 18 years old.`
        },
    }
};
export default translations;