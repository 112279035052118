        
import React, { Component } from 'react'
import { updatePlatformFetchData } from './actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import PlatformForm from './PlatformForm';
import Big from 'big.js';


class PlatformEdit extends Component {

  state = { 
    isFetching: false,
    didInvalidate: false,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center'
  }

  updatePlatform({ name, origin, identifier, sale, purchase, purchase_identifier, sale_limit, fee, _id }) {
    this.setState((state) => ({
      isFetching: true,
      didInvalidate: false,
    }))
    
    this.props.updatePlatform({ name, origin, identifier, sale, purchase, purchase_identifier, fee, sale_limit: Number(Big(sale_limit).times(100)) }, _id)
    .then(body => {

      this.setState((state) => ({
        isFetching: false,
        didInvalidate: false,
      }))

      this.props.closeUpdate('SUCCESS');
    })
    .catch(ex => {
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: true,
        snackbarOpen: true,
        snackMessage: ex
      }))
    })
  }



  handleClose = () => {
    this.setState((state) => ({ ...state, snackbarOpen: false }));
  };

  componentDidMount() {
    const { platform } = this.props;
    this.setState({ platform });
  }

  render() {
    const { isFetching, vertical, horizontal, snackbarOpen, snackMessage } = this.state
    const { platforms, platform } = this.props;
    return (
        <div>
            <PlatformForm 
              handleSubmit={(data) => this.updatePlatform(data)}
              platforms={platforms}
              platform={platform}
              isFetching={isFetching}
            />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                key={`${vertical},${horizontal}`}
                open={snackbarOpen}
                onClose={this.handleClose}
                ContentProps={{
                  'aria-describedby': 'message-id',
                }}
              message={<span id="message-id">{snackMessage}</span>}
            />
        </div>
    )
  }
}

const mapStateToProps = ({ auth, platforms }) => {
  return {
    auth,
    platforms
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePlatform: (platform, id) => dispatch(updatePlatformFetchData(platform, id))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlatformEdit))

      