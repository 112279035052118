import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { getUserBalanceFetchData, getMeFetchData } from '../users/actions'
import { getRecentDollarPricesFetchData } from '../dollar-prices/actions';
import { changeLang } from '../auth/actions'
import NumberFormat from 'react-number-format'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { Link } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider, Grid } from '@material-ui/core'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import SendIcon from '@material-ui/icons/Send';
import TransformIcon from '@material-ui/icons/Transform';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import HomeIcon from '@material-ui/icons/Home';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import { getMyNoticesFetchData } from '../notices/actions'
import SelectLang from '../common/SelectLang'
import Translations from '../../translations/translations'
import SenseiCoin from '../common/SenseiCoin'
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import translations from '../../translations/translations';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
  },
  avatar: {
    margin: 10,
  },
  grayAvatar: {
    margin: 10,
    color: '#000',
    backgroundColor: '#ededed',
  },
  link: {
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: '#FFFFFF',
    padding: '8px 16px',
    minWidth: '64px',
    minHeight: '36px',
  }
}


const UserMenu = ({ close, lang, auth }) => {
  return (
    <List style={{ minWidth: 300, paddingTop: 0 }}>
      <div style={{ padding: 2, background: '#ececec', textAlign: 'center' }}>
       <Typography variant="caption" style={{ textAlign: 'center', margin: '0' }}>{translations[lang].account}: <b>{auth.user_email}</b></Typography>
      </div>
      <div style={{ textAlign: 'center', padding: 8, marginBottom: 8 }}>
        <img src="./senseiwallet-vertical.png" alt="sensei wallet" style={{ maxWidth: '90%', height: 80 }} />
      </div>
      <Divider />
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/dashboard" onClick={close}>
        <ListItem button>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={Translations[lang].home} />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/envios" onClick={close}>
        <ListItem button>
          <ListItemIcon><SendIcon /></ListItemIcon>
          <ListItemText primary={Translations[lang].sales} />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/recebimentos" onClick={close}>
        <ListItem button>
          <ListItemIcon><CallReceivedIcon /></ListItemIcon>
          <ListItemText primary={Translations[lang].purchases} />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/transferencias" onClick={close}>
        <ListItem button>
          <ListItemIcon><TransformIcon /></ListItemIcon>
          <ListItemText primary={Translations[lang].transfers} />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/carteira" onClick={close}>
        <ListItem button>
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary={Translations[lang].wallet} />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/perfil" onClick={close}>
        <ListItem button>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary={Translations[lang].profile} />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/ajuda" onClick={close}>
        <ListItem button>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary={Translations[lang].help} />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/logout" onClick={close}>
        <ListItem button>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={Translations[lang].exit} />
        </ListItem>
      </Link>
    </List>
  )
}

const AdminMenu = ({ close, lang }) => {
  return (
    <List style={{ minWidth: 300 }}>
      <div style={{ textAlign: 'center', padding: 8, marginBottom: 8 }}>
        <img src={process.env.PUBLIC_URL + "/senseiwallet-vertical.png"} alt="sensei wallet" style={{ maxWidth: '90%', height: 80 }} />
      </div>
      <Divider />
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/dashboard" onClick={close}>
        <ListItem button>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary='Home' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/admin/usuarios" onClick={close}>
        <ListItem button>
          <ListItemIcon><AccountCircleIcon /></ListItemIcon>
          <ListItemText primary='Usuários' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/admin/envios" onClick={close}>
        <ListItem button>
          <ListItemIcon><SendIcon /></ListItemIcon>
          <ListItemText primary='Envios' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/admin/recebimentos" onClick={close}>
        <ListItem button>
          <ListItemIcon><CallReceivedIcon /></ListItemIcon>
          <ListItemText primary='Recebimentos' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/admin/transferencias" onClick={close}>
        <ListItem button>
          <ListItemIcon><TransformIcon /></ListItemIcon>
          <ListItemText primary='Transferências' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/admin/plataformas" onClick={close}>
        <ListItem button>
          <ListItemIcon><StoreMallDirectoryIcon /></ListItemIcon>
          <ListItemText primary='Plataformas' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/admin/cofre" onClick={close}>
        <ListItem button>
          <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
          <ListItemText primary='Cofre' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/admin/avisos" onClick={close}>
        <ListItem button>
          <ListItemIcon><SmsFailedIcon /></ListItemIcon>
          <ListItemText primary='Avisos' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/admin/preco" onClick={close}>
        <ListItem button>
          <ListItemIcon><LocalAtmIcon /></ListItemIcon>
          <ListItemText primary='Preço Dólar' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/logout" onClick={close}>
        <ListItem button>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary='Sair' />
        </ListItem>
      </Link>
    </List>
  )
}

const EmployeeMenu = ({ close }) => {
  return (
    <List style={{ minWidth: 300 }}>
      <div style={{ textAlign: 'center', padding: 8, marginBottom: 8 }}>
        <Typography>Sensei <b>Wallet</b></Typography>
      </div>
      <Divider />
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/dashboard" onClick={close}>
        <ListItem button>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary='Home' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/admin/usuarios" onClick={close}>
        <ListItem button>
          <ListItemIcon><AccountCircleIcon /></ListItemIcon>
          <ListItemText primary='Usuários' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/admin/envios" onClick={close}>
        <ListItem button>
          <ListItemIcon><SendIcon /></ListItemIcon>
          <ListItemText primary='Envios' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/admin/recebimentos" onClick={close}>
        <ListItem button>
          <ListItemIcon><CallReceivedIcon /></ListItemIcon>
          <ListItemText primary='Recebimentos' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/admin/transferencias" onClick={close}>
        <ListItem button>
          <ListItemIcon><TransformIcon /></ListItemIcon>
          <ListItemText primary='Transferências' />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'black' }} to="/logout" onClick={close}>
        <ListItem button>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary='Sair' />
        </ListItem>
      </Link>
    </List>
  )
}

const SideMenu = ({ open, close, me, lang, auth }) => {
  const menus = {
    'ADMIN': <AdminMenu lang={lang} close={close} />,
    'USER': <UserMenu lang={lang} close={close} auth={auth} />,
    'EMPLOYEE': <EmployeeMenu lang={lang} close={close} />
  }

  return (
    <Drawer open={open} onClose={close} style={{ paddingRight: 16 }}>
      {
        menus[me.role]
      }
    </Drawer>
  )
}

class UserBar extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  }

  state = {
    anchorEl: null,
    openMenu: false
  }

  toggleMenu = () => {
    this.setState((state) => ({ openMenu: !state.openMenu }))
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  getBalance() {
    this.props.getBalance();
  }

  async getMe(id) {
    await this.props.getMe(id);
  }

  async componentDidMount() {
    await this.getMe(this.props.auth.id)
    await this.props.getRecentDollarPrices()

    if (this.props.me.role === "USER") {
      this.getBalance();
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.me !== this.props.me) {
      if (this.props.me.role === 'USER') {
        this.props.getMyNotices(this.props.me.cognito_id)
      }
    }
  }

  formatBalance(balance) {
    return balance.toFixed(2)
  }

  render() {
    const { classes, auth, me, balance = 0, lang, changeLang, recentDollarPrices } = this.props
    const { openMenu } = this.state

    return (
      <div className={classes.root}>
        <div style={{ fontSize: 10, background: me.role === 'ADMIN' ? '#8e0000' : '#232323' , color: 'white', padding: '2px 16px' }}>
          <Grid container jusitfy="space-between" spacing={4}>
            <Grid item xs={9} style={{ textAlign: 'left' }}>
              <Typography style={{ fontSize: 12 }}><SendIcon style={{ fontSize: 11, marginRight: 4 }} /><b><NumberFormat
                value={recentDollarPrices.sale}
                displayType={'text'}
                prefix="R$ "
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale="true"
                style={{ marginRight: 8 }}
              /></b> | <CallReceivedIcon style={{ fontSize: 11, marginRight: 4, marginLeft: 4 }} /><b><NumberFormat
                value={recentDollarPrices.purchase}
                displayType={'text'}
                prefix="R$ "
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale="true"
              /></b></Typography>
            </Grid>

            <Grid item xs={3} style={{ textAlign: 'right' }}>
              <Typography style={{ fontSize: 12 }}><Link to='/logout' style={{ textDecoration: 'underline', color: 'white', marginLeft: 8 }}>{Translations[lang].exit}</Link></Typography>
            </Grid>
          </Grid>
        </div>
        <AppBar position="static" style={{ background: '#101010', padding: '6px' }}>
          <Toolbar>
            <IconButton onClick={this.toggleMenu} className={classes.menuButton} color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <Link style={{ textDecoration: 'none', color: 'white' }} className={classes.grow} to="/dashboard">
              <img alt="logo" className="header-logo" src="/senseiwallet-logo.png" />
            </Link>

            {me.role === 'USER' ? <SelectLang lang={lang} changeLang={changeLang} /> : null}
            
            {
              me.role === 'USER' ?
                <Link style={{ textDecoration: 'none', color: 'white' }} to="/carteira">
                  <div style={{ textAlign: 'center', padding: "5px 8px", background: '#2f2f2f', borderRadius: 5 }}>
                    <Typography variant="caption">{Translations[lang].balance}</Typography>
                    <Typography>
                      <b>
                        <SenseiCoin />
                        <NumberFormat
                          value={balance / 100}
                          displayType={'text'}
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale="true"
                        />
                      </b>
                    </Typography>
                  </div>
                </Link>
                : null
            }
            <SideMenu open={openMenu} close={this.toggleMenu} auth={auth} me={me} lang={lang} />
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}


const mapStateToProps = ({ auth, me, balance, lang, recentDollarPrices }) => {
  return {
    auth,
    me,
    balance,
    lang,
    recentDollarPrices
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBalance: () => dispatch(getUserBalanceFetchData()),
    getMe: (id) => dispatch(getMeFetchData(id)),
    getMyNotices: (id) => dispatch(getMyNoticesFetchData(id)),
    changeLang: (lang) => dispatch(changeLang(lang)),
    getRecentDollarPrices: () => dispatch(getRecentDollarPricesFetchData())
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UserBar))