        
import React, { Component } from 'react'
import { createSaleFetchData, adminCreateSaleFetchData } from './actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import BasicForm from '../common/BasicForm';
import _ from 'lodash';
import { getUserBalanceFetchData } from '../users/actions'


class SaleCreate extends Component {

  state = { 
    isFetching: false,
    didInvalidate: false,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center'
  }

  createSale({ platform, value, identifier, message, dollar_price, user }) {
    this.setState((state) => ({
      isFetching: true,
      didInvalidate: false,
    }))
    console.log('createsale')
    if(this.props.me.role === 'ADMIN'){
      this.props.adminCreateSale({ platform, value, identifier, message, dollar_price, user })
      .then(body => {
        this.setState((state) => ({
          isFetching: false,
          didInvalidate: false,
        }))
  
        this.props.closeCreate('SUCCESS');
  
        this.props.getBalance();
      })
      .catch(ex => {
        this.setState((state) => ({
          isFetching: false,
          didInvalidate: true,
          snackbarOpen: true,
          snackMessage: ex
        }))
      })
    } else if(this.props.me.role === 'USER') {
      this.props.createSale({ platform, value, identifier, message, dollar_price })
      .then(body => {
        this.setState((state) => ({
          isFetching: false,
          didInvalidate: false,
        }))
  
        this.props.closeCreate('SUCCESS');
  
        this.props.getBalance();
      })
      .catch(ex => {
        this.setState((state) => ({
          isFetching: false,
          didInvalidate: true,
          snackbarOpen: true,
          snackMessage: ex
        }))
      })
    }
  }

  handleClose = () => {
    this.setState((state) => ({ ...state, snackbarOpen: false }));
  };

  render() {
    const { isFetching, vertical, horizontal, snackbarOpen, snackMessage } = this.state
    const { platforms = [], rules, lang, me } = this.props;

    let plat = []
    let filtered_platforms = platforms.docs

    if(me.role ==='USER'){
      plat = _.filter(rules, (rule) => rule.sale === false)
      plat = plat.map(p => p.platform)
  
      filtered_platforms = _.filter(platforms, (p) => !plat.includes(p._id) )
    }

    return (
        <div>
            <BasicForm
              rules={rules} 
              handleSubmit={(data) => this.createSale(data)}
              type="SALE"
              platforms={filtered_platforms}
              isFetching={isFetching}
              lang={lang}
            />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                key={`${vertical},${horizontal}`}
                open={snackbarOpen}
                onClose={this.handleClose}
                ContentProps={{
                  'aria-describedby': 'message-id',
                }}
              message={<span id="message-id">{snackMessage}</span>}
            />
        </div>
    )
  }
}

const mapStateToProps = ({ auth, me, rules, lang }) => {
  return {
    auth,
    me,
    rules,
    lang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getSaleFetchData: () => dispatch(getSaleFetchData()),
    createSale: (sale) => dispatch(createSaleFetchData(sale)),
    adminCreateSale: (sale) => dispatch(adminCreateSaleFetchData(sale)),
    getBalance: () => dispatch(getUserBalanceFetchData())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaleCreate))

      