import { routes } from './core/api';
import { Auth as AuthCognito } from 'aws-amplify';


export const getDollarPrices = (dollarPrices) => {
  return {
      type: 'GET_DOLLAR_PRICES',
      dollarPrices
  }
}


export const getRecentDollarPrices = (dollarPrices) => {
  return {
    type: 'GET_RECENT_DOLLAR_PRICES',
    dollarPrices
  }
}

export function getRecentDollarPricesFetchData() {
  let route = routes.userGet;
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
        })
        .then(res => { 
          return res.json() 
        })
        .then(body => {
          dispatch(getRecentDollarPrices(body))
          resolve(body)
        })
      })
    })
  }
}

export function createDollarPrice(body) {
  return new Promise((resolve, reject) => {
    AuthCognito.currentSession()
    .then(session=>{
      return fetch(routes.adminGet, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': session.idToken.jwtToken
        },
        body: JSON.stringify(body)
      })
      .then(res => { 
        if(res.status !== 200) { 
          reject('Preço do dólar não criado!');
         }
        return res.json() 
      })
      .then(body => {
        resolve(body)
      })
    })
  })
  
}


export function getDollarPricesFetchData({page = 1, query = {}}) {
  let qs = '?';
  if(page) {
    qs += 'page='+page
  }

  var queryString = Object.keys(query).map((key) => {
    if(key && query[key]) return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
    return ''
  }).join('&');

  let route = routes.adminGet+qs;
  if(queryString.length) {
    route += `&${queryString}`
  }

  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
        })
        .then(res => { 
          return res.json() 
        })
        .then(body => {
          dispatch(getDollarPrices(body))
          resolve(body)
        })
      })
    })
  }
}

