export const platform_transactions = (state = [], action) => {
  switch (action.type) {
    case 'GET_PLATFORM_TRANSACTIONS':
      return action.platform_transactions
    default:
        return state;
  }
}

export const safe_balance = (state = 0, action) => {
  switch (action.type) {
    case 'GET_SAFE_BALANCE':
      return action.safe_balance
    default:
        return state;
  }
}

export const resume = (state = [], action) => {
  switch (action.type) {
    case 'GET_RESUME':
      return action.resume
    default:
        return state;
  }
}

export const users_balance = (state = 0, action) => {
  switch (action.type) {
    case 'GET_USERS_BALANCE':
      return action.users_balance
    default:
        return state;
  }
}