import React, { Component } from 'react'
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography, Grid } from '@material-ui/core'
import translations from '../../translations/translations';


class StatusWidget extends Component {
    
    render() {
        const { status = 'PENDING', lang } = this.props;
        const statusColor =  {
            'PENDING': { 
              color: '#ffbc00',
              label: translations[lang].pending,
              icon: <ErrorIcon fontSize='small' style={{ display: 'inline-block', marginRight: 8 }}/>
            },
            'APPROVED': { 
              color: '#439406',
              label: translations[lang].approved,
              icon: <CheckCircleIcon fontSize='small' style={{ display: 'inline-block', marginRight: 8 }}/>
            },
            'DECLINED': { 
              color: '#f54646',
              label: translations[lang].declined,
              icon: <CancelIcon fontSize='small' style={{ display: 'inline-block', marginRight: 8 }}/>
            },
            'CANCELED': { 
              color: '#7b7b7b',
              label: translations[lang].canceled,
              icon: <CancelIcon fontSize='small' style={{ display: 'inline-block', marginRight: 8 }}/>
            }
          }

          const { color , icon, label } = statusColor[status]

          return (
            <div style={{ 
                padding: '4px 8px', 
                background: `linear-gradient(147deg, rgb(255, 255, 255) -33%, ${color} 67%)`, 
                display: 'inline-block', 
                borderRadius: 30,
                color: 'white',
                boxShadow: "1px 1px 4px 0px #ddd"
            }}>
                <Grid container alignItems="center" style={{ lineHeight: 1 }}>
                <Grid item>
                      {icon}
                  </Grid>
                  <Grid item>
                      <Typography variant='body2' style={{ display: 'inline-block' }}>
                        <b style={{ color: "#fff" }}>{label}</b>
                      </Typography>
                  </Grid>
                 
                </Grid>
            </div>
          )

          
    }
}

export default StatusWidget
