import { routes } from './core/api';
import { Auth as AuthCognito } from 'aws-amplify';


export const getNotices = (notices) => {
  return {
      type: 'GET_NOTICES',
      notices
  }
}

export const getMyNotices = (notices) => {
  return {
      type: 'GET_MY_NOTICES',
      notices
  }
}

export function createNotice(body) {
  return new Promise((resolve, reject) => {
    AuthCognito.currentSession()
    .then(session=>{
      return fetch(routes.get, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': session.idToken.jwtToken
        },
        body: JSON.stringify(body)
      })
      .then(res => { 
        if(res.status !== 200) { 
          reject('Aviso não criado!');
         }
        return res.json() 
      })
      .then(body => {
        resolve(body)
      })
    })
  })
  
}

export function deleteNotice(id) {
  return new Promise((resolve, reject) => {
    AuthCognito.currentSession()
    .then(session=>{
      return fetch(routes.get+'/'+id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': session.idToken.jwtToken
        }
      })
      .then(res => { 
        if(res.status !== 200) { 
          reject('Aviso não deletado!');
         }
        return res.json() 
      })
      .then(body => {
        resolve(body)
      })
    })
  })
  
}

export function getNoticesFetchData({page = 1, query = {}}) {
  let qs = '?';
  if(page) {
    qs += 'page='+page
  }

  var queryString = Object.keys(query).map((key) => {
    if(key && query[key]) return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
    return ''
  }).join('&');

  let route = routes.get+qs;
  if(queryString.length) {
    route += `&${queryString}`
  }

  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(route, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
        })
        .then(res => { 
          return res.json() 
        })
        .then(body => {
          dispatch(getNotices(body))
          resolve(body)
        })
      })
    })
  }
}

export function getMyNoticesFetchData(userId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        return fetch(routes.userGet+'/'+userId, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken
          },
        })
        .then(res => { 
          return res.json() 
        })
        .then(body => {
          dispatch(getMyNotices(body))
          resolve(body)
        })
      })
    })
  }
}


export function updateNotice(id, body) {
  return new Promise((resolve, reject) => {
    AuthCognito.currentSession()
    .then(session=>{
      return fetch(`${routes.get}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': session.idToken.jwtToken
        },
        body: JSON.stringify(body)
      })
      .then(res => { 
        return res.json() 
      })
      .then(body => {
        resolve(body)
      })
    })
  })
}

export function hideToUser(noticeId, userId) {
  return new Promise((resolve, reject)=>{
    AuthCognito.currentSession()
    .then(session=>{
      return fetch(routes.hideToUser, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': session.idToken.jwtToken
        },
        body: JSON.stringify({noticeId, userId})
      })
      .then(res => { 
        return res.json() 
      })
      .then(body => {
        resolve(body)
      })
    })
  })
}