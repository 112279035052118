        
import React, { Component } from 'react'
import { createCognitoUser } from './actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import UserForm from './UserForm';


class UserCreate extends Component {

  state = { 
    isFetching: false,
    didInvalidate: false,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center'
  }

  createUser({ full_name, email, phone, password, role }) {
    this.setState((state) => ({
      isFetching: true,
      didInvalidate: false,
    }))

    this.props.createUser({ full_name, email, phone, password, role })
    .then(body => {
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: false,
      }));
      this.props.closeCreate();
    })
    .catch(ex => {
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: true,
        snackbarOpen: true,
        snackMessage: ex,
      }))
    })
  }


  render() {
    const { isFetching, vertical, horizontal, snackbarOpen, snackMessage } = this.state
    const { users } = this.props

    return (
        <div>
            <UserForm 
              handleSubmit={(data) => this.createUser(data)}
              users={users}
              isFetching={isFetching}
            />
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              key={`${vertical},${horizontal}`}
              open={snackbarOpen}
              onClose={this.handleCloseSnackbar}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
            message={<span id="message-id">{snackMessage}</span>}    />
        </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (user) => dispatch(createCognitoUser(user))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserCreate))

      