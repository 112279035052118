
import Auth2 from '../../../utils/auth';
const baseApi = `${process.env.REACT_APP_API_URL}/notices`


export const headers = () => { 
  const token = Auth2.getToken()
  return new Headers({
    'Content-Type': 'application/json',
    'Authorization': token
  }) 
}

export const routes = {
  get: `${baseApi}`,
  userGet: `${baseApi}/user`,
  hideToUser: `${baseApi}/hide-to-user`
} 

