export const purchase = (state = {}, action) => {
  switch (action.type) {
    case 'GET_PURCHASE':
      return action.purchase
    default:
        return state;
  }
}

export const purchases = (state = [], action) => {
  switch (action.type) {
    case 'GET_PURCHASES':
      return action.purchases
    default:
        return state;
  }
}

export const recent_purchases = (state = [], action) => {
  switch (action.type) {
    case 'GET_RECENT_PURCHASES':
      return action.recent_purchases
    default:
        return state;
  }
}