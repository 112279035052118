import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Grid, MenuItem, InputAdornment } from '@material-ui/core'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import CircularProgress from '@material-ui/core/CircularProgress'
import NumberFormat from 'react-number-format';


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      fixedDecimalScale="true"
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

class SafeTransactionForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired
  }

  state = {
    value: '',
    platform: '',
  }

  reset() {
    this.setState((state) => ({
      value: '',
      platform: '',
    }))
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  handleSubmit = () => {
    console.log({
      ...this.state,
      type: this.props.type
    })
    this.props.handleSubmit({
      ...this.state,
      type: this.props.type
    }, () => this.reset())
  }

  render() {
    const { platform, value } = this.state
    const { isFetching, type, platforms = [] } = this.props
    const buttonMessage = {
      DEPOSIT: 'Depositar',
      WITHDRAW: 'Sacar'
    };
    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextValidator
              fullWidth
              variant="filled"
              label="Platforma"
              onChange={this.handleChange}
              name="platform"
              value={platform}
              select
              validators={['required']}
              errorMessages={['Campo obrigatório']}
            >
              {
                platforms.map(platform => (<MenuItem key={platform._id} value={platform._id}>{platform.name}</MenuItem>))
              }
            </TextValidator>
          </Grid>
          <Grid item xs={4}>
            <TextValidator
              fullWidth
              label="Valor"
              variant="filled"
              onChange={this.handleChange}
              name="value"
              value={value}
              validators={['required']}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputComponent: NumberFormatCustom,
              }}
              errorMessages={['Campo obrigatório']}
            />
          </Grid>
          <Grid item xs={4}>
          <Button 
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              disabled={isFetching}
              style={{ height: 55 }}
            >
              {isFetching && <CircularProgress size={20} />} {buttonMessage[type] || 'Salvar'}
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    )
  }
}

export default SafeTransactionForm;