import React, {Component} from 'react'
import { Grid, MenuItem, Typography, TextField, Paper } from '@material-ui/core'
import { connect } from 'react-redux';
import {getUsersFetchData} from '../users/actions';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BasicDeleteButton from './BasicDeleteButton';

const useStyles = makeStyles((theme) => ({
  name: {
    fontFamily: 'Merriweather',
    fontSize: '16px',
    color: '#444',
  },
  email: {
    fontFamily: 'Merriweather',
    fontSize: '12px',
    color: '#444',
  },
}));

const UserListItem = (props) => {
  const classes = useStyles();

  return (
    <MenuItem onClick={props.onClick}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.name}>{props.doc.name}</div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.email}>{props.doc.email}</div>
        </Grid>
      </Grid>
    </MenuItem>
  )

}

const NoResultItem = (props) => {
  const classes = useStyles();

  return (
    <MenuItem>
      <Grid container>
        <Grid item xs={12}>
        <div className={classes.email}>
          {props.isSearching ? 'Buscando...'
          : 'Nenhum resultado encontrado'}
          </div>
        </Grid>
      </Grid>
    </MenuItem>
  )

}


class UserSearch extends Component {

  constructor(props) {
    super(props);

    this.handleSearchByEmail = this.handleSearchByEmail.bind(this);
    this.timer = null

  }
  
  state={
    user: '',
    docs: [],
    isFetching: false,
    isSearching: false,
    hideSearch: false,
    searchTerm: ''
  }

  
  handleChange = (e) => {
    this.setState({user: e, hideSearch: true})
    this.props.handleSetUser(e)
  }

  getUsers({limit=4, email}){
    this.props.getUsers({page: 1, query:{email}, noDispatch:true })
    .then(res=>{
      this.setState(state => ({
        docs: res.docs,
        isFetching: false,
        isSearching: false
      }))
    })
    .catch(err=>{
      this.setState({
        isFetching: false, 
        isSearching: false})
    })
  }
  

  removeSelection = () => {
    this.setState({ user: '', hideSearch: false, searchTerm:'', docs:[] })
  }

  handleSearchByEmail = (event) => {
    const searchTerm = event.target.value;
    this.setState({searchTerm, isSearching: true })
    clearTimeout(this.timer)
    this.timer = setTimeout(()=>{
      if(searchTerm === ''){
        this.getUsers({})
      }
      else{
        this.getUsers({email: searchTerm})
      }
    }, 500)
    
  }

  componentDidMount(){
    // this.getUsers({})
  }

  

  render(){
    const { user, searchTerm, isSearching, docs, hideSearch } = this.state;
    const {specialLabel} = this.props

    return(
      <Grid container justify="space-between" alignItems="center" spacing={3}>
          
        
        <Grid item xs={12} style={{ paddingTop: 16 }}>
          {
            !hideSearch && (
              <TextField label={specialLabel || 'Buscar por usuário'} fullWidth value={searchTerm} variant='filled' onChange={this.handleSearchByEmail}></TextField>
            )
          }

          {
              searchTerm  && !hideSearch &&
              (<Paper style={{ padding: 8 }}>
                {
                  docs.length ? docs.map(doc => {
                    return (<UserListItem key={doc.id} doc={doc} onClick={() => this.handleChange(doc)}/>)
                  }) : (<NoResultItem isSearching={isSearching} />)
                }
              </Paper>)
          }
        </Grid>
        { user ? 
          <Grid item xs={12} md={6} style={{ paddingTop: 16 }}>
            <div>
              <Typography variant='body1'>{specialLabel ? specialLabel : 'Usuário:'}</Typography>
              <Typography variant="body1" style={{
                fontFamily: 'Merriweather',
                fontSize: '16px',
                color: '#444',
              }}>{user.name}</Typography>
              <Typography variant="body1" style={{
                fontFamily: 'Merriweather',
                fontSize: '12px',
                color: '#444',
              }}>{user.email}</Typography>
              <BasicDeleteButton handleClick={this.removeSelection}>Descelecionar</BasicDeleteButton>
            </div>
          </Grid>
        : null}
        
       
      </Grid>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (query) => dispatch(getUsersFetchData(query))
  }
}


export default withRouter(connect(null, mapDispatchToProps)(UserSearch))