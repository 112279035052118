import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Grid, MenuItem, InputAdornment, Typography, Card, CardContent } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import CircularProgress from '@material-ui/core/CircularProgress'
import NumberFormat from 'react-number-format';
import _ from 'lodash'
import Big from 'big.js'
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import translations from '../../translations/translations'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getRecentDollarPricesFetchData } from '../dollar-prices/actions';
import { getPlatformsRulesFetchData } from '../users/actions'
import UserSearch from './UserSearch'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale="true"
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

class BasicForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired
  }

  state = {
    value: '',
    platform: '',
    identifier: '',
    purchaseIdentifier: '',
    platformName: '',
    identifierDescription: '',
    message: '',
    fee: 0,
    sale_limit: 0,
    selected_user_rules: false,
    debit_value: '',
    brl: false,
    usdt: false,
  }

  reset() {
    this.setState((state) => ({
      value: '',
      platform: '',
      identifier: '',
      platformName: '',
      user: { name: '', id: '' },
      identifierDescription: '',
      purchaseIdentifier: '',
      message: '',
      brl: false
    }))
  }

  handleSetUser = async (user) => {
    //get user platform rules
    const selected_user_rules = await this.props.getRules(user.id)

    this.setState({
      user,
      selected_user_rules
    })
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    const stateValue = this.state.value || 0

    if (field === 'platform') {
      if (value === process.env.REACT_APP_BRL_PLATFORM) {
        this.setState({ brl: true })
      } else {
        this.setState({ brl: false })
      }

      if (value === process.env.REACT_APP_USDT_PLATFORM) {
        this.setState({ usdt: true })
      } else {
        this.setState({ usdt: false })
      }
      const plat = _.find(this.props.platforms, function (p) { return p._id === value })

      this.setState({ identifierDescription: plat.identifier, platformName: plat.name, fee: plat.fee, purchaseIdentifier: plat.purchase_identifier ? plat.purchase_identifier : '' })

      let rule = {}

      if (this.props.me.role === "ADMIN") {
        rule = _.find(this.state.selected_user_rules, function (r) { return r.platform._id === plat._id })
        this.setState({ selected_user_sale_permited: rule.sale })
      } else if (this.props.me.role === "USER") {
        rule = _.find(this.props.rules, function (r) { return r.platform === plat._id })
      }

      let debit_value = stateValue * 100

      if (rule && plat.fee > 0) {
        debit_value = Big(stateValue * 100).plus(Big(plat.fee).times(stateValue * 100).div(100)).toNumber()
      }

      this.setState({ identifierDescription: plat.identifier, platformName: plat.name, fee: plat.fee, sale_limit: rule ? rule.sale_limit : 'Sem limite', debit_value })

    }

    if (field === 'value') {
      let debit_value = value * 100
      if (this.state.fee > 0) {
        debit_value = Big(value * 100).plus(Big(this.state.fee).times(value * 100).div(100)).toNumber()
      }
      this.setState({ debit_value })
    }
    this.setState({ [field]: value })
  }

  handleSubmit = () => {
    const { platform, identifier, value, message, identifierDescription, platformName, purchaseIdentifier, sale_limit, fee, debit_value, brl, user } = this.state;
    const { recentDollarPrices } = this.props
    const params = { platform, identifier, value, message, identifierDescription, platformName, purchaseIdentifier, sale_limit, fee, debit_value, user: user ? user.id : null }
    if (brl) {
      params['dollar_price'] = recentDollarPrices._id
    }
    this.props.handleSubmit(params, () => this.reset())
  }


  render() {
    const { usdt, platform, identifier, value, message, identifierDescription, platformName, purchaseIdentifier, fee, debit_value, brl, selected_user_rules } = this.state
    const { isFetching, type, platforms = [], lang, recentDollarPrices } = this.props
    const buttonMessage = {
      SALE: translations[lang].sale_action,
      PURCHASE: translations[lang].purchase_action
    };

    const purchaseBRLIdentifier = 'Nome Completo e CPF da conta origem';

    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
      >
        <Grid container spacing={3}>
          {
            type === "PURCHASE" && (
              <Grid item xs={12}>
                <div style={{ padding: 16, color: '#ff6000', borderRadius: 5, border: '1px solid #e27809' }}>
                  <Grid container spacing={2}>
                    <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={1}>
                      <InfoOutlinedIcon fontSize='large' />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant='body1'>
                        {translations[lang].purchase_info}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )
          }
          {
            this.props.me.role === 'ADMIN' && (
              <Grid item xs={12}>
                <UserSearch handleSetUser={this.handleSetUser} />
              </Grid>
            )
          }
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              variant="filled"
              label={translations[lang].platform}
              onChange={this.handleChange}
              name="platform"
              value={platform}
              select
              disabled={this.props.me.role === 'ADMIN' && !this.state.selected_user_rules}
              validators={['required']}
              errorMessages={['Campo obrigatório']}
            >
              {
                platforms.map(platform => (<MenuItem key={platform._id} value={platform._id}>{platform.name}</MenuItem>))
              }
            </TextValidator>
          </Grid>
          {
            platform !== '' && type === 'PURCHASE' && !brl ?
              <Grid item xs={12}>
                <Typography variant="body1" style={{ margin: "0px 8px", color: '#ff6000' }}>{translations[lang].identifier_description(identifierDescription, platformName, purchaseIdentifier)}</Typography>
              </Grid>
              : null
          }          
          {
            platform !== '' && type === 'SALE' ?
              <Grid item xs={12}>
                <Typography variant="body1" style={{ margin: "0px 8px", color: '#ff6000' }}>{translations[lang].form_info(platformName, identifierDescription)}</Typography>
              </Grid>
              : null
          }
          {
            usdt && type === 'PURCHASE' ?
              <Grid item xs={12}>
                <Typography variant="body1" style={{ margin: "0px 8px", color: '#ff6000' }}>{translations[lang].usdt_info}</Typography>
              </Grid>
              : null
          }
          {
            brl ?
              <Grid item xs={12}>
                <div style={{ padding: 16, color: '#019800', borderRadius: 5, border: '1px solid #53bf01' }}>
                  <Grid container spacing={2}>
                    <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={1}>
                      <LocalAtmIcon fontSize='large' />
                    </Grid>
                    <Grid item xs={11}>
                      {
                        type === "PURCHASE" ?
                          <Typography variant="body1">Você deve enviar <b>R$ <NumberFormat
                            value={value * recentDollarPrices.purchase}
                            displayType={'text'}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale="true"
                            decimalScale={2}
                          /></b> para PIX: <b>{purchaseIdentifier}</b></Typography>
                          : null
                      }
                      {
                        type === "SALE" ?
                          <Typography variant="body1">Valor creditado <b>R$ <NumberFormat
                            value={value * recentDollarPrices.sale}
                            displayType={'text'}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale="true"
                            decimalScale={2}
                          /></b> para {identifierDescription}: {identifier} </Typography>
                          : null
                      }

                    </Grid>
                  </Grid>
                </div>
              </Grid>
              : null
          }

          {
            (
              (this.props.me.role === "USER" && platform && fee > 0 && type === "SALE")
              ||
              (this.props.me.role === 'ADMIN' && platform && selected_user_rules)
            ) ? (
              <Grid item xs={12}>
                <Card style={{ margin: "0px 8px" }}>
                  <CardContent>
                    {
                      this.props.me.role === 'ADMIN' && !this.state.selected_user_sale_permited &&
                      <Typography variant='body1' style={{ background: 'red', color: 'white', padding: 8, fontWeight: 'bold' }}>USUÁRIO NÃO PODE FAZER ENVIO NESTA PLATAFORMA</Typography>
                    }
                    <Typography>Taxa: <b>{fee}%</b></Typography>
                    <Typography>Valor debitado: <b><NumberFormat
                      value={debit_value / 100}
                      displayType={'text'}
                      thousandSeparator="."
                      decimalSeparator=","
                      fixedDecimalScale="true"
                      decimalScale={2}
                    /></b> </Typography>
                  </CardContent>
                </Card>

              </Grid>
            )
              : null
          }
          <Grid item xs={8}>
            <TextValidator
              fullWidth
              label={brl && type === 'PURCHASE' ? `${translations[lang].identifier} (${purchaseBRLIdentifier})` : identifierDescription ? `${translations[lang].identifier} (${identifierDescription})` : translations[lang].identifier}
              variant="filled"
              onChange={this.handleChange}
              name="identifier"
              value={identifier}
              disabled={!platform}
              validators={['required']}
              errorMessages={['Campo obrigatório']}
            />
          </Grid>
          <Grid item xs={4}>
            <TextValidator
              fullWidth
              label={translations[lang].value}
              variant="filled"
              onChange={this.handleChange}
              name="value"
              value={value}
              disabled={!platform}
              validators={['required']}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputComponent: NumberFormatCustom,
              }}
              errorMessages={['Campo obrigatório']}
            />
          </Grid>
          <Grid item xs={8}>
            <TextValidator
              fullWidth
              label={translations[lang].observation}
              disabled={!platform}
              variant="filled"
              onChange={this.handleChange}
              name="message"
              value={message}
              multiline
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              disabled={isFetching}
              style={{ height: 55 }}
            >
              {isFetching && <CircularProgress size={20} />} {buttonMessage[type] || translations[lang].save}
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    )
  }
}


const mapStateToProps = ({ recentDollarPrices, me }) => {
  return {
    recentDollarPrices,
    me
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRules: (id) => dispatch(getPlatformsRulesFetchData(id)),
    getRecentDollarPrices: () => dispatch(getRecentDollarPricesFetchData()),
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BasicForm))