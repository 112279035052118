import React, { Component } from 'react'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import { Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css'


class ForgotPassword extends Component {

  state = {
    emailToResetPassword: '',
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  render() {
    const { open, handleClose, handleSubmit, isFetching, isCompletingNewPassword } = this.props

    return (
      <Dialog open={open} onClose={handleClose} PaperProps={{style:{margin: 'auto', width: '400px', padding:'30px'}}}>
        <Typography style={{fontWeight: 'bold', marginBottom: 8}} padding="20px 20px 0 20px">
            INFORME SEU E-MAIL
        </Typography>
        <Typography variant='caption'>Enviaremos um código para o seu e-mail para que você possa redefinir sua senha</Typography>
        <ValidatorForm
          style={{
            margin: 'auto'
          }}
          ref="form"
          onSubmit={()=>handleSubmit(this.state.emailToResetPassword)}
        >
          <TextValidator
          fullWidth
          value={this.state.emailToResetPassword}
          label="E-mail"
          type='text'
          onChange={this.handleChange}
          name="emailToResetPassword"
          validators={['required']}
          errorMessages={['preencha o email']}
          margin="dense"
          // InputProps={{ classes: { root: classes.input } }}
          // InputLabelProps={{
          //   classes: {
          //     root: classes.label
          //   }
          // }}
          />
          <Button 
            variant="contained"
            fullWidth
            color="primary"
            type="submit"
            disabled={isCompletingNewPassword}
            margin="normal"
            style={{ marginTop: 16 }}
          >
            {isFetching && <CircularProgress color='inherit' size={20} />} <b>Confirmar</b>
        </Button>
        </ValidatorForm>
      </Dialog>
    )
  }
}

export default ForgotPassword
