import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel';

const styles = theme => ({
  main: {
    backgroundColor: 'white',
    border: '1px solid #8A1912',
    color: "#8A1912",
    fontWeight: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 200,
    height: 35,
    fontSize: 12,
    margin: '20px auto',
    "&:hover": {
      boxShadow: '0px 4px 9px #444',
      backgroundColor: '#8A1912',
      color: 'white'
    }
  }
})

const BasicButton = ({classes, children, handleClick, type}) => {

    return (
      <Button
        onClick={handleClick}
        className={classes.main}
        variant="contained"
        fullWidth
        type={type ? type : "button"}
        margin="normal">
          <CancelIcon/>{children}
      </Button>
    )

}

export default withStyles(styles)(BasicButton);