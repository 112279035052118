import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Grid, MenuItem } from '@material-ui/core'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import CircularProgress from '@material-ui/core/CircularProgress'
import NumberFormat from 'react-number-format';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      fixedDecimalScale="true"
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

class UserForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired
  }

  state = {
    full_name: '',
    phone: '',
    email: '',
    password: '',
    nickname: '',
    role: 'USER'
  }

  reset() {
    this.setState((state) => ({
      full_name: '',
      phone: '',
      email: '',
      password: '',
      nickname: '',
      role: 'USER'
    }))
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  handleChangeUser = (event) => {
    const value = event.target.value
    const { users } = this.props;
    const destination_user = users[value]['_id'] || null; 
    this.setState({ destination_user })
  }


  handleSubmit = () => {
    this.props.handleSubmit(this.state, () => this.reset())
  }

  render() {
    const { full_name, email, phone, password, role } = this.state
    const { isFetching } = this.props

    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
        
      >
        <Grid container spacing={3}>
          {/* <Grid item xs={4}>
            <TextValidator
                fullWidth
                label="Nickname"
                variant="filled"
                onChange={this.handleChange}
                name="nickname"
                value={nickname}
                validators={['required']}
                errorMessages={['Campo obrigatório']}
              />
          </Grid> */}
          <Grid item xs={4}>
            <TextValidator
                fullWidth
                label="Nome completo"
                variant="filled"
                onChange={this.handleChange}
                name="full_name"
                value={full_name}
                validators={['required']}
                errorMessages={['Campo obrigatório']}
              />
          </Grid>
          <Grid item xs={4}>
            <TextValidator
                fullWidth
                label="Papel"
                variant="filled"
                onChange={this.handleChange}
                name="role"
                value={role}
                select
                validators={['required']}
                errorMessages={['Campo obrigatório']}
              >
                <MenuItem value='USER'>Usuário</MenuItem>
                <MenuItem value='ADMIN'>Administrador</MenuItem>
                <MenuItem value='EMPLOYEE'>Funcionário</MenuItem>
              </TextValidator>
          </Grid>
          <Grid item xs={4}>
            <TextValidator
              fullWidth
              label="Senha Temporária"
              variant="filled"
              onChange={this.handleChange}
              name="password"
              type="password"
              value={password}
              validators={['required', 'matchRegexp:^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$']}
              errorMessages={['Campo obrigatório', 'A senha deve contar pelo menos 8 dígitos, maiúsculas e minúsculas']}
            />
          </Grid>
          <Grid item xs={4}>
            <TextValidator
              fullWidth
              label="E-mail"
              autoComplete="off"
              variant="filled"
              onChange={this.handleChange}
              name="email"
              value={email}
              validators={['required']}
              errorMessages={['Campo obrigatório']}
            />
          </Grid>
          <Grid item xs={4}>
            <PhoneInput
              country={'br'}
              specialLabel='Whatsapp'
              value={phone}
              onChange={phone => this.setState({ phone })}
              fullWidth
              margin="normal"
              variant="filled"
            />
          </Grid>
          <Grid item xs={4}>
          <Button 
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              disabled={isFetching}
              style={{ height: 55 }}
            >
              {isFetching && <CircularProgress size={20} />} Salvar
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    )
  }
}

export default UserForm;