import './Landing.css';
import BasicHeader from '../common/BasicHeader';
import { AppBar, Typography, Button, Grid, Container, Box } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import RedCard from '../common/Card'
import NeonDivider from '../common/NeonDivider';
import { Link as LinkRouter } from 'react-router-dom';
import React, { Component } from "react"
import { connect } from 'react-redux'
import { setSignUpInfoData } from '../auth/actions'
import queryString from 'query-string'



const theme = createMuiTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif'
  }
})

class Landing extends Component {

  componentDidMount() {
    const { auth }  = this.props

    const { user_email } = auth;

    if(user_email) {
      this.props.history.push('/dashboard')
    }

    const parsed = queryString.parse(this.props.location.search);

    if (parsed.ref) {
      let ref = parsed.ref.replace("/", "")
      this.setState({ ref: ref })
      this.props.setSignUpInfo({ ref: ref })
    }


  }
  
  render() {
    return (
      <div className="App landing-page">
        <ThemeProvider theme={theme}>
          <div>
            <AppBar position="static" style={{ backgroundColor: 'white', color: '#18191F' }}>
              <BasicHeader />
            </AppBar>
            <section style={{ background: "url('./textura-branca.png')", paddingTop: "60px", paddingBottom: "32px", paddingLeft: "16px", paddingRight: "16px" }}>
              <Container>
                <Grid container alignItems="center" >
                  <Grid item xs={12} md={6}>
                    <img src="./logo-sensei-wallet.svg" alt="Sensei Wallet" style={{ maxWidth: "90%", marginTop: "24px" }} />
                    <Typography variant="h4" style={{ marginTop: "40px", marginBottom: "16px", fontWeight: 400, color: "#A30908", fontSize: "30px", textAlign: "left" }}> A carteira digital exclusiva do <b>Sensei Poker.</b> </Typography>
                    <Typography variant="h4" style={{ marginTop: "16px", marginBottom: "16px", fontWeight: 400, color: "#A30908", fontSize: "30px", textAlign: "left" }}> Gestão de Bankroll simplificada, para você poder focar no seu jogo. </Typography>
                    <LinkRouter to="/login?signup=true"><Button variant="contained" size="large" style={{ background: "linear-gradient(90deg, rgba(103,3,3,1) 0%, rgba(163,9,8,1) 100%)", color: 'white', borderRadius: 50, margin: "32px 0", fontWeight: 600, fontSize: "20px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>Quero abrir minha carteira</Button></LinkRouter>
                  </Grid>
                  <Box
                    component={Grid}
                    item
                    md={6}
                    style={{ textAlign: "right" }}
                    display={{ xs: "none", sm: "block" }}
                  >
                    <img src="./phone.png" alt="Sensei Wallet" style={{ maxWidth: '90%' }} />
                  </Box>
                  <Box
                    component={Grid}
                    item
                    md={12}
                    display={{ xs: "none", sm: "block" }}
                  >
                    <img style={{ width: "100%", marginTop: "-20px" }} src="./salas.png" alt="Salas" />
                  </Box>
                </Grid>
              </Container>
            </section>
            <section id="sobre" style={{ background: "url('./fundo-banner.svg')", backgroundSize: 'cover', color: 'white', padding: '32px 16px' }}>
              <Container>
                <Grid container justify="space-between" alignItems="center" >
                  <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
                    <Typography variant="h3" style={{ lineHeight: "52px", fontWeight: 300, marginBottom: "16px" }}>O que é a <br /><b>Sensei Wallet?</b></Typography>
                    <Typography style={{ fontSize: "16px", marginBottom: "16px", lineHeight: "1.8" }}>É um sistema para gestão de Bankroll, ou seja, gestão de todo seu dinheiro utilizado para jogar Poker.</Typography>
                    <Typography style={{ fontSize: "16px", marginBottom: "16px", lineHeight: "1.8" }} >Facilitamos a movimentação do seu dinheiro entre plataformas. Você pode transformar fichas em dinheiro e dinheiro em fichas de forma descomplicada, rápida e segura. Além de também fazermos envio via bitcoin.</Typography>
                    <Typography style={{ fontSize: "16px", fontWeight: 600, marginBottom: "24px" }}>Ideal para Times e Players.</Typography>
                  </Grid>
                  <Box
                    component={Grid}
                    item
                    md={6}
                    style={{ textAlign: "right" }}
                    display={{ xs: "none", sm: "block" }}
                  >
                    <img src="./bankroll.svg" alt="Sensei Wallet" />
                  </Box>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <LinkRouter to="/login?signup=true"><Button variant="contained" size="large" style={{ background: 'white', color: '#A30908', borderRadius: 50, fontWeight: 600, fontSize: "20px" }}>QUERO ABRIR MINHA CARTEIRA</Button></LinkRouter>
                  </Grid>
                </Grid>
              </Container>
            </section>
            <section id="como-funciona" style={{ background: "url('./bg-white.png')", padding: '32px 16px' }}>
              <Container>
                <Grid container spacing={6} style={{ textAlign: 'center' }}>
                  <Grid item xs={12}>
                    <Typography style={{ textTransform: 'uppercase', textAlign: 'center', fontWeight: 600, color: "#A30908", fontSize: "35px" }}>Como funciona?</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ marginBottom: '16px', textAlign: 'center', fontSize: "22px", color: "#A30908" }}>O Sensei Poker atua como um intermediário, fazendo a gestão de caixa entre seu dinheiro e as salas de Poker.</Typography>
                    <Typography style={{ textAlign: 'center', fontSize: "22px", color: "#A30908" }}><b>Cuidamos de tudo para que você possa focar no seu jogo, sem preocupações.</b></Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" justify="center" spacing={3} >
                  <Grid item>
                    <RedCard img="./arrow-down.svg" alt="Receber" action="Receber" text='Transfira o dinheiro para a Sensei Wallet e o receba como saldo na sua carteira.' />
                  </Grid>
                  <Grid item>
                    <RedCard img="./arrow-up.svg" alt="Enviar" action="Enviar" text='Faça o envio do saldo da sua carteria para qualquer uma das salas parceiras.' />
                  </Grid>
                  <Grid item>
                    <RedCard img="./arrow-transfer.svg" alt="Transferir" action="Transferir" text='Transfira facilmente seu saldo entre usuários da Sensei Wallet.' />
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <LinkRouter to="/login?signup=true"><Button variant="contained" size="large" style={{ marginTop: "32px", background: 'white', color: '#A30908', borderRadius: 50, fontWeight: 600, fontSize: "20px" }}>QUERO ABRIR MINHA CARTEIRA</Button></LinkRouter>
                </Grid>
              </Container>
            </section>
            <section id="beneficios" style={{ background: "url('./fundo-banner2.svg')", backgroundSize: 'cover', color: 'white', padding: '32px 16px', textAlign: "center" }}>
              <Container>
                <Grid container justify="space-around">
                  <Grid item>
                    <Typography style={{ textAlign: "left", fontSize: "35px", fontWeight: 600, lineHeight: "52px", marginBottom: "16px" }}>Sem custos<br /> ou taxas abusivas</Typography>
                    <Typography style={{ textAlign: "left", fontSize: "22px", lineHeight: "33px", marginBottom: "24px" }}>Não pague taxa para a maioria das salas.</Typography>
                  </Grid>
                  <Grid item>
                    <img src="./semcusto.svg" alt="Sem custo"></img>
                  </Grid>
                </Grid>
                <NeonDivider />
                <Grid container justify="space-around" alignItems="center">
                  <Grid item>
                    <Typography style={{ textAlign: "left", fontSize: "35px", fontWeight: 600, lineHeight: "52px", marginBottom: "16px" }}>Possibilidade de<br />resgate em Bitcoin</Typography>
                    <Typography style={{ textAlign: "left", fontSize: "22px", lineHeight: "33px", marginBottom: "24px" }}>Receba o dinheiro da sua carteira em Bitcoin.</Typography>
                  </Grid>
                  <Grid item>
                    <img src="./bitcoin.svg" alt="Bitcoin"></img>
                  </Grid>
                </Grid>
                <NeonDivider />
                <Grid container justify="space-around" alignItems="center">
                  <Grid item>
                    <Typography style={{ textAlign: "left", fontSize: "35px", fontWeight: 600, lineHeight: "52px", marginBottom: "16px" }}>Menos<br />burocracia</Typography>
                    <Typography style={{ textAlign: "left", fontSize: "22px", lineHeight: "33px" }}>A Sensei Wallet facilita o processo de transação<br />entre as principais salas de Poker e Wallets.*</Typography>
                    <Typography style={{ textAlign: "left", fontSize: "15px", lineHeight: "22px", marginBottom: "24px" }}>*Consultar salas e carteiras disponíveis.</Typography>
                  </Grid>
                  <Grid item>
                    <img src="./transacoes.svg" alt="Menos burocracia"></img>
                  </Grid>
                </Grid>
                <NeonDivider />
                <Grid container justify="space-around" alignItems="center">
                  <Grid item>
                    <Typography style={{ textAlign: "left", fontSize: "35px", fontWeight: 600, lineHeight: "52px", marginBottom: "16px" }}>Segurança</Typography>
                    <Typography style={{ textAlign: "left", fontSize: "22px", lineHeight: "33px", marginBottom: "24px" }}>Toda a gestão de caixa é feita de forma<br />totalmente segura e transparente.</Typography>
                  </Grid>
                  <Grid item>
                    <img src="./seguranca.svg" alt="Segurança"></img>
                  </Grid>
                </Grid>
                <NeonDivider />
                <Grid container justify="space-around" alignItems="center">
                  <Grid item>
                    <Typography style={{ textAlign: "left", fontSize: "35px", fontWeight: 600, lineHeight: "52px", marginBottom: "16px" }}>Suporte em Português,<br />7 dias por semana</Typography>
                    <Typography style={{ textAlign: "left", fontSize: "22px", lineHeight: "33px", marginBottom: "24px" }}>Nosso time pronto para te ajudar.</Typography>
                  </Grid>
                  <Grid item>
                    <img src="./suporte.svg" alt="Suporte"></img>
                  </Grid>
                </Grid>
              </Container>
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <LinkRouter to="/login?signup=true"><Button variant="contained" size="large" style={{ background: 'white', color: '#A30908', borderRadius: 50, fontWeight: 600, fontSize: "20px", marginTop: "32px" }}>QUERO ESSAS VANTAGENS</Button></LinkRouter>
                </Grid>
              </Grid>
            </section>
            <section style={{ background: "url('./bg-white.png')", padding: "32px 16px", color: '#A30908' }}>
              <Container>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography style={{ margin: '8px 0', textAlign: 'center', fontSize: "35px", color: "#A30908", fontWeight: 700 }}>Crie sua carteira gratuitamente e deixe a complexidade de lado.</Typography>
                    <Typography style={{ margin: '8px 0', textAlign: 'center', fontSize: "22px", color: "#A30908" }}>Cuidamos de tudo para que você possa focar no seu jogo, sem preocupações.</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <LinkRouter to="/login?signup=true"><Button variant="contained" size="large" style={{ background: "linear-gradient(90deg, rgba(103,3,3,1) 0%, rgba(163,9,8,1) 100%)", color: 'white', borderRadius: 50, margin: "32px 0", fontWeight: 600, fontSize: "20px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>Abrir Sensei Wallet</Button></LinkRouter>
                  </Grid>
                </Grid>
              </Container>
            </section>
            <section style={{ background: "#090909", backgroundSize: 'cover', backgroundPosition: 'center', padding: "64px 0", color: 'white' }}>

              <Grid container style={{ textAlign: 'center' }}>
                <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                  <img style={{
                    maxWidth: '80%',
                    marginTop: 12,
                    marginBottom: 12
                  }} alt="logo" src={process.env.PUBLIC_URL + '/senseipoker.png'} />
                </Grid>

                <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                  <Box
                    xs={{ textAlign: "center" }}
                  >
                    <Button style={{ padding: '8px 25px', color: 'white' }}><a style={{ color: 'white' }} href="https://sensei.poker">Sensei Poker</a></Button>
                    <Button style={{ padding: '8px 25px', color: 'white' }}><a style={{ color: 'white' }} href="https://club.sensei.poker">Clube</a></Button>
                    <Button style={{ padding: '8px 25px', color: 'white' }}><LinkRouter style={{ color: 'white' }} to="/login?signup=true">Wallet</LinkRouter></Button>
                    <Button style={{ padding: '8px 25px', color: 'white' }}><a style={{ color: 'white' }} href="https://sensei.poker">Time</a></Button>
                    <Button style={{ padding: '8px 25px', color: 'white' }}><a style={{ color: 'white' }} href="https://escola.sensei.poker">Escola</a></Button>
                  </Box>
                </Grid>

              </Grid>
            </section>
          </div>
        </ThemeProvider>
  
      </div>
    );
  }
}


const mapStateToProps = ({ auth }) => {
  return {
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSignUpInfo: (info) => dispatch(setSignUpInfoData(info)),
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Landing)

