import React, { Component } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core'
// import {Link} from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import './SignUpForm.css'
import translations from '../../../translations/translations'
import { Link } from 'react-router-dom'


class SignUpForm extends Component {

  state = {
    name: '',
    email: this.props.email || '',
    password: '',
    phoneNumber: '',
    older: false,
    agree: false,
  }

  reset() {
    this.setState((state) => ({
      name: '',
      email: this.props.email || '',
      password: '',
      phoneNumber: '',
      older: false
    }))
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    if (field === 'older' || field === 'agree') {
      const checked = event.target.checked
      this.setState({ [field]: checked })
    } else {
      this.setState({ [field]: value })
    }
  }

  handleSubmit = () => {
    const data = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      phoneNumber: '+' + this.state.phoneNumber
    }
    this.props.handleSubmit({ ...data }, () => this.reset())
  }

  render() {
    const { name, email, password, phoneNumber } = this.state
    const { isFetching, lang } = this.props

    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
      >
        <Grid container>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              label={translations[lang].name}
              onChange={this.handleChange}
              name="name"
              value={name}
              validators={['required']}
              errorMessages={translations[lang].error_required}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              label={translations[lang].email}
              onChange={this.handleChange}
              name="email"
              value={email}
              validators={['required']}
              errorMessages={translations[lang].error_required}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneInput
              country={'br'}
              specialLabel={translations[lang].phone}
              value={phoneNumber}
              onChange={phoneNumber => this.setState({ phoneNumber })}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              label={translations[lang].password}
              type='password'
              onChange={this.handleChange}
              name="password"
              value={password}
              validators={['required', 'matchRegexp:^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$']}
              errorMessages={[translations[lang].error_required, translations[lang].password_requirements]}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <div id="message" style={{ marginBottom: '12px' }}>
              <Typography variant="caption">{translations[lang].password_info()}</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(this.state.older)}
                  onChange={this.handleChange}
                  name="older"
                  color="primary"
                />
              }
              label={<Typography variant="caption">{translations[lang].older_declaration(this.state.name)}</Typography>}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(this.state.agree)}
                  onChange={this.handleChange}
                  name="agree"
                  color="primary"
                />
              }
              label={<Typography variant="caption">Eu, {this.state.name}, li e concordo com os <Link className="terms" target='_blank' to='terms-of-use'>Termos de Uso</Link></Typography>}
            />
          </Grid>
        </Grid>

        <div style={{ textAlign: 'center', marginTop: '16px' }}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            type="submit"
            disabled={isFetching || !this.state.older || !this.state.agree}
            margin="normal"
            style={{ marginTop: 16 }}
          >
            {isFetching && <CircularProgress color='inherit' size={20} />} <b>{translations[lang].create_wallet}</b>
          </Button>
        </div>
      </ValidatorForm>

    )
  }
}

export default SignUpForm