        
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { TableCell, TableRow } from '@material-ui/core'
import { updateFeeFetchData } from './actions'
class PlatformRow extends Component {

  render() {
    const { doc, onPlatformChange } = this.props;
    return (
      <TableRow onClick={(e) => onPlatformChange(doc)}>
        <TableCell>{doc.name}</TableCell>
        <TableCell>
          <div>
            { doc.fee || 0 }%
          </div> 
        </TableCell>
      </TableRow>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFee: (platform, fee) => dispatch(updateFeeFetchData(platform, fee))
  }
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlatformRow))
