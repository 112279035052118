import React, {Component} from 'react'
import { Card, Grid, Snackbar, Typography } from '@material-ui/core'
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {getMyNoticesFetchData, hideToUser} from './actions'
import {connect} from 'react-redux'
import translations from '../../translations/translations'

class Notice extends Component {
  state={
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center'
  }

  handleHideToUser = () => {
    hideToUser(this.props.doc._id, this.props.me.cognito_id)
    .then(r=>{
      this.props.getMyNotices(this.props.me.cognito_id)
    })
    .catch(err=>{
      this.setState({
        snackbarOpen: true,
        snackMessage: err.message
      })
    })
  }

  render(){
    const {vertical, horizontal, snackbarOpen, snackMessage} = this.state
    const {doc, lang} = this.props

    const types = {
      "NEWS": {title:translations[lang].news, icon: <AnnouncementOutlinedIcon />, style: {color:'#167b1e'}},
      "NOTICE": {title:translations[lang].notice, icon: <InfoOutlinedIcon />, style: {color:'#d27d13'}},
      "WARNING": {title: `${translations[lang].alert}!`, icon: <ReportOutlinedIcon />, style: {color:'#ca1a1a'}},
    }

    const message = {
      br: 'message',
      ar: 'message_ar',
      us: 'message_us'
    }
    return(
      <Card style={{background: "#f9f9f9", padding: 8, borderRadius: 5, height: '100%'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ borderBottom:`1px solid ${types[doc.type].style.color}` }}>
            <Grid style={{...types[doc.type].style }} container spacing={3}>
              <Grid item xs={2}>
                {
                  types[doc.type].icon
                }
              </Grid>
              <Grid item xs={8}>
                <Typography style={{textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center' }} variant='body1'>{types[doc.type].title}</Typography>
              </Grid>
              <Grid item xs={2} style={{ paddingRight: 8, textAlign: 'right'}}>
                <CloseOutlinedIcon onClick={this.handleHideToUser} style={{color: 'gray', cursor:'pointer'}}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{padding: "16px 8px"}} item xs={12}>

            <Typography variant='body1'>{doc[message[lang]]}</Typography>
          </Grid>
        </Grid>
        <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              key={`${vertical},${horizontal}`}
              open={snackbarOpen}
              onClose={this.handleCloseSnackbar}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
            message={<span id="message-id">{snackMessage}</span>}    />
      </Card>
    )
  }

}

const mapStateToProps = ({me, lang}) =>{
  return{
    me,
    lang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMyNotices: (users) => dispatch(getMyNoticesFetchData(users))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notice)